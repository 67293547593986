import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom';
import { adminRoutes } from '../helpers/adminRoutes';
import { Layout, Menu, Icon } from 'antd';
import { MailOutlined, AppstoreOutlined, SettingOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';

const { SubMenu } = Menu;
const { Sider } = Layout;

class Sidebar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            login: false,
            userData: null,
        };
    }

    componentDidMount() {
        let userData = JSON.parse(localStorage.getItem('adminData'));
        this.setState({ userData: userData })
    }

    openSider = (e) => {
        e.preventDefault();
        document.body.classList.add("openSider");
    }

    render() {
        let { userData } = this.state;

        return (
            <div className="left-panel">
                <div className="d-flex">
                    <div className="sidebar-logo"><Link to={adminRoutes.PATTERNLIST}>Patterfy</Link></div>

                </div>
                <div className="sidebar-nav">
                    <Sider>
                        <Menu
                            mode="inline"
                            defaultSelectedKeys={['1']}
                            defaultOpenKeys={['sub1', 'sub2']}
                            style={{ height: '100%', borderRight: 0 }}
                        >

                            <SubMenu key="sub1" title={<span>
                                <Icon type="user" />
                                        Marketplace
                                </span>}>
                                <Menu.Item key="1">
                                    <Link className="dashboard" to={adminRoutes.PATTERNLIST} > Patterns </Link>
                                </Menu.Item>
                            </SubMenu>
                            {userData && userData.data && userData.data.type === 'admin' && <SubMenu key="sub2"
                                title={<span>
                                    <Icon type="setting" />
                                    Management
                                </span>}>
                                <Menu.Item key="2">
                                    <Link className="dashboard" to={adminRoutes.STITCHLIST} > Stitches </Link>
                                </Menu.Item>
                                <Menu.Item key="3">
                                    <Link className="dashboard" to={adminRoutes.USERSLIST} > Users </Link>

                                </Menu.Item>
                            </SubMenu>}

                        </Menu>
                    </Sider>
                </div>
                {/* <div className="blink"> Staging</div> */}
                {/* <div className="blink blinkRight"> mansi</div> */}
            </div >
        )
    }
}
// export default Sidebar;
const mapStateToProps = ((state) => {
    let { loading, data, error } = state.login
    return {
        loading,
        data,
        error
    }
})
export default withRouter(connect(mapStateToProps)(Sidebar));