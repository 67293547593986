import React, { Component } from 'react'
// import { connect } from 'react-redux';
import { Checkbox } from 'antd';

export default class Border extends Component {
    constructor(props) {
        super(props)
        this.state = {
            boderValue: this.props.boderValue,
        }
    }




    handleBorderClick = (e, val) => {
        e.preventDefault();
        let { checkboxValue } = this.state;
        let tempval = { ...checkboxValue }
        const newBorder = e.target.checked ? val : "";
        tempval.border = newBorder;


        this.setState({ boderValue: newBorder, checkboxValue: tempval },
            () => this.props.eBorder(e, { boderValue: newBorder, checkboxValue: tempval })
        )
    }

    componentDidUpdate(prevProps, prevState) {
        let { boderValue } = this.props;
        if (boderValue === false && prevProps.boderValue !== boderValue) {
            this.setState({ boderValue: boderValue })
        }
    }


    render() {
        let { boderValue } = this.state;
        return (
            <div className="grid-box-wrap repeat-div">
                <h2>Repeat</h2>
                <Checkbox
                    value="left-border"
                    checked={boderValue === "left-border" ? true : false}
                    onChange={(e) => this.handleBorderClick(e, "left-border")}
                ></Checkbox>

                <Checkbox
                    value="right-border"
                    checked={boderValue === "right-border" ? true : false}
                    onChange={(e) => this.handleBorderClick(e, "right-border")}
                ></Checkbox>

                <Checkbox
                    value="LeftRightRepeat"
                    checked={boderValue === "LeftRightRepeat" ? true : false}
                    onChange={(e) => this.handleBorderClick(e, "LeftRightRepeat")}
                ></Checkbox>

                {/* <Checkbox
                    value="bottom-border"
                    checked={boderValue === "bottom-border" ? true : false}
                    onChange={(e) => this.handleBorderClick(e, "bottom-border")}
                ></Checkbox> */}
            </div>
        )
    }
}

// const mapStateToProps = ((state) => {

// })
// export default connect(mapStateToProps)(Border);