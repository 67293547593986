import React, { Component } from 'react'
import Layout from './Layout';
import { Form, Icon, Input, Button, Upload, message, Select, Checkbox } from 'antd';
import { connect } from 'react-redux';
import { adminRoutes } from '../helpers/adminRoutes';
import { addUser } from '../redux/actions/addUser';
import { viewUser, resetViewUser } from '../redux/actions/viewUser';
import { deleteUser } from '../redux/actions/deleteUser';

import moment from 'moment';

import { Breadcrumb } from 'antd';
import { SERVER_URL } from '../api';
import { Link } from 'react-router-dom';
import { Beforeunload } from 'react-beforeunload';
const { Option } = Select;


function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}


const { Dragger } = Upload;

const props = {
    name: 'file',
    multiple: false,
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
        }
        if (status === 'done') {

            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
};

class AddUserData extends Component {

    constructor(props) {
        super(props)
        this.state = {
            fileList: [],
            betaShow: false,
            typeShow: false,
            edit: false,
            betaChecked: false,
            id: null,
            userData: []

        }
    }

    componentWillUnmount() {
        this.setState({ edit: false, id: null });
        let { dispatch } = this.props;

        dispatch(resetViewUser())

        let val = this.props.form.isFieldsTouched();
        if (val === true) {
        } else {
        }
    }

    handleDelete = (e, id) => {
        e.preventDefault();
        let { dispatch } = this.props;
        dispatch(deleteUser(id)).then(res => {
            if (res) {
                this.props.history.push(adminRoutes.USERSLIST);
            }
        })
    }
    // handleDelete = (e, id, imageName) => {
    //     const img = imageName;
    //     e.preventDefault();
    //     let { dispatch } = this.props;
    //     dispatch(deleteImage(id._id, img)).then(res => {
    //         if (res && res.status === 200) {
    //             dispatch(viewPattern(id._id));
    //         }
    //     })
    // }

    handleSubmit = (e) => {

        e.preventDefault();
        let { dispatch, location } = this.props;
        let { fileList, edit, difficultyValue, image } = this.state;
        let path = location.pathname.split('/')

        let id = path[2];
        this.setState({ defaulState: true })

        this.props.form.validateFields((err, values) => {
            if (!err) {
                if (edit === false) {
                    if (values.beta === undefined) {
                        values.beta = false
                    }
                    dispatch(addUser({ ...values })).then(res => {
                        if (res && res.status === 200) {
                            this.props.history.push(adminRoutes.USERSLIST);
                        }

                    }).catch(err => {
                        message.error('Email already exists, please use another email');

                    })
                }
                else {
                    if (values.beta === undefined) {
                        values.beta = this.state.betaChecked
                    }
                    dispatch(addUser({ ...values, id }, edit)).then(res => {

                        if (res && res.status === 200) {
                            this.props.history.push(adminRoutes.USERSLIST);
                        }

                    }).catch(err => {
                        message.error('Email already exists, please use another email');


                    })
                }
            }


        })

    }



    componentDidUpdate(prevProps) {
        let { dispatch, location } = this.props;

        let path = location.pathname.split('/')

        let id = path[2];
        if (location && prevProps.location !== location) {

            if (id) {
                this.setState({ edit: true, id: id })

                dispatch(viewUser(id)).then(res => {
                    if (res && res.status === 200) {


                        this.setState({
                            betaChecked: res && res.data && res.data.data && res.data.data.data && res.data.data.data.beta
                            , edit: true
                        })
                    }
                })

            }
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        let { dispatch, location, error, history } = this.props;

        let path = location.pathname.split('/')

        let id = path[2];
        if (id) {
            let userData = JSON.parse(localStorage.getItem('adminData'));
            this.setState({ userData: userData })
            this.setState({ edit: true, id: id })

            dispatch(viewUser(id)).then(res => {
                if (res && res.status === 200) {

                    this.setState({ betaChecked: res && res.data && res.data.data && res.data.data.data && res.data.data.data.beta })
                }
            })

        }


        if (error && error.status === 400) {
            message.error(error.data.message)
            history.push(adminRoutes.AddPattern)
        }


    }
    onBeta(e) {
        this.setState({ betaChecked: e.target.checked })
        // console.log(`checked = ${e.target.checked}`);

    }
    onRoleSelect(e) {
        console.log('e => ', e);
        if (e == 'user') {
            this.setState({ betaShow: true, typeShow: false })
        }
        else if (e == 'admin') {

            this.setState({ typeShow: true, betaShow: false })
        }
        // console.log(`checked = ${e.target.checked}`);

    }

    render() {
        const { getFieldDecorator, getFieldsError } = this.props.form;
        let { editData, prevAddData } = this.props;
        let { fileList, edit, userData } = this.state;


        return (
            <div className="content-box">


                <Layout>

                    <div className="bredcrumb-div">
                        <Breadcrumb separator="-">
                            <Breadcrumb.Item><Link to={adminRoutes.USERSLIST}>User</Link></Breadcrumb.Item>
                            <Breadcrumb.Item><Link to={adminRoutes.ADDUSER}>{edit === true ? "Edit User" : "Add User"}</Link></Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className="page-title d-flex">
                        <h2>Add User</h2>
                    </div>
                    <div className="white-box">
                        <Form layout="horizontal" onSubmit={this.handleSubmit} style={{ width: "50 %" }}>
                            <ul className="filled-ul flex-wrap">
                                <li className="">

                                    <Form.Item >
                                        <label> Name </label>
                                        {getFieldDecorator('name', {
                                            rules: [{ required: true, message: 'Please input your stitch name!' }],
                                            initialValue:
                                                edit === true ? editData && editData.data && editData.data.data && editData.data.data.data &&
                                                    editData.data.data.data.name :
                                                    prevAddData && prevAddData.data && prevAddData.data.data.name ?
                                                        prevAddData && prevAddData.data && prevAddData.data.data.name : ""
                                        })(
                                            <Input
                                                // prefix={<Icon />}
                                                type="text"
                                                placeholder="Name"
                                            />,
                                        )}
                                    </Form.Item>
                                </li>
                                <li>
                                    <Form.Item >
                                        <label>Email</label>
                                        {getFieldDecorator('email', {
                                            rules: [{ required: true, message: 'Please input email!' }],
                                            initialValue:
                                                edit === true ? editData && editData.data && editData.data.data && editData.data.data.data &&
                                                    editData.data.data.data.email :
                                                    prevAddData && prevAddData.data && prevAddData.data.data.email ?
                                                        prevAddData && prevAddData.data && prevAddData.data.data.email : ""
                                        }
                                        )(
                                            <Input
                                                // prefix={<Icon />}
                                                type="text"
                                                placeholder="Email"
                                            />,
                                        )}
                                    </Form.Item>
                                </li>
                                <li>
                                    <Form.Item >
                                        <label>Password: </label>
                                        {edit === true ? getFieldDecorator('password', {
                                        })


                                            (
                                                <Input
                                                    // prefix={<Icon />}
                                                    type="text"
                                                    placeholder="Password"
                                                />,
                                            )
                                            : getFieldDecorator('password', {
                                                rules: [{ required: true, message: 'Please input password!' }]
                                            })


                                                (
                                                    <Input
                                                        // prefix={<Icon />}
                                                        type="text"
                                                        placeholder="Password"
                                                    />,
                                                )}
                                    </Form.Item>
                                </li>
                                <li className="half-width CustomeSelect">
                                    <Form.Item>


                                        <label>User Type</label>
                                        {getFieldDecorator('role', {
                                            rules: [{ required: true, message: 'Please input type!' }],

                                            initialValue: edit === true ? editData && editData.data && editData.data.data && editData.data.data.data &&
                                                editData.data.data.data.role :
                                                prevAddData && prevAddData.data && prevAddData.data.data.role ?
                                                    prevAddData && prevAddData.data && prevAddData.data.data.role : ""
                                        })(
                                            <Select onChange={(e) => this.onRoleSelect(e)}>
                                                <Option key="user">Mobile</Option>
                                                <Option key="admin">Web</Option>
                                            </Select>
                                        )}
                                    </Form.Item>

                                </li>
                                {edit == true && <li className="">

                                    <Form.Item >
                                        <label> Created At : </label>
                                        {getFieldDecorator('createdAts', {
                                            rules: [{ required: true, message: 'Please input your stitch name!' }],
                                            initialValue:
                                                edit === true ? editData && editData.data && editData.data.data && editData.data.data.data &&
                                                    new moment(editData.data.data.data.createdAt).format('MM-DD-YYYY') :
                                                    prevAddData && prevAddData.data && prevAddData.data.data.createdAt ?
                                                        prevAddData &&
                                                        prevAddData.data &&
                                                        new moment(prevAddData.data.data.data.createdAt).format('MM-DD-YYYY') : ""
                                        })(
                                            <Input
                                                // prefix={<Icon />}
                                                type="text"
                                                readOnly
                                                placeholder="createdAt"
                                            />,
                                        )}
                                    </Form.Item>
                                </li>
                                }

                                <li className="half-width CustomeSelect">
                                    {this.state.typeShow === true &&
                                        <Form.Item>


                                            <label>Type</label>
                                            {getFieldDecorator('type', {
                                                rules: [{ required: true, message: 'Please input type!' }],

                                                initialValue: edit === true ? editData && editData.data && editData.data.data && editData.data.data.data &&
                                                    editData.data.data.data.type :
                                                    prevAddData && prevAddData.data && prevAddData.data.data.type ?
                                                        prevAddData && prevAddData.data && prevAddData.data.data.type : ""
                                            })(
                                                <Select>
                                                    <Option key="admin">Admin</Option>
                                                    <Option key="standard">Standard</Option>
                                                </Select>
                                            )}
                                        </Form.Item>}

                                </li>
                                <li className="BetaLi">
                                    {(this.state.betaShow === true || editData && editData.data && editData.data.data && editData.data.data.data &&
                                        editData.data.data.data.role === "user") && < Form.Item >

                                            <label>Beta</label>
                                            {getFieldDecorator('beta')(
                                                <Checkbox onChange={(e) => this.onBeta(e)} checked={this.state.betaChecked} ></Checkbox>
                                            )}
                                        </Form.Item>
                                    }
                                </li>
                                <li className="btm-btn chat-view-btm">
                                    <Form.Item>

                                        <div className="d-flex justify-content-center">
                                            <div className="ChatExitButton">
                                                < Button type="button" onClick={(e) => this.handleSubmit(e)} disabled={hasErrors(getFieldsError())}  >
                                                    {edit === true ? "Update" : "Save and Exit"}
                                                </Button>
                                                {edit === true && < Button type="button" onClick={(e) => this.handleDelete(e, editData.data.data.data._id)} disabled={hasErrors(getFieldsError())}  >
                                                    Delete
                                                </Button>
                                                }
                                            </div>

                                        </div>



                                    </Form.Item>
                                </li>
                                <li className="btm-btn chat-view-btm">
                                    <Form.Item>





                                    </Form.Item>
                                </li>
                            </ul>

                        </Form>
                    </div>
                </Layout>
            </div >
        )
    }
}

const mapStateToProps = ((state) => {
    let { loading, data, error } = state.addUser
    return {
        loading,
        data,
        error,
        editLoading: state.viewUser.loading,
        editData: state.viewUser.data,
        prevAddData: state.addPattern.data,
        editError: state.viewUser.error,

    }
})

const AddUser = Form.create({ name: 'addUser' })(AddUserData);
export default connect(mapStateToProps)(AddUser);
// export default (AddUserData);