import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Descriptions, Breadcrumb } from 'antd';
import Layout from './Layout';
import { viewSection, resetViewSection } from '../redux/actions/viewSection';
import { Link } from 'react-router-dom';
import { adminRoutes } from '../helpers/adminRoutes';
import { SERVER_URL } from '../api';



class ViewSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            patternId: '',

        }
    }
    componentDidMount() {
        let { dispatch, location } = this.props;
        let path = location.pathname.split('/')
        let sectionId = path[2];
        let patternId = path[3];

        this.setState({ patternId: patternId, sectionId: sectionId })
        dispatch(viewSection(sectionId))
    }

    componentWillUnmount() {
        let { dispatch } = this.props;
        dispatch(resetViewSection())
    }
    render() {
        let { loading, data } = this.props

        let patternId = data && data.data && data.data.pattern.patternId._id;
        let sizeId = data && data.data && data.data.data.sizeId;

        let arr = [];
        data && data.data && data.data.data.image

            .map((dat, index) => {
                arr.push({ ImagePath: `${SERVER_URL}uploads/${dat}`, uid: index, originFileObj: `${SERVER_URL}uploads/${dat}` })
            })
        return (
            <Layout>
                <div className="content-box">
                    <div className="bredcrumb-div">

                        <Breadcrumb separator="-">
                            <Breadcrumb.Item><Link to="">{data && data.data && data.data.pattern && data.data.pattern && data.data.pattern.patternId.patternName}</Link></Breadcrumb.Item>
                            <Breadcrumb.Item><Link to={adminRoutes.SIZELIST + "/" + patternId} > {data && data.data && data.data.pattern && data.data.pattern.sizeName}</Link></Breadcrumb.Item>
                            <Breadcrumb.Item><Link to={adminRoutes.SECTIONLIST + "/" + patternId + "/" + sizeId} >{data && data.data && data.data && data.data.data.sectionName}</Link></Breadcrumb.Item>
                            <Breadcrumb.Item>View</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className="page-title d-flex">
                        <h2>Section Info</h2>
                    </div>

                    <div className="pattern_img_cstm">
                        {arr && arr.length > 0 && arr.map(img => (
                            <img src={img.ImagePath} alt="" />
                        ))}

                    </div>

                    <div className="viewsize-content">
                        <div className="white-box">
                            {loading && <h3>Loading............</h3>}
                            {!loading && data && data.data &&
                                <Descriptions bordered column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
                                    <Descriptions.Item label="Section Name">{data.data.data.sectionName} </Descriptions.Item>
                                    <Descriptions.Item label="Intro Text">{data.data.data.introText} </Descriptions.Item>
                                    <Descriptions.Item label="Outro Text">{data.data.data.outroText}</Descriptions.Item>
                                </Descriptions>}

                        </div>
                    </div>
                </div>
            </Layout>

        )
    }
}



const mapStateToProps = ((state) => {
    let { loading, data, error } = state.viewSection
    return {
        loading,
        data,
        error
    }
})

export default connect(mapStateToProps)(ViewSection);
