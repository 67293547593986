import React from 'react';
import './App.css';
import { Router, Switch, Route } from 'react-router-dom';
import LoginComponent from './components/LoginComponent'
import ForgetPasswordComponent from './components/ForgetPasswordComponent';
import ResetPasswordComponent from './components/ResetPasswordComponent';
import ListPatternComponent from './components/ListPatternComponent';
import AddPattern from './components/AddPattern';
import Grid from './components/Grid';
import AddSize from './components/AddSize';
import AddSection from './components/AddSection';
import ListSize from './components/ListSize';
import ListSection from './components/ListSection';
import ViewPattern from './components/ViewPattern';
import ViewSize from './components/ViewSize';
import ViewSection from './components/ViewSection';
import history from './helpers/history'
import { ADMIN_BASE_URL, adminRoutes } from './helpers/adminRoutes';
import AdminRouter from './privateRoutes/AdminRoute';
import AddStitch from './components/AddStitch';
import ListStitch from './components/ListStitch';
import ListUsers from './components/ListUsers';
import AddUser from './components/AddUser';

function App() {
  return (
    <div className="App">
      <Router history={history}>
        <Switch>
          <Route path={ADMIN_BASE_URL} exact={true} component={LoginComponent} />
          <Route exact={true} path={adminRoutes.FORGOT_PASSWORD} component={ForgetPasswordComponent} />
          <AdminRouter exact={true} path={adminRoutes.PATTERNLIST} component={ListPatternComponent} />
          <AdminRouter exact={true} path={adminRoutes.STITCHLIST} component={ListStitch} />
          <AdminRouter exact={true} path={adminRoutes.USERSLIST} component={ListUsers} />
          <AdminRouter exact={true} path={adminRoutes.ADDSTITCH} component={AddStitch} />
          <AdminRouter exact={true} path={adminRoutes.ADDUSER} component={AddUser} />
          <AdminRouter exact={true} path={adminRoutes.SIZELIST + '/:id'} component={ListSize} />
          <AdminRouter exact={true} path={adminRoutes.VIEWPATTERN + '/:id'} component={ViewPattern} />
          <AdminRouter exact={true} path={adminRoutes.VIEWSIZE + '/:id'} component={ViewSize} />
          <AdminRouter exact={true} path={adminRoutes.VIEWSECTION + '/:id'} component={ViewSection} />
          <AdminRouter exact={true} path={adminRoutes.EDITPATTERN + '/:id'} component={AddPattern} />
          <AdminRouter exact={true} path={adminRoutes.EDITSTITCH + '/:id'} component={AddStitch} />
          <AdminRouter exact={true} path={adminRoutes.EDITUSER + '/:id'} component={AddUser} />
          <AdminRouter exact={true} path={adminRoutes.EDITSIZE + '/:id/:patternId'} component={AddSize} />
          <AdminRouter exact={true} path={adminRoutes.EDITSECTION + '/:patternId/:sizeId/:sectionId'} component={AddSection} />
          <AdminRouter exact={true} path={`${adminRoutes.SECTIONLIST}/:patternId/:sectionId`} component={ListSection} />
          <AdminRouter exact={true} path={adminRoutes.ADDPATTERN} component={AddPattern} />
          <AdminRouter exact={true} path={adminRoutes.GRID + '/:patternId/:sizeId/:sectionId'} component={Grid} />
          <AdminRouter path={adminRoutes.GRID} component={Grid} />
          <AdminRouter exact={true} path={`${adminRoutes.ADDSIZE}/:id`} component={AddSize} />
          <AdminRouter exact={true} path={`${adminRoutes.ADDSECTION}/:patternId/:sectionId`} component={AddSection} />
          {/* <AdminRouter path={adminRoutes.ADDSECTION} component={AddSection} /> */}
          <Route exact={true} path={`${adminRoutes.RESET_PASSWORD}/:token`} component={ResetPasswordComponent} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
