import { SERVER_URL } from "../../api";
import axios from 'axios'
import { getHeaderWithToken } from "../../helpers/func";
export const SECTION_LIST_REQUEST = 'SECTION_LIST_REQUEST';
export const SECTION_LIST_SUCCESS = 'SECTION_LIST_SUCCESS';
export const SECTION_LIST_ERROR = 'SECTION_LIST_ERROR';
export const RESET_SECTION_LIST = 'RESET_SECTION_LIST';


export const sectionList = (data) => {
    let start = data && data.start;
    let length = data && data.length;
    let headers = getHeaderWithToken();
    return dispatch => {
        return new Promise(function (resolve, reject) {
            dispatch({
                type: SECTION_LIST_REQUEST
            })
            axios({
                method: 'GET',
                url: `${SERVER_URL}v1/patterns/${data.id}/sectiond`,
                headers: headers,
                params: { start: start, length: length, search: data.search ? data.search : '' }
            }).then(function (response) {
                dispatch({
                    type: SECTION_LIST_SUCCESS,
                    payload: response
                })
                resolve(response);
            })
                .catch(function (error) {
                    dispatch({
                        type: SECTION_LIST_ERROR,
                        payload: error
                    })
                    reject(error)
                })
        })
    }
}




