import React, { Component } from 'react'
import Layout from './Layout';
import { Button, Popconfirm, message, Breadcrumb, Spin } from 'antd';
import { adminRoutes } from '../helpers/adminRoutes';
import { connect } from 'react-redux';
import { viewPattern } from '../redux/actions/viewPattern';
import { patternList } from '../redux/actions/patternList';
import { deletePattern } from '../redux/actions/deletePattern';
import { SERVER_URL } from '../api';
import InfiniteScroll from 'react-infinite-scroller';
//import InfiniteScroll from 'react-infinite-scroll-component';
import { addPattern, resetAddPattern } from '../redux/actions/addPattern';
import { Link } from 'react-router-dom';


class ListPatternComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            pageNo: 0,
            patternListData: [],
            loadingApi: false,
            imageStatus: "loading"
        }
    }

    componentDidMount() {
        let { dispatch } = this.props;
        dispatch(resetAddPattern())
        this.loadFunc(1)
    }
    componentWillUnmount() {
        this.setState({
            patternListData: []
        })
    }

    patternAdd = (e, id) => {
        this.props.history.push(adminRoutes.ADDPATTERN)
    }
    patternEdit = (e, id) => {
        this.props.history.push(adminRoutes.EDITPATTERN + "/" + id)
    }
    handleSize = (e, id) => {
        let { history } = this.props;
        e.preventDefault();
        history.push(adminRoutes.SIZELIST + '/' + id)
    }
    confirm = (e) => {
        message.success('Click on Yes');
    }

    cancel = (e) => {

    }
    handleView = (e, id) => {
        let { dispatch, history } = this.props;
        e.preventDefault();
        dispatch(viewPattern(id))
        history.push(adminRoutes.VIEWPATTERN + '/' + id)
    }

    handleDelete = (e, id) => {
        e.preventDefault();
        let { dispatch } = this.props;
        dispatch(deletePattern(id)).then(res => {
            if (res && res.status === 200) {
                this.loadFunc(1, 'delete');
            }
        })
    }

    handleduplicate = (e, data) => {
        e.preventDefault();
        let { dispatch } = this.props;
        dispatch(addPattern({ type: "duplicate", ...data })).then(res => {

            if (res && res.status === 200) {
                this.loadFunc(1, 'duplicate', '')
                dispatch(resetAddPattern())

            }
        })

    }


    SampleNextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", background: "red" }}
                onClick={onClick}
            />
        );
    }

    Capitalize(str) {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
    }

    loadFunc = (e, string, value) => {

        if (string === 'delete' || string === 'duplicate') {
            this.setState({ loadingApi: true });
        }

        let page = (e - 1) * 9
        let data = {
            start: page,
            length: 9,
            search: value ? value : ''
        }
        let { dispatch } = this.props;
        dispatch(resetAddPattern())

        let { patternListData } = this.state
        let datas = string === 'delete' || string === 'search' || string === 'duplicate' ? [] : patternListData;
        dispatch(patternList(data)).then(res => {
            this.setState({ newPageNo: e, recordsTotals: res && res.data && res.data.recordsTotal })
            this.setState({ pageNo: page + 9 })

            res.data.data.map(dat => (
                datas.push(dat)

            ))

            function compare(a, b) {
                const bandA = b.position
                const bandB = a.position

                let comparison = 0;
                if (bandB > bandA) {
                    comparison = 1;
                } else if (bandB < bandA) {
                    comparison = -1;
                }
                return comparison;
            }

            datas.sort(compare);
            setTimeout(() => {
                this.setState({ patternListData: datas, loadingApi: false })
            }, 200)

        })

    }

    handleImageLoaded = () => {
        this.setState({ imageStatus: "loaded" });
    }

    handleImageErrored = () => {
        this.setState({ imageStatus: "failed to load" });
    }
    SamplePrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", background: "green" }}
                onClick={onClick}
            />
        );
    }

    render() {

        let { loading, data } = this.props;
        let { patternListData, loadingApi } = this.state;
        let total = data && data.data && data.data.recordsTotal


        return (
            <Layout>
                <div className="header-top">
                </div>
                <div className="content-box">
                    <div className="bredcrumb-div">
                        <Breadcrumb>
                            <Breadcrumb.Item><Link to={adminRoutes.PATTERNLIST}>Patterns</Link></Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className="page-title d-flex">
                        <div className="PageTitleLeft">
                            <h2>Pattern List</h2>
                            <h3> {this.state.pageNo < this.state.recordsTotals ?
                                this.state.pageNo : this.state.recordsTotals}
                                /{this.state.recordsTotals}</h3>
                        </div>
                        <Button onClick={this.patternAdd} type="primary" htmlType="submit" className="primary-btn"> ADD PATTERN </Button>
                    </div>
                    <div className="page-content">
                        <div className="pattern-list">
                            <div className="pattern_wrapper">

                                {
                                    loadingApi === true ? <div><Spin /></div> :

                                        <InfiniteScroll
                                            //  pageStart={this.state.newPageNo}
                                            pageStart={1}
                                            initialLoad={false}
                                            // hasMore={this.state.newPageNo < (total / 9)}

                                            hasMore={patternListData.length < total ? true : false}

                                            // loader=
                                            useWindow={true}
                                            loadMore={this.loadFunc}
                                        >

                                            {
                                                patternListData && patternListData.length > 0 ? patternListData.map((item, i) => {
                                                    let amt = item.amount.toString().includes(".") ? item.amount.toString().split('.') : []

                                                    let amtLength = amt && amt.length > 0 && amt[1].toString().length > 1 ? false : true

                                                    return (

                                                        <div key={i}>
                                                            <div className={item.status === false ? "FalseDIV" : ""}>

                                                                <div className="pattern-list-box">
                                                                    {item && item.image.length > 0 ?
                                                                        <div className="pattern-list-img">
                                                                            <img src={this.state.imageStatus === "loading" ? "" : `${SERVER_URL}uploads/${item.image[0]}`}
                                                                                onLoad={this.handleImageLoaded}
                                                                                onError={this.handleImageErrored}
                                                                            />
                                                                        </div>
                                                                        :
                                                                        <div className="pattern-list-img">
                                                                            <img src={this.state.imageStatus === "loading" ? "" : `${SERVER_URL}uploads/ComingSoon.png`}
                                                                                onLoad={this.handleImageLoaded}
                                                                                onError={this.handleImageErrored}
                                                                            />
                                                                        </div>
                                                                    }
                                                                    <div className="pattern-list-content">

                                                                        <h2> {item.patternName}</h2>
                                                                        <div className="author-price">
                                                                            <h5>{item.authorName}</h5>

                                                                            <h6><span>Price :</span> $<strong>{item.amount.toString().includes('.') ? amtLength === true ? item.amount + '0' : item.amount : item.amount + '.00'}</strong></h6>
                                                                        </div>
                                                                        <div >
                                                                            <h4>
                                                                                <span>Purchases : </span><strong>0</strong>
                                                                            </h4>
                                                                        </div>
                                                                        <div className="difficulty">

                                                                            <div className="d-flex">
                                                                                <span>{this.Capitalize(item.difficultyType)}</span>
                                                                                <ul className="d-flex">
                                                                                    <li className={item.difficultyType === "Totally doable" || item.difficultyType === "You got this" || item.difficultyType === "In it to win it" || item.difficultyType === "You are a boss" ? "fill-bg" : ""}></li>
                                                                                    <li className={item.difficultyType === "You got this" || item.difficultyType === "In it to win it" || item.difficultyType === "You are a boss" ? "fill-bg" : ""}></li>
                                                                                    <li className={item.difficultyType === "In it to win it" || item.difficultyType === "You are a boss" ? "fill-bg" : ""}></li>
                                                                                    <li className={item.difficultyType === "You are a boss" ? "fill-bg" : ""}></li>

                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="pattern-action">
                                                                        <div className="edit-on-click">
                                                                            <Button className="pattern-action-button" onClick={(e) => this.patternEdit(e, item._id)}></Button>
                                                                        </div>
                                                                        <ul>
                                                                            {/* <li>
                                                                            <Button className="pattern-action-button" onClick={(e) => this.handleView(e, item._id, item.patternName)}>
                                                                                <i><img src="images/eye.svg" alt="" /></i> <span>VIEW</span>
                                                                            </Button></li> */}
                                                                            <li>
                                                                                <Button className="pattern-action-button" onClick={(e) => this.patternEdit(e, item._id)}>
                                                                                    <i><img src="images/edit.svg" alt="" /></i> <span>EDIT</span>
                                                                                </Button>
                                                                            </li>
                                                                            <li> <Button className="pattern-action-button" onClick={(e) => this.handleduplicate(e, item)} >
                                                                                <i><img src="images/duplicate.svg" alt="" /></i> <span>DUPLICATE</span>
                                                                            </Button>  </li>
                                                                            <li>
                                                                                <Popconfirm
                                                                                    title="Are you sure you want to permanently delete this?"
                                                                                    onConfirm={(e) => this.handleDelete(e, item._id)}
                                                                                    onCancel={this.cancel}
                                                                                    okText="Yes"
                                                                                    cancelText="No"
                                                                                >
                                                                                    <Button className="pattern-action-button">
                                                                                        <i><img src="images/delete.svg" alt="" /></i> <span>DELETE</span>
                                                                                    </Button>
                                                                                </Popconfirm>
                                                                            </li>


                                                                            <li className="standout-btn"> <Button className="pattern-action-button" onClick={(e) => this.handleSize(e, item._id)}>
                                                                                <i><img src="images/size.svg" alt="" /></i> <span>VIEW SIZES</span>
                                                                            </Button>  </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                    :
                                                    !loading && <div className="white-box emply-data-message">
                                                        <p>Hi there! <span>Sadly there are no patterns yet.</span> Feel free to click on the button in the top right corner to add one</p>
                                                    </div>
                                            }
                                        </InfiniteScroll>
                                }

                            </div>

                        </div>
                    </div>

                </div>
            </Layout>
        )
    }
}

const mapStateToProps = ((state) => {
    let { loading, data, patternData, error } = state.patternList
    return {
        loading,
        data,
        patternData,
        error
    }
})

export default connect(mapStateToProps)(ListPatternComponent);