import { SERVER_URL } from "../../api";
import axios from 'axios';
import { getHeaderWithToken } from "../../helpers/func";

export const GET_NEEDLES_REQUEST = 'GET_NEEDLES_REQUEST';
export const GET_NEEDLES_SUCCESS = 'GET_NEEDLES_SUCCESS';
export const GET_NEEDLES_ERROR = 'GET_NEEDLES_ERROR';
export const RESET_GET_NEEDLES = 'RESET_GET_NEEDLES';


export const getNeedles = () => {
    let headers = getHeaderWithToken();

    return dispatch => {
        return new Promise(function (resolve, reject) {
            dispatch({
                type: GET_NEEDLES_REQUEST
            })
            axios({
                method: 'GET',
                url: `${SERVER_URL}v1/needle`,
                headers: headers,

            }).then(function (response) {
                dispatch({
                    type: GET_NEEDLES_SUCCESS,
                    payload: response
                })
                resolve(response);
            })
                .catch(function (error) {
                    dispatch({
                        type: GET_NEEDLES_ERROR,
                        payload: error
                    })
                    reject(error)
                })
        })
    }
}



