import { SERVER_URL } from "../../api";
import axios from 'axios';
import FormData from "form-data";

import { getHeaderWithToken } from "../../helpers/func";
export const ADD_STITCH_REQUEST = 'ADD_STITCH_REQUEST';
export const ADD_STITCH_SUCCESS = 'ADD_STITCH_SUCCESS';
export const ADD_STITCH_ERROR = 'ADD_STITCH_ERROR';
export const RESET_ADD_STITCH = 'RESET_ADD_STITCH';


export const addStitch = (data, edit) => {
    let headers = getHeaderWithToken();
    const formdata = new FormData();

    if (data && data.name !== undefined) {
        formdata.append('name', data.name);

    }
    if (data && data.abbreviation !== undefined) {
        formdata.append('abbreviation', data.abbreviation);

    }
    if (data && data.type !== undefined) {
        formdata.append('type', data.type);

    }
    if (data && data.short_instruction !== undefined) {
        formdata.append('short_instruction', data.short_instruction);

    }
    if (data && data.long_instruction !== undefined) {
        formdata.append('long_instruction', data.long_instruction);

    }
    if (data && data.link !== undefined) {
        formdata.append('link', data.link);

    }
    if (data && data.height !== undefined) {
        formdata.append('height', data.height);

    }
    if (data && data.width !== undefined) {
        formdata.append('width', data.width);

    }


    if (edit) {
        formdata.append('id', data.id);
    }
    if (data.fileList) {

        for (let i = 0; i < data.fileList.length; i++) {
            formdata.append("image", data.fileList[i].originFileObj);
        }
    }


    return dispatch => {
        return new Promise(function (resolve, reject) {

            dispatch({
                type: ADD_STITCH_REQUEST
            })
            axios({
                method: edit ? 'PUT' : 'POST',
                url: `${SERVER_URL}v1/pattern/stitches`,
                headers: headers,
                data: formdata

            }).then(function (response) {
                dispatch({
                    type: ADD_STITCH_SUCCESS,
                    payload: response
                })
                resolve(response);

            })
                .catch(function (error) {
                    dispatch({
                        type: ADD_STITCH_ERROR,
                        payload: error
                    })
                    reject(error)
                })
        })

    }
}
export const resetAddStitchData = () => {
    return dispatch => {
        dispatch({
            type: RESET_ADD_STITCH
        })
    }
}


