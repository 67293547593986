import { SERVER_URL } from "../../api";
import axios from 'axios'
import { getHeaderWithToken } from "../../helpers/func";
export const DELETE_SCHEMATIC_REQUEST = 'DELETE_SCHEMATIC_REQUEST';
export const DELETE_SCHEMATIC_SUCCESS = 'DELETE_SCHEMATIC_SUCCESS';
export const DELETE_SCHEMATIC_ERROR = 'DELETE_SCHEMATIC_ERROR';
export const RESET_DELETE_SCHEMATIC = 'RESET_DELETE_SCHEMATIC';


export const deleteSchematic = (id, data) => {
    let headers = getHeaderWithToken();
    return dispatch => {
        return new Promise(function (resolve, reject) {
            dispatch({
                type: DELETE_SCHEMATIC_REQUEST
            })
            axios({
                method: 'DELETE',
                url: `${SERVER_URL}v1/pattern/schematic`,
                headers: headers,
                data: { id, data }
            }).then(function (response) {
                dispatch({
                    type: DELETE_SCHEMATIC_SUCCESS,
                    payload: response
                })
                resolve(response);
            })
                .catch(function (error) {
                    dispatch({
                        type: DELETE_SCHEMATIC_ERROR,
                        payload: error
                    })
                    reject(error)
                })
        })

    }
}



