import { GET_NEEDLES_REQUEST, GET_NEEDLES_SUCCESS, GET_NEEDLES_ERROR } from "../actions/needles"

let initialState = {
    loading: false,
    data: null,
    error: null
}

const getNeedles = (state = initialState, action) => {
    switch (action.type) {
        case GET_NEEDLES_REQUEST:
            return {
                ...state,
                loading: true
            }

        case GET_NEEDLES_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            }
        case GET_NEEDLES_ERROR:
            return {
                ...state,
                loading: false,
                data: action.payload
            }

        default:
            return state;
    }
}

export default getNeedles