import React, { Component } from 'react'
// import { connect } from 'react-redux';
import { Checkbox } from 'antd';

export default class Marker extends Component {
    constructor(props) {
        super(props)
        this.state = {
            markValue: this.props.markValue,
        }
    }




    handleBorderClick = (e, val) => {
        e.preventDefault();
        let { checkboxValue } = this.state;
        let tempval = { ...checkboxValue }
        const newBorder = e.target.checked ? val : "";
        tempval.border = newBorder;


        this.setState({ markValue: newBorder, checkboxValue: tempval },
            () => this.props.eBorder(e, { markValue: newBorder, checkboxValue: tempval })
        )
    }

    componentDidUpdate(prevProps, prevState) {
        let { markValue } = this.props;
        if (markValue === false && prevProps.markValue !== markValue) {
            this.setState({ markValue: markValue })
        }
    }


    render() {
        let { markValue } = this.state;
        return (
            <div className="StitchMarker grid-box-wrap repeat-div">
                <h2>Stitch Marker</h2>
                <Checkbox
                    value="BlackBDR-left"
                    checked={markValue === "BlackBDR-left" ? true : false}
                    onChange={(e) => this.handleBorderClick(e, "BlackBDR-left")}
                ></Checkbox>

                <Checkbox
                    value="BlackBDR-right"
                    checked={markValue === "BlackBDR-right" ? true : false}
                    onChange={(e) => this.handleBorderClick(e, "BlackBDR-right")}
                ></Checkbox>

                <Checkbox
                    value="TopBottomstitchMarker"
                    checked={markValue === "TopBottomstitchMarker" ? true : false}
                    onChange={(e) => this.handleBorderClick(e, "TopBottomstitchMarker")}
                ></Checkbox>

            </div>
        )
    }
}

// const mapStateToProps = ((state) => {

// })
// export default connect(mapStateToProps)(Border);