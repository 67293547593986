import React, { Component } from 'react'
import { Form, Input, Button, message } from 'antd';
import { login } from '../redux/actions/login';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { adminRoutes, ADMIN_BASE_URL } from '../helpers/adminRoutes';
import { isLogin } from '../helpers/func';


function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}



class LoginForm extends Component {
    handleValidationforEmail = (a, b, c) => {
        let regex = /^\w+[\w-.]*@\w+((-\w+)|(\w*))\.[a-z]{2,3}$/
        if (b.match(regex)) {
            c();
        } else {
            c("Please enter valid email");
        }
    }

    handleSubmit = e => {
        let { dispatch } = this.props;
        e.preventDefault();
        this.props.form.validateFields((err, values) => {

            if (!err) {
                dispatch(login(values))
            }
        });
    };


    render() {
        const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;
        const usernameError = isFieldTouched('email') && getFieldError('email');
        const passwordError = isFieldTouched('password') && getFieldError('password');
        return (
            <div className="login-page">
                <div className="login-box">
                    <h2>Patterfy</h2>
                    <Form layout="block" onSubmit={this.handleSubmit} style={{ width: "50 %" }}>
                        <Form.Item validateStatus={usernameError ? 'error' : ''} help={usernameError || ''}>
                            <label>Email</label>
                            {getFieldDecorator('email', {
                                rules: [

                                    {
                                        type: 'email',
                                        message: 'The input is not valid E-mail!',
                                    },
                                    {
                                        required: true,
                                        message: 'Please input your E-mail!',
                                    },
                                    { validator: this.handleValidationforEmail }

                                ],
                            })(
                                <Input
                                    placeholder="Email"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item validateStatus={passwordError ? 'error' : ''} help={passwordError || ''} >
                            <label>Password</label>
                            {getFieldDecorator('password', {
                                rules: [{ required: true, message: 'Please input your Password!' }],
                            })(
                                <Input
                                    type="password"
                                    placeholder="Password"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item className="login-submit-btn">
                            <Link className="login-form-forgot" to={'forget_password'} > Forgot password</Link>
                            <Button type="primary" htmlType="submit" disabled={hasErrors(getFieldsError())}>
                                Log in
                            </Button>
                        </Form.Item>

                    </Form>

                </div>
                {/* <div className="blink"> Staging</div> */}

            </div>
        )
    }

    componentDidMount() {
        this.props.form.validateFields();
    }
    componentDidUpdate(prevProps) {
        let { loading, data, history } = this.props;

        if (!loading && prevProps.loading !== loading) {
            if (data && prevProps.data !== data) {
                if (data && data.status === 200) {
                    localStorage.setItem("adminData", JSON.stringify(data.data))
                    history.push(adminRoutes.PATTERNLIST)
                }
                else {
                    message.error(data && data.data.message)
                    history.push(ADMIN_BASE_URL)
                }
                if (isLogin()) {
                    this.props.history.push(adminRoutes.PATTERNLIST);
                }
            }

        }
    }
}



const mapStateToProps = ((state) => {
    let { loading, data, error } = state.login
    return {
        loading,
        data,
        error
    }
})

const LoginComponent = Form.create({ name: 'login_form' })(LoginForm);
export default connect(mapStateToProps)(LoginComponent);
