import { SERVER_URL } from "../../api";
import axios from 'axios';
import { getHeaderWithToken } from "../../helpers/func";

export const GET_STITCHES_REQUEST = 'GET_STITCHES_REQUEST';
export const GET_STITCHES_SUCCESS = 'GET_STITCHES_SUCCESS';
export const GET_STITCHES_ERROR = 'GET_STITCHES_ERROR';
export const RESET_GET_STITCHES = 'RESET_GET_STITCHES';


export const getStitch = (data) => {
    let start = data && data.start;
    let length = data && data.length;
    let headers = getHeaderWithToken();

    return dispatch => {
        return new Promise(function (resolve, reject) {
            dispatch({
                type: GET_STITCHES_REQUEST
            })
            axios({
                method: 'GET',
                url: `${SERVER_URL}v1/patterns/stitch`,
                headers: headers,
                params: { start: start, length: length }

            }).then(function (response) {
                dispatch({
                    type: GET_STITCHES_SUCCESS,
                    payload: response
                })
                resolve(response);
            })
                .catch(function (error) {
                    dispatch({
                        type: GET_STITCHES_ERROR,
                        payload: error
                    })
                    reject(error)
                })
        })
    }
}



