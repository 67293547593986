import { ADD_CHART_REQUEST, ADD_CHART_SUCCESS, ADD_CHART_ERROR, RESET_ADD_CHART } from "../actions/addChart"

let initialState = {
    loading: false,
    data: null,
    error: null
}

const addChart = (state = initialState, action) => {
    switch (action.type) {
        case ADD_CHART_REQUEST:
            return {
                ...state,
                loading: true
            }

        case ADD_CHART_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            }
        case ADD_CHART_ERROR:
            return {
                ...state,
                loading: false,
                data: action.payload
            }
        case RESET_ADD_CHART:
            return {
                ...state,
                loading: false,
                data: null,
                error: null
            }

        default:
            return state;
    }
}

export default addChart