import { SERVER_URL } from "../../api";
import axios from 'axios';
import { getHeaderWithToken } from "../../helpers/func";

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_ERROR = 'GET_USER_ERROR';
export const RESET_GET_USER = 'RESET_GET_USER';
export const RESET_List_USER = 'RESET_List_USER';


export const getUser = (data) => {
    console.log('data', data.start);
    if (data && data.start === NaN) {

        var start = 0;
    } else {

        var start = data && data.start;
    }

    let length = data && data.length;
    let drpVal = data && data.drpVal;
    let beta = data && data.beta;

    let headers = getHeaderWithToken();

    return dispatch => {
        return new Promise(function (resolve, reject) {
            dispatch({
                type: GET_USER_REQUEST
            })
            axios({
                method: 'POST',
                url: `${SERVER_URL}v1/pattern/users`,
                headers: headers,
                params: { start: start, length: length, drpVal: drpVal, beta: beta }

            }).then(function (response) {
                dispatch({
                    type: GET_USER_SUCCESS,
                    payload: response
                })
                resolve(response);
            })
                .catch(function (error) {
                    dispatch({
                        type: GET_USER_ERROR,
                        payload: error
                    })
                    reject(error)
                })
        })
    }
}



export const resetListUser = () => {
    return dispatch => {
        dispatch({
            type: RESET_List_USER
        })
    }
}