import { DELETE_SCHEMATIC_REQUEST, DELETE_SCHEMATIC_SUCCESS, DELETE_SCHEMATIC_ERROR } from "../actions/deleteSchematic"

let initialState = {
    loading: false,
    data: null,
    error: null
}

const deleteSchematic = (state = initialState, action) => {
    switch (action.type) {
        case DELETE_SCHEMATIC_REQUEST:
            return {
                ...state,
                loading: true
            }

        case DELETE_SCHEMATIC_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            }
        case DELETE_SCHEMATIC_ERROR:
            return {
                ...state,
                loading: false,
                data: action.payload
            }

        default:
            return state;
    }
}

export default deleteSchematic