import { SERVER_URL } from "../../api";
import axios from 'axios'
export const FORGET_PASSWORD_REQUEST = 'FORGET_PASSWORD_REQUEST';
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_ERROR = 'FORGET_PASSWORD_ERROR';
export const RESET_FORGET_PASSWORD = 'RESET_FORGET_PASSWORD';


export const forget_password = (data) => {

    return dispatch => {
        dispatch({
            type: FORGET_PASSWORD_REQUEST
        })
        axios({
            method: 'POST',
            url: `${SERVER_URL}v1/forgot_password`,
            data: data
        }).then(function (response) {
            dispatch({
                type: FORGET_PASSWORD_SUCCESS,
                payload: response
            })
        })
            .catch(function (error) {
                dispatch({
                    type: FORGET_PASSWORD_ERROR,
                    payload: error
                })
            })

    }
}



