import { SERVER_URL } from "../../api";
import axios from 'axios'
import { getHeaderWithToken } from "../../helpers/func";
export const PATTERN_LIST_REQUEST = 'PATTERN_LIST_REQUEST';
export const PATTERN_LIST_SUCCESS = 'PATTERN_LIST_SUCCESS';
export const PATTERN_LIST_ERROR = 'PATTERN_LIST_ERROR';
export const RESET_PATTERN_LIST = 'RESET_PATTERN_LIST';


export const patternList = (data) => {
    console.log('mansi data', data);

    let start = data && data.start;
    let length = data && data.length;
    let headers = getHeaderWithToken();
    return dispatch => {
        return new Promise(function (resolve, reject) {
            dispatch({

                type: PATTERN_LIST_REQUEST
            })
            axios({
                method: 'POST',
                url: `${SERVER_URL}v1/patterns`,
                headers: headers,
                data: { start: start, length: length, search: data.search }
            }).then(function (response) {
                dispatch({
                    type: PATTERN_LIST_SUCCESS,
                    payload: response
                })
                resolve(response);
            })
                .catch(function (error) {
                    dispatch({
                        type: PATTERN_LIST_ERROR,
                        payload: error
                    })
                    reject(error)
                })
        })
    }
}



