import React, { Component } from 'react'
import Layout from './Layout';
import { Button, Popconfirm, message, Spin } from 'antd';
import { adminRoutes } from '../helpers/adminRoutes';
import { connect } from 'react-redux';
import { sizeList } from '../redux/actions/sizeList';
import { deleteSize } from '../redux/actions/deleteSize';
import { viewSize } from '../redux/actions/viewSize';
import { Breadcrumb } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import { addSize, resetAddSizeDatas } from '../redux/actions/addSize';
import { Link } from 'react-router-dom';
import { resetAddSizeData } from '../redux/actions/search';


class ListSize extends Component {
    constructor(props) {
        super(props)
        this.state = {
            patternId: '',
            pageNo: 0,
            loadingApi: false,
            sizeListData: [],
        }
    }



    componentDidMount() {
        let { dispatch, location } = this.props;
        let path = location.pathname.split('/')
        let id = path[2];
        dispatch(resetAddSizeDatas())

        this.loadFunc(1)
        this.setState({ patternId: id })
        dispatch(sizeList(id))
    }
    handleSection = (e, sectionId) => {
        let { history } = this.props;
        let { patternId } = this.state;
        e.preventDefault();
        history.push(adminRoutes.SECTIONLIST + '/' + patternId + '/' + sectionId)
    }

    loadFunc = (e, string, value) => {
        if (string === 'delete' || string === 'duplicate') {
            this.setState({ loadingApi: true });
        }
        let { dispatch, location } = this.props;

        dispatch(resetAddSizeData())

        let path = location.pathname.split('/')
        let id = path[2];
        let page = (e - 1) * 9
        let data = {
            start: page,
            length: 9,
            search: value ? value : ''
        }

        let { sizeListData, } = this.state
        let datas = string === 'delete' || string === 'search' || string === 'duplicate' ? [] : sizeListData;
        dispatch(sizeList({ id: id, ...data })).then(res => {
            this.setState({ recordsTotals: res && res.data && res.data.recordsTotal })
            this.setState({ pageNo: page + 9 })
            res.data.data.map(dat => (
                datas.push(dat)
            ))
            function compare(a, b) {
                const bandA = b.position
                const bandB = a.position

                let comparison = 0;
                if (bandB > bandA) {
                    comparison = 1;
                } else if (bandB < bandA) {
                    comparison = -1;
                }
                return comparison;
            }

            datas.sort(compare);
            dispatch(resetAddSizeDatas())

            // this.setState({ sizeListData: datas, loadingApi: false })
            setTimeout(() => {
                this.setState({ sizeListData: datas, loadingApi: false })
            }, 200)
        })

    }

    confirm = (e) => {
        message.success('Click on Yes');
    }

    cancel = (e) => {

    }
    sizeEdit = (e, id) => {
        let { data } = this.props;
        let patternId = data && data.data && data.data.id;

        this.props.history.push(adminRoutes.EDITSIZE + "/" + patternId + "/" + id)
    }

    handleView = (e, id) => {
        let { dispatch, history } = this.props;
        e.preventDefault();
        dispatch(viewSize(id))
        history.push(adminRoutes.VIEWSIZE + '/' + id)
    }
    handleDelete = (e, id) => {
        e.preventDefault();
        let { dispatch } = this.props;
        dispatch(deleteSize(id)).then(res => {
            if (res && res.status === 200) {

                this.loadFunc(1, 'delete');
                window.location.reload()
            }
        })
    }

    handleduplicate = (e, data) => {
        e.preventDefault();
        let { dispatch } = this.props;

        dispatch(addSize({ type: "duplicate", ...data, "patternId": data.patternId })).then(res => {
            if (res && res.status === 200) {

                this.loadFunc(1, 'duplicate', '')
                dispatch(resetAddSizeData())

            }
        })

    }

    patternEdit = (e, id) => {
        this.props.history.push(adminRoutes.EDITPATTERN + "/" + id)
    }

    sizeAdd = (e, id) => {
        let { patternId } = this.state;

        this.props.history.push(adminRoutes.ADDSIZE + "/" + patternId)
    }

    render() {
        let { loading, data } = this.props;
        let { sizeListData, loadingApi } = this.state;
        let total = data && data.data && data.data.data.length > 0 && data.data.recordsTotal

        return (
            <Layout>
                <div className="content-box">
                    <div className="bredcrumb-div">
                        <Breadcrumb separator="-">

                            <Breadcrumb.Item><Link to={adminRoutes.PATTERNLIST}>{data && data.data && data.data.pattern && data.data.pattern.patternName}</Link></Breadcrumb.Item>
                            <Breadcrumb.Item>Size</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className="page-title d-flex">
                        <div className="PageTitleLeft">

                            <h2>Size List</h2>
                            <h3> {this.state.pageNo > this.state.recordsTotals ?
                                this.state.recordsTotals : this.state.pageNo}
                                /{this.state.recordsTotals}</h3>
                        </div>
                        <Button onClick={this.sizeAdd} type="primary" htmlType="submit" className="primary-btn"> ADD SIZE </Button>
                    </div>
                    <div className="page-content">
                        <div className="pattern-list">
                            <div className="pattern_wrapper">
                                {
                                    loadingApi === true ? <div><Spin /></div> :
                                        <InfiniteScroll
                                            pageStart={1}
                                            initialLoad={false}
                                            hasMore={sizeListData.length < total ? true : false}
                                            // loader={<div >Loading ...</div>}
                                            useWindow={true}
                                            loadMore={this.loadFunc}
                                        >
                                            {

                                                sizeListData && sizeListData.length > 0 ? sizeListData.map((item, i) => (
                                                    <div key={i}>
                                                        <div className={item.status === false ? "FalseDIV" : ""}>
                                                            <div className="pattern-list-box size-list-box">
                                                                <div className="pattern-list-content ">
                                                                    <h2>{item.sizeName}</h2>
                                                                    <div className="d-flex size-info-head">
                                                                        {item && item.measurement.length > 0 && item.measurement.map((value, index) => (
                                                                            <h3 key={index}>
                                                                                <span>{value && value.label_name != null ? value.label_name : ""} : </span>
                                                                                <big>{value && value.label_measurement != null ? value.label_measurement : ""}</big>
                                                                            </h3>
                                                                        ))
                                                                        }
                                                                        <h3><span>Gauge : </span><big>{item.gauge}</big></h3>


                                                                    </div>

                                                                </div>
                                                                <div className="pattern-action">
                                                                    <div className="edit-on-click">
                                                                        <Button className="pattern-action-button" onClick={(e) => this.sizeEdit(e, item._id)}></Button>
                                                                    </div>
                                                                    <ul>
                                                                        {/* <li>
                                                                <Button className="pattern-action-button" onClick={(e) => this.handleView(e, item._id)}>
                                                                    <i><img src="images/eye.svg" alt="" /></i> <span>VIEW</span>
                                                                </Button></li> */}
                                                                        <li>
                                                                            <Button className="pattern-action-button" onClick={(e) => this.sizeEdit(e, item._id)}>
                                                                                <i><img src="images/edit.svg" alt="" /></i> <span>EDIT</span>
                                                                            </Button>
                                                                        </li>

                                                                        <li> <Button className="pattern-action-button" onClick={(e) => this.handleduplicate(e, item)} >
                                                                            <i><img src="images/duplicate.svg" alt="" /></i> <span>DUPLICATE</span>
                                                                        </Button>  </li>
                                                                        <li>
                                                                            <Popconfirm
                                                                                title="Are you sure you want to permanently delete this?"
                                                                                onConfirm={(e) => this.handleDelete(e, item._id)}
                                                                                onCancel={this.cancel}
                                                                                okText="Yes"
                                                                                cancelText="No"
                                                                            >
                                                                                <Button className="pattern-action-button">
                                                                                    <i><img src="images/delete.svg" alt="" /></i> <span>DELETE</span>
                                                                                </Button>
                                                                            </Popconfirm>
                                                                        </li>

                                                                        <li className="standout-btn"> <Button className="pattern-action-button" onClick={(e) => this.handleSection(e, item._id)}>
                                                                            <i><img src="images/size.svg" alt="" /></i> <span>VIEW SECTION</span>
                                                                        </Button>  </li>
                                                                    </ul>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                ))
                                                    :
                                                    <div className="white-box emply-data-message">
                                                        {!loading && <p>Hi there! <span>Sadly there are no sizes yet.</span> Feel free to click on the button in the top right corner to add one</p>
                                                        }</div>
                                            }

                                        </InfiniteScroll>

                                }
                            </div>

                        </div>
                    </div>
                </div>
            </Layout >
        )
    }
}

const mapStateToProps = ((state) => {
    let { loading, data, sizeData, error } = state.sizeList
    return {
        loading,
        data,
        sizeData,
        error
    }
})

export default connect(mapStateToProps)(ListSize);
