import { SERVER_URL } from "../../api";
import axios from 'axios'
import { getHeaderWithToken } from "../../helpers/func";
export const DELETE_PATTERN_REQUEST = 'DELETE_PATTERN_REQUEST';
export const DELETE_PATTERN_SUCCESS = 'DELETE_PATTERN_SUCCESS';
export const DELETE_PATTERN_ERROR = 'DELETE_PATTERN_ERROR';
export const RESET_DELETE_PATTERN = 'RESET_DELETE_PATTERN';


export const deletePattern = (data) => {
    let headers = getHeaderWithToken();
    return dispatch => {
        return new Promise(function (resolve, reject) {
            dispatch({
                type: DELETE_PATTERN_REQUEST
            })
            axios({
                method: 'DELETE',
                url: `${SERVER_URL}v1/pattern/${data}`,
                headers: headers
            }).then(function (response) {
                dispatch({
                    type: DELETE_PATTERN_SUCCESS,
                    payload: response
                })
                resolve(response);
            })
                .catch(function (error) {
                    dispatch({
                        type: DELETE_PATTERN_ERROR,
                        payload: error
                    })
                    reject(error)
                })
        })

    }
}



