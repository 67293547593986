import { PATTERN_LIST_REQUEST, PATTERN_LIST_SUCCESS, PATTERN_LIST_ERROR } from "../actions/patternList"

let initialState = {
    loading: false,
    data: null,
    patternData: [],
    error: null
}


const patternList = (state = initialState, action) => {

    switch (action.type) {
        case PATTERN_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }

        case PATTERN_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            }
        case PATTERN_LIST_ERROR:
            return {
                ...state,
                loading: false,
                data: action.payload
            }

        default:
            return state;
    }
}

export default patternList