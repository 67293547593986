import { VIEW_PATTERN_REQUEST, VIEW_PATTERN_SUCCESS, VIEW_PATTERN_ERROR, RESET_VIEW_PATTERN } from "../actions/viewPattern"

let initialState = {
    loading: false,
    data: null,
    error: null
}

const viewPattern = (state = initialState, action) => {
    switch (action.type) {
        case VIEW_PATTERN_REQUEST:
            return {
                ...state,
                loading: true
            }

        case VIEW_PATTERN_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            }
        case VIEW_PATTERN_ERROR:
            return {
                ...state,
                loading: false,
                data: action.payload
            }
        case RESET_VIEW_PATTERN:
            return {
                ...state,
                loading: false,
                data: null,
                error: null
            }

        default:
            return state;
    }
}

export default viewPattern