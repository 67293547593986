import { SERVER_URL } from "../../api";
import axios from 'axios'
import FormData from "form-data";
import { getHeaderWithToken } from "../../helpers/func";
export const ADD_PATTERN_REQUEST = 'ADD_PATTERN_REQUEST';
export const ADD_PATTERN_SUCCESS = 'ADD_PATTERN_SUCCESS';
export const ADD_PATTERN_ERROR = 'ADD_PATTERN_ERROR';
export const RESET_ADD_PATTERN = 'RESET_ADD_PATTERN';


export const addPattern = (data, edit, image) => {

    let headers = getHeaderWithToken();

    const formdata = new FormData();

    formdata.append('patternName', data.patternName);
    formdata.append('position', data.position);
    formdata.append('beta', data.beta);
    if (data.measurement) {
        formdata.append('measurement', JSON.stringify(data.measurement));
    }
    if (data.kits) {
        let arr = [];
        for (const a of data.kits) {
            // var colors = { color: data.color[i] }
            if (a !== undefined) {
                console.log('a.kit_price', a.kit_price);
                if (a.kit_price == null || a.kit_price == undefined) {
                    a.kit_price = parseFloat(0).toFixed(2)

                } else {

                    a.kit_price = parseFloat(a && a.kit_price).toFixed(2)
                }
                arr.push(a)
            }
        }
        formdata.append('kits', JSON.stringify(arr));
    }
    // if (data.link2) {
    //     formdata.append('link2', data.link2);
    // }
    // if (data.link3) {
    //     formdata.append('link3', data.link3);
    // }


    if (data.authorEmail || typeof data.authorEmail == "string") {
        formdata.append('authorEmail', data.authorEmail);
    }
    if (data.authorName || typeof data.authorName == "string") {
        formdata.append('authorName', data.authorName);
    }
    if (data.kit_status) {
        formdata.append('kit_status', data.kit_status);
    }
    if (data.kit_label || typeof data.kit_label == "string") {
        formdata.append('kit_label', data.kit_label);
    }
    if (data.kit_description || typeof data.kit_description == "string") {
        formdata.append('kit_description', data.kit_description);
    }
    if (data.color) {

        for (let i = 0; i < data.color.length; i++) {
            // var colors = { color: data.color[i] }
            formdata.append("colors", JSON.stringify(data.color[i]));
        }
    }
    if (data.description || typeof data.description == "string") {
        formdata.append('description', data.description);
    }
    formdata.append('difficultyType', data.difficultyValue ? data.difficultyValue : data.difficultyType);
    formdata.append('status', data.status);
    if (edit) {
        formdata.append('id', data.id);
    }

    if (data.fileList) {

        for (let i = 0; i < data.fileList.length; i++) {
            formdata.append("image", data.fileList[i].originFileObj);
        }
    }
    if (data.schematicfileList) {

        for (let i = 0; i < data.schematicfileList.length; i++) {
            formdata.append("schematic", data.schematicfileList[i].originFileObj);
        }
    }
    if (data && data.type) {
        formdata.append("id", data._id);

        formdata.append('type', data.type);
        for (let i = 0; i < data.image.length; i++) {
            formdata.append("image", data.image[i]);
        }

    }

    if (data && data.DragImage) {
        formdata.append("DragImage", data.DragImage);
    }
    formdata.append('paymentType', data.paymentType);

    if (data && data.amount) {

        formdata.append('amount', parseFloat(data.amount).toFixed(2));
    }
    if (data && data.kit_price) {

        formdata.append('kit_price', parseFloat(data.kit_price).toFixed(2));
    }

    return dispatch => {
        return new Promise(function (resolve, reject) {
            dispatch({
                type: ADD_PATTERN_REQUEST
            })
            axios({
                method: edit ? 'PUT' : 'POST',
                url: `${SERVER_URL}v1/pattern`,
                headers: headers,
                data: formdata
            }).then(function (response) {
                dispatch({
                    type: ADD_PATTERN_SUCCESS,
                    payload: response
                })
                resolve(response);
            })
                .catch(function (error) {
                    dispatch({
                        type: ADD_PATTERN_ERROR,
                        payload: error
                    })
                    reject(error.response)
                })
        })
    }
}


export const resetAddPattern = () => {
    return dispatch => {
        dispatch({
            type: RESET_ADD_PATTERN
        })
    }
}
