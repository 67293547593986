import { SERVER_URL } from "../../api";
import axios from 'axios'
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';


export const reset_password = (data) => {

    return dispatch => {
        dispatch({
            type: RESET_PASSWORD_REQUEST
        })
        axios({
            method: 'POST',
            url: `${SERVER_URL}v1/reset_password`,
            data: data
        }).then(function (response) {
            dispatch({
                type: RESET_PASSWORD_SUCCESS,
                payload: response
            })
        })
            .catch(function (error) {
                dispatch({
                    type: RESET_PASSWORD_ERROR,
                    payload: error
                })
            })

    }
}



