import { SERVER_URL } from "../../api";
import axios from 'axios'
import { getHeaderWithToken } from "../../helpers/func";
export const ADD_USER_REQUEST = 'ADD_USER_REQUEST';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_ERROR = 'ADD_USER_ERROR';
export const RESET_ADD_USER = 'RESET_ADD_USER';


export const addUser = (data, edit) => {
    let headers = getHeaderWithToken();
    return dispatch => {
        return new Promise(function (resolve, reject) {

            dispatch({
                type: ADD_USER_REQUEST
            })
            axios({
                method: edit ? 'PUT' : 'POST',
                url: `${SERVER_URL}v1/pattern/add_user`,
                headers: headers,
                data: data
            }).then(function (response) {
                dispatch({
                    type: ADD_USER_SUCCESS,
                    payload: response
                })
                resolve(response);

            })
                .catch(function (error) {
                    dispatch({
                        type: ADD_USER_ERROR,
                        payload: error
                    })

                    reject(error)
                })
        })
    }
}

export const resetAddUSERDatas = () => {
    return dispatch => {
        dispatch({
            type: RESET_ADD_USER
        })
    }
}

