import { SERVER_URL } from "../../api";
import axios from 'axios'
import { getHeaderWithToken } from "../../helpers/func";
export const ADD_SIZE_REQUEST = 'ADD_SIZE_REQUEST';
export const ADD_SIZE_SUCCESS = 'ADD_SIZE_SUCCESS';
export const ADD_SIZE_ERROR = 'ADD_SIZE_ERROR';
export const RESET_ADD_SIZE = 'RESET_ADD_SIZE';


export const addSize = (data, edit) => {
    let headers = getHeaderWithToken();

    return dispatch => {
        return new Promise(function (resolve, reject) {

            dispatch({
                type: ADD_SIZE_REQUEST
            })
            axios({
                method: edit ? 'PUT' : 'POST',
                url: `${SERVER_URL}v1/pattern/size`,
                headers: headers,
                data: data
            }).then(function (response) {
                dispatch({
                    type: ADD_SIZE_SUCCESS,
                    payload: response
                })
                resolve(response);

            })
                .catch(function (error) {
                    dispatch({
                        type: ADD_SIZE_ERROR,
                        payload: error
                    })
                    reject(error)
                })
        })
    }
}

export const resetAddSizeDatas = () => {
    return dispatch => {
        dispatch({
            type: RESET_ADD_SIZE
        })
    }
}

