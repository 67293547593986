import { ADD_SIZE_REQUEST, ADD_SIZE_SUCCESS, ADD_SIZE_ERROR, RESET_ADD_SIZE } from "../actions/addSize"

let initialState = {
    loading: false,
    data: null,
    error: null
}

const addSize = (state = initialState, action) => {
    switch (action.type) {
        case ADD_SIZE_REQUEST:
            return {
                ...state,
                loading: true
            }

        case ADD_SIZE_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            }
        case ADD_SIZE_ERROR:
            return {
                ...state,
                loading: false,
                data: action.payload
            }
        case RESET_ADD_SIZE:
            return {
                ...state,
                loading: false,
                data: null,
                error: null
            }

        default:
            return state;
    }
}

export default addSize