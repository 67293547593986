import React, { Component } from 'react'
import Layout from './Layout';
import { Prompt, Link } from 'react-router-dom'
import { Form, Icon, Input, Button, message, Upload, Switch, InputNumber, Select, Checkbox, Modal } from 'antd';
import { connect } from 'react-redux';
import { addPattern } from '../redux/actions/addPattern';
import { adminRoutes } from '../helpers/adminRoutes';
import { viewPattern, resetViewPattern } from '../redux/actions/viewPattern';
import { login } from '../redux/actions/login';
import { Breadcrumb } from 'antd';
import { SERVER_URL } from '../api';
import { Beforeunload } from 'react-beforeunload';
import { SwatchesPicker } from 'react-color';
import { ReactSortable } from 'react-sortablejs';
import {
    sortableContainer,
    sortableElement,
    sortableHandle
} from 'react-sortable-hoc'; import arrayMove from 'array-move';
import { deleteImage } from '../redux/actions/deleteImage';
import { patternList } from '../redux/actions/patternList';
import { deleteSchematic } from '../redux/actions/deleteSchematic';
import { deleteColor } from '../redux/actions/deleteColor';
import TextArea from 'antd/lib/input/TextArea';
const { Option } = Select;

const SortableItem = sortableElement(({ value }) => <li>{value}</li>);

const SortableContainer = sortableContainer(({ children }) => {
    return <ul>{children}</ul>;
});

function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class AddPatternData extends Component {

    constructor(props) {
        super(props)
        this.state = {
            idLabel: 1,
            idkits: 0,
            arrOfkit: [],
            id: null,
            volume: 0,
            openTextBox: false,
            textIndex: null,
            tempPrice: "",
            tempKitPrice: "",
            totalRecords: 0,
            betaChecked: false,
            shippingChecked: false,
            fileList: [],
            schematicfileList: [],
            inputValue: 0,
            uploadValidation: [],
            validateArr: [],
            image: [],

            color: [],
            displayColorPicker: false,
            ChangeColorPicker: false,
            userData: null,
            defaulState: false,
            edit: false,
            selectedRadio: 'free',
            difficultyValue: 'Totally doable'
        }
    }

    componentWillUnmount() {
        this.setState({ edit: false, id: null });
        let { dispatch } = this.props;

        dispatch(resetViewPattern())
        let val = this.props.form.isFieldsTouched();
        if (val === true) {
        } else {
        }
    }





    handleDelete = (e, id, imageName) => {
        const img = imageName;
        e.preventDefault();
        let { dispatch } = this.props;
        dispatch(deleteImage(id._id, img)).then(res => {
            if (res && res.status === 200) {

                dispatch(viewPattern(id._id)).then(res => {
                    this.setState({ newKitStatus: res.data.data.kit_status, shippingChecked: res.data.data.isShipped, betaChecked: res && res.data && res.data.data && res.data.data.beta, difficultyValue: res && res.data && res.data.data && res.data.data.difficultyType, image: res && res.data && res.data.data && res.data.data.image, color: res && res.data && res.data.data && res.data.data.colors, inputValue: res && res.data && res.data.data && res.data.data.amount })
                    this.setState({ idLabel: res && res.data && res.data.data && res.data.data.measurement.length })
                    this.setState({ oldKits: res && res.data && res.data.data && res.data.data.kits.length, kits: res && res.data && res.data.data && res.data.data.kits, idkits: res && res.data && res.data.data && res.data.data.kits.length })

                })

            }
        })
    }

    handleDeleteSchematic = (e, id, imageName) => {
        const img = imageName;
        e.preventDefault();
        let { dispatch } = this.props;
        dispatch(deleteSchematic(id._id, img)).then(res => {
            if (res && res.status === 200) {
                dispatch(viewPattern(id._id));

            }
        })
    }

    handleDeleteColor = (e, id, colorId, index) => {
        e.preventDefault();

        // if (colorId._id == undefined) {
        var temp = this.state.color
        var data = temp.splice(index, 1)
        this.setState({ color: temp })
        // }
        // else {
        //     const colId = colorId._id;
        //     let { dispatch } = this.props;
        //     dispatch(deleteColor(id, colId)).then(res => {
        //         if (res && res.status === 200) {
        //             dispatch(viewPattern(id)).then(resp => {
        //                 this.setState({ color: this.state.color })

        //             })
        //         }
        //     })
    }


    removeFromDb = (i) => {
        var array = this.state.measurements;
        array.splice(i, 1);
        this.setState({ measurements: array, measurement: array, idLabel: array.length })
    }
    removekitsFromDb = (i) => {
        var arrOfkit = this.state.arrOfkit;

        var array = this.state.kits;
        array.splice(i, 1);
        this.setState({ oldKits: this.state.oldKits - 1, kits: array, kits: array, idkits: array.length })
    }

    add = (e) => {
        const { form } = this.props;
        const keys = form.getFieldValue('keys');
        let { idLabel } = this.state
        let id = idLabel;
        const nextKeys = keys.concat(id++);
        this.setState({ idLabel: id })
        form.setFieldsValue({
            keys: nextKeys,
        });
    };

    addkit = (e) => {
        const { form } = this.props;

        const keys = form.getFieldValue('kitkeys');
        let { idkits } = this.state
        let id = idkits;
        var arrOfkit = this.state.arrOfkit;
        arrOfkit.push({ index: id, val: false })
        console.log('mansi add arrOfkit => ', arrOfkit);

        this.setState({ arrOfkit: arrOfkit })
        const nextKeys = keys.concat(id++);
        this.setState({ idkits: id })
        form.setFieldsValue({
            kitkeys: nextKeys,
        });
    };

    remove = k => {
        const { form } = this.props;
        let { idLabel } = this.state
        if (this.state.edit === true) {
            this.state.measurements.splice(k, 1, this.state.measurements)
            var temp = idLabel
            temp = temp - 1
            let keys = [];
            for (let i = 1; i <= this.state.idLabel; i++) {
                keys.push(i)

            }
            this.setState({ keys: keys, measurements: this.state.measurements, idLabel: temp })
            form.setFieldsValue({
                keys: keys,
            });
        }
        else {

            var keys = form.getFieldValue('keys');
            if (keys.length === 1) {
                return;
            }

            form.setFieldsValue({
                keys: keys.filter(key => key !== k),
            });
        }



    };

    removekit = k => {
        const { form } = this.props;
        let { idLabel, arrOfkit } = this.state

        console.log('mansi first arrOfkit => ', arrOfkit);

        var keys = form.getFieldValue('kitkeys');
        if (keys.length === 1) {
            return;
        }
        console.log('mansi k => ', k);

        let newkit = arrOfkit.filter(kit => kit.index !== k)
        // let newkit = arrOfkit.splice(k, 1);
        console.log('mansi newkit => ', newkit);

        this.setState({ arrOfkit: newkit })
        form.setFieldsValue({
            kitkeys: keys.filter(key => key !== k),
        });
        // }



    };

    handleColorText = (e, id, colorId, index) => {
        e.preventDefault();
        this.setState({ openTextBox: true, textIndex: index })
    }

    handleColorChangeComplete = (color, index) => {


        let colorData = this.state.color;
        // let Datas = { "color": color.hex, "positions": colorData.length + 1 };
        let Datas = { "color": color.hex };

        if (typeof index === "number") {

            var newItem = colorData[index]
            var Datass = {
                "color": color.hex,
                // "positions": index + 1
            }
            if (newItem && newItem._id) {
                Datass._id = newItem._id
            }
            colorData.splice(index, 1, Datass)
            this.setState({ color: colorData, ChangeColorPicker: false });

        }
        else {

            colorData.push(Datas);
            this.setState({ color: colorData });
        }
    };

    handleAddTextColor = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {

            var Datass = {
                "color": this.state.color[this.state.textIndex].color,
                "text": values.text,
                "positions": values.positions
            }
            if (this.state.color[this.state.textIndex] && this.state.color[this.state.textIndex]._id) {
                Datass._id = this.state.color[this.state.textIndex]._id
            }
            var arr = [];
            this.state.color.splice(this.state.textIndex, 1, Datass)
            var sortpositionColor = this.state.color
            // if (values.positions <= (this.state.color).length) {

            //     this.state.color.map((dat, index) => {
            //         if (dat.positions >= values.positions && index !== this.state.textIndex) {
            //             var obj = {
            //                 "color": this.state.color[index].color,
            //                 "text": this.state.color[index].text,
            //                 "positions": parseInt(this.state.color[index].positions) + 1

            //             }


            //         }
            //         else {
            //             var obj = {
            //                 "color": this.state.color[index].color,
            //                 "text": this.state.color[index].text,
            //                 "positions": this.state.color[index].positions

            //             }
            //             if (this.state.color[index].color && this.state.color[index].color._id) {
            //                 obj._id = this.state.color[index].color._id
            //             }
            //         }
            //         arr.push(obj)

            //     })
            // }

            // function compare(a, b) {

            //     const bandA = b.positions
            //     const bandB = a.positions

            //     let comparison = 0;
            //     if (bandB > bandA) {
            //         comparison = 1;
            //     } else if (bandB < bandA) {
            //         comparison = -1;
            //     }
            //     return comparison;
            // }

            // arr.sort(compare);
            this.setState({ color: sortpositionColor, openTextBox: false })
            this.props.form.resetFields('text');

        })
    }

    handleColorClick = (e, val, index) => {

        e.preventDefault();
        let { checkboxValue } = this.state;
        if (e.target.checked === false) {
            this.setState({ colorValue: null, colorIndex: null })
            return;
        }
        let tempval = { ...checkboxValue }
        const newColor = tempval.color !== val ? val : "";
        tempval.color = newColor;

        this.setState({ colorValue: newColor, checkboxValue: tempval, colorIndex: index })
    }

    handleClick = (e) => {
        e.preventDefault();
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };

    handleClose = (e) => {
        e.preventDefault();
        this.setState({ displayColorPicker: false, ChangeColorPicker: false })
    };


    onChangeKitStatus = (checked, event) => {
        let { idkits } = this.state;

        // if (checked == false) {
        //     for (let i = 0; i <= idkits; i++)
        //         this.removekitsFromDb(i)
        // }
        this.setState({ newKitStatus: checked })
    }

    handleSubmit = (e, type) => {

        e.preventDefault();
        let { dispatch } = this.props;
        let { fileList, newKitStatus, schematicfileList, edit, id, difficultyValue, image, color, } = this.state;

        this.setState({ defaulState: true })
        this.props.form.validateFields((err, values) => {
            values.kit_status = newKitStatus


            if (edit === false) {
                if (err && values.patternName == null) {
                    if (type && type === "save")
                        this.props.history.push(adminRoutes.PATTERNLIST);

                }
                if (!err && values.patternName != null) {
                    if (values.beta === undefined) {
                        values.beta = false
                    }
                    if (values.isShipped === undefined) {
                        values.isShipped = false
                    }

                    if (type && type === "save") {
                        dispatch(addPattern({ ...values, fileList, schematicfileList, color, difficultyValue: difficultyValue })).then(res => {
                            this.props.history.push(adminRoutes.PATTERNLIST);
                            window.location.reload();

                        })
                    }
                    else {
                        dispatch(addPattern({ ...values, fileList, schematicfileList, color, difficultyValue: difficultyValue })).then(res => {
                            this.props.history.push(adminRoutes.ADDSIZE + '/' + res.data.data._id);
                            window.location.reload();

                        })
                    }


                }

            }

            else if (!err && values.patternName != null) {
                if (values.beta === undefined) {
                    values.beta = this.state.betaChecked
                }
                if (values.isShipped === undefined) {
                    values.isShipped = this.state.shippingChecked
                }
                if (type && type === "editNext") {
                    dispatch(addPattern({ ...values, color, fileList, schematicfileList, id, difficultyValue }, edit)).then(res => {
                        if (res && res.status === 200) {
                            this.props.history.push(adminRoutes.SIZELIST + "/" + res.data.data._id);
                        }

                    }).catch(err => {
                        message.destroy();
                        message.error(err.data.message)
                    })
                }
                else {
                    dispatch(addPattern({ ...values, color, fileList, schematicfileList, id, difficultyValue, DragImage: image }, edit)).then(res => {
                        if (res && res.status === 200) {
                            message.success('Pattern updated successfully');
                            this.props.history.push(adminRoutes.PATTERNLIST);
                        }

                    }).catch(err => {
                        message.destroy();
                        message.error(err.data.message)
                    })
                }

            }



        });
    };

    handleOnChange = (value) => {
        this.setState({
            volume: value
        })
    }

    handlekeyPress = (e) => {
        if (e && e !== "" && !isNaN(e)) {
            this.setState({
                inputValue: Number(e).toFixed(2),
            });
        } else {

            this.setState({
                inputValue: Number(0).toFixed(2),
            });

        }


    }
    normFile = e => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };



    handleChange = ({ file, fileList }) => {
        let temp = []
        fileList.map(data => {
            const isJpgOrPng = data.type === 'image/jpeg' || data.type === 'image/png' || data.type === 'image/jpg';
            if (!isJpgOrPng) {
                message.error('You can only upload JPG/PNG/JPEG file!');
                return message.error('Sorry you can  upload JPG/PNG/JPEG file !');
            }
            const sizeOfFile = data.size / 1024 / 1024

            const isLt2M = sizeOfFile < 5;
            if (isLt2M === false) {
                return message.error('Sorry you cannot upload a photo larger then 5 MB!');

            }

            else {
                return (
                    temp.push(data)
                )
            }

        })
        this.setState({ fileList: temp });
    }

    handleChanges = ({ file, fileList }) => {
        let temp = []
        fileList.map(data => {
            // const isJpgOrPng = data.type === 'image/jpeg' || data.type === 'image/png' || data.type === 'image/jpg';
            // if (!isJpgOrPng) {
            //     message.error('You can only upload JPG/PNG/JPEG file!');
            //     return message.error('Sorry you can  upload JPG/PNG/JPEG file !');
            // }
            const sizeOfFile = data.size / 1024 / 1024

            const isLt2M = sizeOfFile < 5;
            if (isLt2M === false) {
                return message.error('Sorry you cannot upload a photo larger then 5 MB!');

            }
            else {
                return (
                    temp.push(data)
                )
            }

        })
        this.setState({ schematicfileList: temp });
    }

    componentDidUpdate(prevProps) {
        let { dispatch, location } = this.props;
        let data = {
            start: 0,
            length: 1000000,

        }


        let path = location.pathname.split('/')

        let id = path[2];

        if (location && prevProps.location !== location) {

            if (id) {
                this.setState({ edit: true, id: id })

                dispatch(viewPattern(id)).then(res => {
                    if (res && res.status === 200) {

                        this.setState({ newKitStatus: res.data.data.kit_status, shippingChecked: res.data.data.isShipped, betaChecked: res.data.data.beta, difficultyValue: res.data.data.difficultyType, inputValue: res.data.data.amount, color: res.data.data.colors, image: res.data.data.image, edit: true })
                        this.setState({ measurements: res && res.data && res.data.data && res.data.data.measurement, idLabel: res && res.data && res.data.data && res.data.data.measurement.length })
                        this.setState({ oldKits: res && res.data && res.data.data && res.data.data.kits.length, kits: res && res.data && res.data.data && res.data.data.kits, idkits: res && res.data && res.data.data && res.data.data.kits.length })

                    }
                })

            }
            else {

                this.add();
                // this.addkit();

            }
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        let { dispatch, location, error, history } = this.props;
        let data = {
            start: 0,
            length: 1000000,

        }
        dispatch(patternList({ ...data })).then(response => {

            if (response && response.data && response.data && response.data.recordsTotal) {
                this.setState({ totalRecords: response.data.recordsTotal })
            }
        })
        let path = location.pathname.split('/')
        let userData = JSON.parse(localStorage.getItem('adminData'));
        this.setState({ userData: userData })

        let id = path[2];
        if (id) {
            this.setState({ edit: true, id: id })

            dispatch(viewPattern(id)).then(res => {
                if (res && res.status === 200) {
                    this.setState({ newKitStatus: res.data.data.kit_status, shippingChecked: res.data.data.isShipped, betaChecked: res && res.data && res.data.data && res.data.data.beta, difficultyValue: res && res.data && res.data.data && res.data.data.difficultyType, image: res && res.data && res.data.data && res.data.data.image, color: res && res.data && res.data.data && res.data.data.colors, inputValue: res && res.data && res.data.data && res.data.data.amount })
                    this.setState({ measurements: res && res.data && res.data.data && res.data.data.measurement, idLabel: res && res.data && res.data.data && res.data.data.measurement.length })
                    this.setState({ oldKits: res && res.data && res.data.data && res.data.data.kits.length, kits: res && res.data && res.data.data && res.data.data.kits, idkits: res && res.data && res.data.data && res.data.data.kits.length })
                    this.setState({ i: 0 })
                    for (const a of this.state.kits) {
                        a.index = this.state.i;
                        a.val = a.isShipped
                        this.state.arrOfkit.push(a)
                        this.setState({ i: this.state.i + 1 })
                    }

                }
            })


        }
        else {
            this.add()
            // this.addkit()
        }


        if (error && error.status === 400) {
            message.error(error.data.message)
            history.push(adminRoutes.AddPattern)
        }


    }

    addanotherMeasurementOnEnter = (e) => {
        // e.preventDefault();
        if (e.which === 13) {
            this.add();
            // this.addkit();
        }
    }

    // removeCommas(nStr) {
    //     if (nStr == null || nStr == "")
    //         return "";
    //     return nStr.toString().replace(/,/g, "");
    // }
    removeCommas = (nStr) => {
        if (nStr == null || nStr == "")
            return "";
        return nStr.toString().replace(/,/g, "");
    }

    numbersOnly = (e) => {
        e.preventDefault()
        let { tempPrice } = this.state;
        let val = e.target.value;
        let { setFieldsValue } = this.props.form;
        const regex = /^[0-9]*(\.[0-9]{0,2})?$/
        if (regex.test(val) === true) {
            this.setState({ tempPrice: val })
            setTimeout(() => {
                setFieldsValue({ ["amount"]: val })
            }, 20);
        } else {
            setTimeout(() => {
                setFieldsValue({ ["amount"]: tempPrice })
            }, 20);
        }


    }

    kitnumbersOnly = (e) => {
        e.preventDefault()
        let { tempKitPrice } = this.state;
        let val = e.target.value;
        let { setFieldsValue } = this.props.form;
        const regex = /^[0-9]*(\.[0-9]{0,2})?$/
        if (regex.test(val) === true) {
            this.setState({ tempKitPrice: val })
            setTimeout(() => {
                setFieldsValue({ ["kit_price"]: val })
            }, 20);
        } else {
            setTimeout(() => {
                setFieldsValue({ ["kit_price"]: tempKitPrice })
            }, 20);
        }


    }



    onBeta(e) {
        this.setState({ betaChecked: e.target.checked })

    }
    onShipping(e, k) {
        console.log('k => ', k);

        var items = this.state.arrOfkit;
        console.log('mansi items => ', items);

        this.setState({ shippingChecked: e.target.checked })
        var item = { index: k, val: e.target.checked }
        console.log('mansi item => ', item);

        var foundIndex = items.findIndex(x => x.index == item.index);
        // items[foundIndex] = item
        items.splice(foundIndex, 1, item);
        console.log('mansi itemsafter => ', items);

        this.setState({ arrOfkit: items })
    }

    onShippings(e, i) {
        console.log('mansi 1234567 => ', 1234567);

        let { kits } = this.state;

        kits[i].isShipped = e.target.checked;
        this.setState({ kits: kits })

    }

    handleValidationforAlphabtes = (a, b, c) => {
        if (b.match(/^[a-zA-Z ]*$/)) {
            c();
        } else {
            c("Please enter only alphabets");
        }
    }


    handleDifficulty = (e, value) => {
        e.preventDefault();
        this.setState({ difficultyValue: value })
    }
    onSortEnd = ({ oldIndex, newIndex }) => {
        this.setState(({ image }) => ({
            image: arrayMove(image, oldIndex, newIndex),
        }));

    };
    onSortEndColor = ({ oldIndex, newIndex }) => {
        this.setState(({ color }) => ({
            color: arrayMove(color, oldIndex, newIndex),
        }));

    };


    setTwoNumberDecimal(event) {
        this.value = parseFloat(this.value).toFixed(2);
    }

    limitDecimalPlaces(e, count) {
        if (e.target.value.indexOf('.') === -1) { return; }
        if ((e.target.value.length - e.target.value.indexOf('.')) > count) {
            e.target.value = parseFloat(e.target.value).toFixed(count);
        }
    }

    handleValidationforEmail = (a, b, c) => {
        // let regex = "/^\w+[\w-\.]*\@\w+((-\w+)|(\w*))\.[a-z]{2,3}$/"
        let regex = "/^w+[w-.]*@w+((-w+)|(w*)).[a-z]{2,3}$/"
        if (b.match(regex)) {
            c();
        } else {
            c("Please enter valid email");
        }
    }



    optionValues(number) {
        let arr = [];
        let { edit } = this.state;
        if (number && number > 0) {
            if (edit === true) {

                for (let i = 1; i <= number; i++) {
                    arr.push(<Option key={i} value={i}>{i}</Option>)
                }
            }
            else {
                for (let i = 1; i <= number + 1; i++) {
                    arr.push(<Option value={i}>{i}</Option>)
                }
            }
            return arr;
        }
    }


    render() {
        const { getFieldDecorator, getFieldsError, getFieldValue, getFieldsValue } = this.props.form;
        let { fileList, arrOfkit, color, kits, edit, measurements, difficultyValue, defaulState, volume, userData, totalRecords, newKitStatus } = this.state;
        console.log('mansi arrOfkit => ', arrOfkit);


        getFieldDecorator('keys', {
            initialValue: [
                // "yarn_name":getFieldsValue.yarn[0]
            ]
        });
        getFieldDecorator('kitkeys', {
            initialValue: [
                // "yarn_name":getFieldsValue.yarn[0]
            ]
        });
        const keys = getFieldValue('keys');
        const kitkeys = getFieldValue('kitkeys');
        let formValues = getFieldsValue()
        const formItems = keys.map((k, index) => (

            <li className="double-input d-flex" key={k}>
                {keys.length > 1 ? (
                    <Icon
                        className="dynamic-delete-button"
                        type="minus-circle-o"
                        onClick={() => this.remove(k)}
                    />

                ) : null}
                <Form.Item>

                    {getFieldDecorator(`measurement[${k}].label_name`, {

                    })(
                        <Input
                            // prefix={<Icon />}
                            type="text"
                            placeholder="Label name"
                        />,
                    )}
                </Form.Item>
                <Form.Item required={false}
                >
                    {getFieldDecorator(`measurement[${k}].label_measurement`, {

                    })(
                        <Input
                            // prefix={<Icon />}
                            type="text"
                            placeholder='link'
                            onKeyDown={(e) => this.addanotherMeasurementOnEnter(e)}
                        />,
                    )}

                </Form.Item>

            </li>
        ))

        const kitformItems = kitkeys.map((k, index) => (
            <li className="double-input d-flex" key={k}>
                {kitkeys.length >= 1 ? (
                    <Icon
                        className="dynamic-delete-button"
                        type="minus-circle-o"
                        onClick={() => this.removekit(k)}
                    />

                ) : null}


                <li className>
                    <Form.Item >
                        <label>Button Label</label>
                        {getFieldDecorator(`kits[${k}].kit_label`, {

                        })(
                            <Input
                                // prefix={<Icon type='' />}
                                type="text"
                                placeholder="Kit Label"
                            />,
                        )}
                    </Form.Item>
                </li>
                <li className="">
                    <Form.Item >
                        <label>Price</label>
                        {getFieldDecorator(`kits[${k}].kit_price`, {
                        })(
                            <input placeholder="Kit Price" onChange={this.kitnumbersOnly} />
                        )}
                    </Form.Item>
                </li>
                <li className="multicopy-li full-width-row">
                    <Form.Item >
                        <label>Description</label>
                        {getFieldDecorator(`kits[${k}].kit_description`, {
                        })
                            (
                                <TextArea
                                    className="text-area-input"
                                    // prefix={<Icon type='' />}
                                    type="text"
                                    placeholder="Kit Description"
                                ></TextArea>,
                            )}
                    </Form.Item>
                </li>
                <li className="BetaLi" >
                    <Form.Item>

                        <label>Shipping</label>
                        {getFieldDecorator(`kits[${k}].isShipped`)(

                            <Checkbox onChange={(e) => this.onShipping(e, k)}></Checkbox>
                        )}
                    </Form.Item>
                </li>
            </li>
        ))


        let { editData, prevAddData } = this.props;
        const uploadButton = (
            <div>
                <Icon type="plus" />
                <div className="ant-upload-text">Upload</div>
            </div>
        );
        const popover = {
            position: 'absolute',
            zIndex: '2',
        }
        const cover = {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        }
        return (
            <div className="content-box">
                {this.props.form.isFieldsTouched() === true && defaulState === false && <Beforeunload
                    onBeforeunload={() => "You have unsaved changes, are you sure you want to leave?"} />}

                <Prompt

                    when={this.props.form.isFieldsTouched() === true && defaulState === false}
                    message='You have unsaved changes, are you sure you want to leave?'
                />
                <Prompt

                    when={this.props.form.isFieldsTouched() === true && defaulState === false}
                    message='You have unsaved changes, are you sure you want to leave?'
                />
                <Layout>
                    <div className="header-top">
                    </div>
                    <div className="bredcrumb-div">
                        <Breadcrumb separator="-">
                            <Breadcrumb.Item><Link to={adminRoutes.PATTERNLIST}>{edit === true ? editData && editData.data && editData.data.pattern && editData.data.pattern.patternName : "Pattern"}</Link></Breadcrumb.Item>
                            <Breadcrumb.Item>{edit === true ? "Edit Pattern" : "Add Pattern"}</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className="page-title d-flex">
                        <h2>{edit === true ? "Edit Pattern" : "Add Pattern"}</h2>
                    </div>
                    <div className="white-box">
                        <Modal visible={this.state.openTextBox} onCancel={() => this.setState({ openTextBox: false })}>

                            <Form.Item >
                                <label>Text :</label>
                                {getFieldDecorator('text', {
                                    initialValue: this.state.color && this.state.color[this.state.textIndex] && this.state.color[this.state.textIndex].text ? this.state.color[this.state.textIndex].text : ""

                                })(
                                    <Input
                                        type="text"
                                        placeholder="Text"
                                    />,
                                )}
                            </Form.Item>
                            {/* <Form.Item >
                                <label>Position :</label>
                                {getFieldDecorator('positions', {
                                    initialValue: this.state.color && this.state.color[this.state.textIndex] && this.state.color[this.state.textIndex].positions ? this.state.color[this.state.textIndex].positions : ""

                                })(
                                    <Input
                                        type="number"
                                        placeholder="Text"
                                    />,
                                )}
                            </Form.Item> */}
                            <Form.Item>

                                <div className="popup-btn">
                                    {/* <button onClick={(e) => this.handleNoteDelete(e)} className="ant-btn WhiteButton"><span>Delete</span></button> */}
                                    <button onClick={this.handleAddTextColor} className="ant-btn OrangeButton"><span>Ok</span></button>
                                </div>
                            </Form.Item>
                        </Modal>
                        <Form layout="horizontal" onSubmit={this.handleSubmit} style={{ width: "50 %" }}>
                            <ul className="filled-ul">
                                <li className="half-width">
                                    <Form.Item >
                                        <label>Pattern Name</label>

                                        {getFieldDecorator('patternName', {
                                            rules: [{ required: true, message: 'Please input your pattern name!' }],
                                            initialValue: edit === true ? editData && editData.data && editData.data.data && editData.data.data.patternName :
                                                prevAddData && prevAddData.data && prevAddData.data.data.patternName ?
                                                    prevAddData && prevAddData.data && prevAddData.data.data.patternName : ""
                                        })(
                                            <Input
                                                // prefix={<Icon type='' />}
                                                id="patternInput"
                                                type="text"
                                                placeholder="Pattern"
                                            />
                                        )}
                                    </Form.Item>
                                </li>
                                <li className="half-width">
                                    <Form.Item >
                                        <label>Author Name</label>
                                        {getFieldDecorator('authorName', {
                                            initialValue: edit === true ? editData && editData.data && editData.data.data && editData.data.data.authorName :
                                                prevAddData && prevAddData.data && prevAddData.data.data.authorName ?
                                                    prevAddData && prevAddData.data && prevAddData.data.data.authorName : ""

                                        })(
                                            <Input
                                                // prefix={<Icon type='' />}
                                                type="text"
                                                placeholder="Author"
                                            />,
                                        )}
                                    </Form.Item>
                                </li>
                                <li className="half-width">
                                    <Form.Item >
                                        <label>Author Email</label>
                                        {getFieldDecorator('authorEmail', {
                                            rules: [{
                                                type: 'email',
                                                message: 'The input is not valid E-mail!',
                                            },
                                            ],
                                            initialValue: userData && userData.data && userData.data.type === 'standard' ? userData && userData.data && userData.data.email : edit === true ? editData && editData.data && editData.data.data && editData.data.data.authorEmail :
                                                prevAddData && prevAddData.data && prevAddData.data.data.authorEmail ?
                                                    prevAddData && prevAddData.data && prevAddData.data.data.authorEmail : ""
                                        })(
                                            <Input
                                                // prefix={<Icon type='' />}
                                                type="text"
                                                placeholder="Email"
                                            />,
                                        )}
                                    </Form.Item>
                                </li>
                                <li>
                                    <Form.Item >
                                        <label>Description</label>
                                        {getFieldDecorator('description', {
                                            initialValue: editData && editData.data && editData.data.data && editData.data.data.description ? editData && editData.data && editData.data.data && editData.data.data.description :
                                                prevAddData && prevAddData.data && prevAddData.data.data.description ?
                                                    prevAddData && prevAddData.data && prevAddData.data.data.description : ""
                                        })
                                            (
                                                <TextArea
                                                    className="text-area-input"
                                                    // prefix={<Icon type='' />}
                                                    type="text"
                                                    placeholder="Here is where you write about your design inspiration, interesting aspects, special or unusual techniques, skills required, and expected learnings. The goal is to excite knitters about the pattern while also setting their expectations appropriately..."
                                                ></TextArea>,
                                            )}
                                    </Form.Item>
                                </li>
                                <li className="picture-card">
                                    <Form.Item extra="">
                                        <label>Add Photos</label>
                                        {getFieldDecorator('image'
                                        )(
                                            <Upload
                                                listType="picture-card"
                                                fileList={fileList}
                                                beforeUpload={() => false}
                                                //beforeUpload={this.beforeUpload}

                                                onPreview={this.handlePreview}
                                                onChange={this.handleChange}
                                                defaultFileList={editData && editData.data && editData.data.data && editData.data.data.image && editData.data.data.image.length > 0 ? `${SERVER_URL}uploads/${editData && editData.data && editData.data.data && editData.data.data.image[0]}` :
                                                    prevAddData && prevAddData.data && prevAddData.data.data && prevAddData.data.data.image[0] ?
                                                        `${SERVER_URL} uploads /${prevAddData.data.data.image[0]}` : []
                                                }
                                                multiple={true}
                                            >
                                                {uploadButton}
                                            </Upload>

                                        )}

                                        <div className="upload-images d-flex">

                                            {/* <SortableList axis="x" items={this.state.image} onSortEnd={this.onSortEnd} /> */}
                                            <SortableContainer onSortEnd={this.onSortEnd}>
                                                {this.state.image && this.state.image.length > 0 && this.state.image.map((value, index) => (
                                                    value != null &&
                                                    <SortableItem axis={"x"} key={`item-${value}`} index={index} value={(<><img src={`${SERVER_URL}uploads/${value}`} alt="" />
                                                        <Button className="imag-close" onClick={(e) => this.handleDelete(e, editData && editData.data && editData.data.data, value)} >
                                                            <Icon type="close" />
                                                        </Button></>)} />
                                                ))}
                                            </SortableContainer>

                                        </div>

                                    </Form.Item>
                                </li>

                                <li className="picture-card">
                                    <Form.Item extra="">
                                        <label>Add Schematic</label>
                                        {getFieldDecorator('schematic'
                                        )(
                                            <Upload
                                                listType="picture-card"
                                                fileList={this.state.schematicfileList}
                                                beforeUpload={() => false}
                                                //beforeUpload={this.beforeUpload}

                                                onPreview={this.handlePreview}
                                                onChange={this.handleChanges}
                                                defaultFileList={editData && editData.data && editData.data.data && editData.data.data.schematic && editData.data.data.schematic.length > 0 ? `${SERVER_URL}uploads/${editData.data.data.schematic[0]}` :
                                                    prevAddData && prevAddData.data && prevAddData.data.data && prevAddData.data.data.schematic[0] ?
                                                        `${SERVER_URL} uploads /${prevAddData.data.data.schematic[0]}` : []
                                                }
                                                multiple={true}
                                            >
                                                {uploadButton}
                                            </Upload>

                                        )}
                                        <div className="upload-images d-flex">
                                            {
                                                editData && editData.data && editData.data.data && editData.data.data.schematic && editData.data.data.schematic.map((dat, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <img src={`${SERVER_URL}uploads/${dat}`} alt="" />
                                                            <Button className="imag-close" onClick={(e) => this.handleDeleteSchematic(e, editData.data.data, dat)}>
                                                                <Icon type="close" />
                                                            </Button>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </Form.Item>
                                </li>
                                {newKitStatus && newKitStatus !== true && <li className="half-width price-input">
                                    <Form.Item >
                                        <label>Price</label>
                                        {getFieldDecorator('amount', {
                                            initialValue: editData && editData.data && editData.data.data && editData.data.data.amount ? editData && editData.data && editData.data.data && parseFloat(editData.data.data.amount).toFixed(2) :
                                                prevAddData && prevAddData.data && prevAddData.data.data.amount ?
                                                    prevAddData && prevAddData.data && parseFloat(prevAddData.data.data.amount).toFixed(2) : edit === true ? "0.00" : ""
                                        })(
                                            // <InputNumber
                                            //     precision={2}
                                            //     step={0.01}
                                            //     min={0.00}
                                            //     formatter={value => Number(this.state.inputValue).toFixed(2)}
                                            //     onChange={this.handlekeyPress}
                                            //     type="number"
                                            // />
                                            // <input type='text' step='0.01' min='0.01' onChange={(e) => this.setTwoNumberDecimal(e, 2)} oninput={(e) => this.limitDecimalPlaces(e, 2)} />
                                            <input placeholder="Price" onChange={this.numbersOnly} />
                                        )}
                                    </Form.Item>
                                </li>
                                }
                                <li className="BetaLi" >
                                    <Form.Item>

                                        <label>Beta</label>
                                        {getFieldDecorator('beta')(
                                            <Checkbox onChange={(e) => this.onBeta(e)} checked={this.state.betaChecked} ></Checkbox>
                                        )}
                                    </Form.Item>
                                </li>
                                <li className="ColorDiv">
                                    <Form.Item>
                                        <label>
                                            <strong>Color</strong>
                                            <button onClick={this.handleClick}>
                                                <Icon type="plus" />
                                                {this.state.displayColorPicker ? <div className="popover">
                                                    <div className="cover" onClick={this.handleClose} />
                                                    <div style={popover} />
                                                    <div style={cover} onClick={this.handleClose} />
                                                    <SwatchesPicker onChange={this.handleColorChangeComplete} />
                                                </div> : null}
                                            </button>
                                        </label>
                                        {getFieldDecorator('color')}
                                        <div className="d-flex color-wrap">
                                            <SortableContainer onSortEnd={this.onSortEndColor}>

                                                {
                                                    this.state.color && this.state.color.length > 0 && this.state.color.map((value, i) => (
                                                        <div className="color-div" key={`color-${i}`}>
                                                            <SortableItem axis={"x"} key={`item-${value}`} index={i} value=
                                                                {(<><div><Checkbox onClick={() => this.setState({ ChangeColorPicker: !this.state.ChangeColorPicker, colorIndexValue: i })}   >
                                                                    <span className="colorSection" style={{ backgroundColor: value.color }}></span>

                                                                </Checkbox></div>
                                                                </>)} />

                                                            {this.state.ChangeColorPicker && i === this.state.colorIndexValue ?
                                                                <div className="popover">
                                                                    <div className="cover" onClick={this.handleClose} />
                                                                    <SwatchesPicker onChange={(e) => this.handleColorChangeComplete(e, i)} />
                                                                </div> : null}
                                                            <Icon type="close" onClick={(e) => this.handleDeleteColor(e, editData && editData.data && editData.data.data._id, value, i)} />
                                                            <Icon className="Type" onClick={(e) => this.handleColorText(e, editData && editData.data && editData.data.data._id, value, i)} />

                                                        </div>
                                                    ))
                                                }
                                            </SortableContainer>
                                        </div>
                                    </Form.Item>
                                </li>
                                {userData && userData.data && userData.data.type === 'admin' && <li className="half-width CustomeSelect">
                                    <Form.Item>
                                        <label>Position</label>
                                        {getFieldDecorator('position', {
                                            rules: [{ required: true, message: 'Please input position!' }],

                                            initialValue: edit === true ? editData && editData.data && editData.data.data &&
                                                editData.data.data.position && editData.data.data.position :
                                                prevAddData && prevAddData.data && prevAddData.data.data.position ?
                                                    prevAddData && prevAddData.data && prevAddData.data.data.position : totalRecords + 1
                                        })(
                                            <Select>
                                                {totalRecords && totalRecords > 0 ?

                                                    this.optionValues(totalRecords) :
                                                    <Option key={1}>1</Option>
                                                }
                                            </Select>
                                        )}
                                    </Form.Item>
                                </li>
                                }
                                {userData && userData.data && userData.data.type !== 'standard' && <li className="half-width is-active">
                                    <Form.Item label="Status">
                                        <label>Publish</label>
                                        {getFieldDecorator('status', {
                                            valuePropName: 'checked',
                                            initialValue: editData && editData.data && editData.data.data && editData.data.data.status ? editData && editData.data && editData.data.data.status :
                                                prevAddData && prevAddData.data && prevAddData.data.data.status ?
                                                    prevAddData && prevAddData.data && prevAddData.data.data.status : false

                                        })(<Switch />)}
                                    </Form.Item>
                                </li>
                                }
                                <li className="half-width difficulty-level">
                                    <Form.Item>
                                        <label>Level </label>
                                        <div className="difficulty-level-box">
                                            <span>{difficultyValue}</span>
                                            <ul>
                                                <li className={difficultyValue === "You are a boss" || difficultyValue === "You got this" || difficultyValue === "Totally doable" || difficultyValue === "In it to win it" ? "filld-button" : ""}><button onClick={(e) => this.handleDifficulty(e, 'Totally doable')}></button></li>
                                                <li className={difficultyValue === "You are a boss" || difficultyValue === "You got this" || difficultyValue === "In it to win it" ? "filld-button" : ""}><button onClick={(e) => this.handleDifficulty(e, 'You got this')}></button></li>
                                                <li className={difficultyValue === "You are a boss" || difficultyValue === "In it to win it" ? "filld-button" : ""}><button onClick={(e) => this.handleDifficulty(e, 'In it to win it')}></button></li>
                                                <li className={difficultyValue === "You are a boss" ? "filld-button" : ""}><button onClick={(e) => this.handleDifficulty(e, 'You are a boss')}></button></li>
                                            </ul>
                                        </div>
                                    </Form.Item>
                                </li>


                                <li>
                                    <label>Link</label>

                                    <div className="another-label another-label-cstm">
                                        <ul className="d-flex inner-ul multicopy-li">
                                            {edit === true ? measurements && measurements.length > 0 && measurements.map((value, index) => (
                                                <li className="double-input d-flex" key={index}>
                                                    <Form.Item >
                                                        {getFieldDecorator(`measurement[${index}].label_name`, {

                                                            initialValue: value && value.label_name
                                                        })(
                                                            <Input
                                                                // prefix={<Icon />}
                                                                type="text"
                                                                placeholder="Label name"
                                                            />,
                                                        )}
                                                    </Form.Item>

                                                    <Form.Item >
                                                        {getFieldDecorator(`measurement[${index}].label_measurement`, {

                                                            initialValue: value && value.label_measurement
                                                        })(
                                                            <Input
                                                                // prefix={<Icon />}
                                                                type="text"
                                                                placeholder="link"
                                                            />,
                                                        )}
                                                    </Form.Item>
                                                    <i key={index}>
                                                        <Icon
                                                            className="dynamic-delete-button"
                                                            type="minus-circle-o"
                                                            onClick={() => this.removeFromDb(index)}
                                                        />
                                                    </i>
                                                </li>
                                            )) :
                                                prevAddData && prevAddData.data && prevAddData.data.data.measurement ?
                                                    prevAddData && prevAddData.data && prevAddData.data.data.measurement.map((value, index) => (
                                                        <li className="double-input d-flex" key={index}>
                                                            <Form.Item >
                                                                {getFieldDecorator(`measurement[${index}].label_name`, {

                                                                    initialValue: value && value.label_name
                                                                })(
                                                                    <Input
                                                                        // prefix={<Icon />}
                                                                        type="text"
                                                                        placeholder="Label name, i.e. Bust"
                                                                    />,
                                                                )}
                                                            </Form.Item>
                                                            <Form.Item >
                                                                {getFieldDecorator(`measurement[${index}].label_measurement`, {

                                                                    initialValue: value && value.label_measurement
                                                                })(
                                                                    <Input
                                                                        // prefix={<Icon />}
                                                                        type="text"
                                                                        placeholder="link"
                                                                    />,
                                                                )}
                                                            </Form.Item>
                                                        </li>
                                                    )) : ""
                                            }

                                            {formItems}

                                            <li>
                                                <div className="add-another">
                                                    <Form.Item>
                                                        <Button type="dashed" onClick={this.add}>
                                                            <Icon type="plus" />
                                                            <span>Add another label</span>
                                                        </Button>
                                                    </Form.Item>
                                                    {/* {keys && keys.length >= 2 && < Form.Item >
                                                        <Button type="dashed" onClick={this.remove}>
                                                            <Icon type="minus" />
                                                            <span>Remove label</span>
                                                        </Button>
                                                    </Form.Item>} */}
                                                </div>
                                            </li>


                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <Form.Item  >
                                        <label>Add option</label>
                                        {getFieldDecorator('kit_status', {
                                            valuePropName: 'checked',
                                            initialValue: editData && editData.data && editData.data.data && editData.data.data.kit_status ? editData && editData.data && editData.data.data.kit_status :
                                                prevAddData && prevAddData.data && prevAddData.data.data.kit_status ?
                                                    prevAddData && prevAddData.data && prevAddData.data.data.kit_status : false

                                        })(<Switch onChange={this.onChangeKitStatus} />)}
                                    </Form.Item>


                                </li>
                                {newKitStatus !== true && <li className="half-width price-input">
                                    <Form.Item >
                                        <label>Price</label>
                                        {getFieldDecorator('amount', {
                                            initialValue: editData && editData.data && editData.data.data && editData.data.data.amount ? editData && editData.data && editData.data.data && parseFloat(editData.data.data.amount).toFixed(2) :
                                                prevAddData && prevAddData.data && prevAddData.data.data.amount ?
                                                    prevAddData && prevAddData.data && parseFloat(prevAddData.data.data.amount).toFixed(2) : edit === true ? "0.00" : ""
                                        })(
                                            // <InputNumber
                                            //     precision={2}
                                            //     step={0.01}
                                            //     min={0.00}
                                            //     formatter={value => Number(this.state.inputValue).toFixed(2)}
                                            //     onChange={this.handlekeyPress}
                                            //     type="number"
                                            // />
                                            // <input type='text' step='0.01' min='0.01' onChange={(e) => this.setTwoNumberDecimal(e, 2)} oninput={(e) => this.limitDecimalPlaces(e, 2)} />
                                            <input placeholder="Price" onChange={this.numbersOnly} />
                                        )}
                                    </Form.Item>
                                </li>
                                }
                                <li>
                                    <label> </label>

                                    <div className="another-label another-label-cstm">
                                        <ul >


                                            {newKitStatus === true && kits && kits.length > 0 && kits.map((value, index) => (
                                                <li className="double-input d-flex" key={index}>


                                                    <Form.Item >
                                                        <label>Button Label</label>
                                                        {getFieldDecorator(`kits[${index}].kit_label`, {

                                                            initialValue: value && value.kit_label
                                                        })(
                                                            <Input
                                                                // prefix={<Icon type='' />}
                                                                type="text"
                                                                placeholder="Kit Label"
                                                            />,
                                                        )}
                                                    </Form.Item>
                                                    <li className="">
                                                        <Form.Item >
                                                            <label>Price</label>
                                                            {getFieldDecorator(`kits[${index}].kit_price`, {
                                                                initialValue: value && value.kit_price

                                                            })(
                                                                // <InputNumber
                                                                //     precision={2}
                                                                //     step={0.01}
                                                                //     min={0.00}
                                                                //     formatter={value => Number(this.state.inputValue).toFixed(2)}
                                                                //     onChange={this.handlekeyPress}
                                                                //     type="number"
                                                                // />
                                                                // <input type='text' step='0.01' min='0.01' onChange={(e) => this.setTwoNumberDecimal(e, 2)} oninput={(e) => this.limitDecimalPlaces(e, 2)} />
                                                                <input placeholder="Kit Price" onChange={this.kitnumbersOnly} />
                                                            )}
                                                        </Form.Item>
                                                    </li>
                                                    <li className="multicopy-li full-width-row">
                                                        <Form.Item >
                                                            <label>Description</label>
                                                            {getFieldDecorator(`kits[${index}].kit_description`, {
                                                                initialValue: value && value.kit_description

                                                            })
                                                                (
                                                                    <TextArea
                                                                        className="text-area-input"
                                                                        // prefix={<Icon type='' />}
                                                                        type="text"
                                                                        placeholder="Kit Description"
                                                                    ></TextArea>,
                                                                )}
                                                        </Form.Item>
                                                    </li>
                                                    <li className="BetaLi" >
                                                        <Form.Item>

                                                            <label>Shipping</label>
                                                            {getFieldDecorator(`kits[${index}].isShipped`,
                                                                {
                                                                    initialValue: value && value.isShipped

                                                                }
                                                            )(

                                                                <Checkbox onChange={(e) => this.onShippings(e, index)} checked={value && value.isShipped} ></Checkbox>
                                                            )}
                                                        </Form.Item>
                                                    </li>
                                                    <i key={index}>
                                                        <Icon
                                                            className="dynamic-delete-button"
                                                            type="minus-circle-o"
                                                            onClick={() => this.removekitsFromDb(index)}
                                                        />
                                                    </i>
                                                </li>
                                            ))
                                            }

                                            {newKitStatus === true && kitformItems}

                                            {newKitStatus == true && <li>
                                                <div className="add-another">
                                                    <Form.Item>
                                                        <Button type="dashed" onClick={this.addkit}>
                                                            <Icon type="plus" />
                                                            <span>Add another kit options</span>
                                                        </Button>
                                                    </Form.Item>
                                                    {/* {keys && keys.length >= 2 && < Form.Item >
                                                        <Button type="dashed" onClick={this.remove}>
                                                            <Icon type="minus" />
                                                            <span>Remove label</span>
                                                        </Button>
                                                    </Form.Item>} */}
                                                </div>
                                            </li>
                                            }


                                        </ul>
                                    </div>
                                </li>

                                {/* <li>
                                    <div className="LinkDiv">
                                        <Form.Item >
                                            <label>Link</label>
                                            {getFieldDecorator('link1', {
                                                initialValue: edit === true ? editData && editData.data && editData.data.data && editData.data.data.link1 :
                                                    prevAddData && prevAddData.data && prevAddData.data.data.link1 ?
                                                        prevAddData && prevAddData.data && prevAddData.data.data.link1 : ""

                                            })(
                                                <Input
                                                    type="text"
                                                    placeholder="link"
                                                />,
                                            )}
                                        </Form.Item>
                                    </div>
                                </li> */}
                                <li className="btm-btn chat-view-btm">
                                    <Form.Item>
                                        {edit === false &&
                                            <div className="d-flex justify-content-center">
                                                <div className="ChatExitButton">
                                                    <Button type="button" onClick={(e) => this.handleSubmit(e, "save")}  >
                                                        Save and Exit
                                                    </Button>
                                                </div>
                                                <div>
                                                    <Button type="primary" className="OrangeButton" onClick={(e) => this.handleSubmit(e, "next")}  >
                                                        Next </Button>
                                                </div>
                                            </div>
                                        }

                                        {edit === true &&
                                            <Button type="button" onClick={(e) => this.handleSubmit(e)}  >
                                                Update
                        </Button>
                                        }
                                        {edit === true &&
                                            <Button type="primary" onClick={(e) => this.handleSubmit(e, "editNext")}  >
                                                Next
                        </Button>
                                        }
                                    </Form.Item>
                                </li>
                            </ul>

                        </Form>
                    </div>
                </Layout>
            </div >
        )

    }
}

const mapStateToProps = ((state) => {
    let { loading, data, error } = state.addPattern
    return {
        loading,
        data,
        error,
        editLoading: state.viewPattern.loading,
        editData: state.viewPattern.data,
        prevAddData: state.addPattern.data,
        editError: state.viewPattern.error,

    }
})

const AddPattern = Form.create({ name: 'addPattern' })(AddPatternData);
export default connect(mapStateToProps)(AddPattern);
