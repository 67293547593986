import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Descriptions } from 'antd';
import Layout from './Layout';
import { Breadcrumb } from 'antd';
import { viewSize, resetViewSize } from '../redux/actions/viewSize';
import { Link } from 'react-router-dom';


class ViewSize extends Component {

    componentDidMount() {
        let { dispatch, location } = this.props;
        let path = location.pathname.split('/')
        let sizeId = path[2];
        dispatch(viewSize(sizeId)).then(res => {

        })

    }
    componentWillUnmount() {
        let { dispatch } = this.props;
        dispatch(resetViewSize())
    }

    render() {

        let { loading, data } = this.props;

        return (
            <Layout>
                <div className="content-box">
                    <div className="bredcrumb-div">
                        <Breadcrumb separator="-">

                            <Breadcrumb.Item><Link to="">{data && data.pattern && data.pattern.patternId.patternName}</Link></Breadcrumb.Item>
                            <Breadcrumb.Item>{data && data.pattern && data.pattern.sizeName}</Breadcrumb.Item>
                            <Breadcrumb.Item>View</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className="page-title d-flex">
                        <h2>Size Info</h2>
                    </div>
                    {loading && <h3>Loading............</h3>}
                    <div className="viewsize-content">
                        <div className="white-box" >
                            {!loading && data && data.data &&
                                <Descriptions bordered column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 2 }}>
                                    <Descriptions.Item label="Size Name">{data.data.sizeName} </Descriptions.Item>

                                </Descriptions>}
                        </div>
                        <div className="white-box">
                            {!loading && data && data.data &&
                                <Descriptions title="Measurement" bordered column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
                                    {data.data.measurement.map((value, index) => (
                                        <Descriptions.Item label={value.label_name} >{value.label_measurement} </Descriptions.Item>
                                    ))
                                    }
                                </Descriptions>}
                        </div>
                        <div className="white-box">


                            {!loading && data && data.data &&

                                data.data.yarn.map((value, index) => (
                                    <Descriptions bordered column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }} title="Yarn">
                                        <Descriptions.Item label="Yarn Name">{value.yarn_name} </Descriptions.Item>,
                                    <Descriptions.Item label="Yarn Weight">{value.yarn_weight} </Descriptions.Item> ,
                                        <Descriptions.Item label="Fiber Content">{value.fiber_content} </Descriptions.Item> ,
                                        <Descriptions.Item label="Length Density">{value.length_density} </Descriptions.Item> ,
                                        <Descriptions.Item label="Yardage Used">{value.yardage_used} </Descriptions.Item> ,
                                        <Descriptions.Item label="Colorway">{value.colorway} </Descriptions.Item>
                                    </Descriptions>
                                ))
                            }
                        </div>
                        <div className="white-box">
                            {!loading && data && data.data &&
                                <Descriptions bordered column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
                                    <Descriptions.Item label="Gauge">{data.data.gauge} </Descriptions.Item>

                                </Descriptions>}
                        </div>
                        <div className="white-box">
                            {!loading && data && data.data &&
                                <Descriptions bordered column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
                                    <Descriptions.Item label="Bits & Bobs">{data.data.bitsBobs} </Descriptions.Item>

                                </Descriptions>}
                        </div>
                    </div>
                </div>
            </Layout>

        )
    }
}


const mapStateToProps = ((state) => {
    let { loading, data, error } = state.viewSize
    return {
        loading,
        data,
        error
    }
})

export default connect(mapStateToProps)(ViewSize);
