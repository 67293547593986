import React, { Component } from 'react'
import Layout from './Layout';
import { Button, Breadcrumb, Popconfirm, Spin } from 'antd';
import { adminRoutes } from '../helpers/adminRoutes';
import { connect } from 'react-redux';
import { getStitch } from '../redux/actions/stitch';
import InfiniteScroll from 'react-infinite-scroller';
import { deleteStitch } from '../redux/actions/deleteStitch';

import { Link } from 'react-router-dom';
import { SERVER_URL } from '../api';
import { responsiveArray } from 'antd/lib/_util/responsiveObserve';
import { resetAddStitchData } from '../redux/actions/addStitch';

class ListStitch extends Component {

    constructor(props) {
        super(props)
        this.state = {
            stitchListData: [],
            loadingApi: false,
            imageStatus: "loading",
            pageNo: 0,
        }
    }



    componentWillUnmount() {
        this.setState({
            patternListData: []
        })
    }

    componentDidMount() {
        let { dispatch } = this.props;
        dispatch(resetAddStitchData())
        this.loadFunc(1)
        // window.location.reload();

    }


    loadFunc = (e, string, value) => {
        // if (string === 'delete' || string === 'duplicate') {
        //     this.setState({ loadingApi: true });
        // }
        // let { dispatch, location } = this.props;
        // let page = (e - 1) * 9
        // let data = {
        //     start: page,
        //     length: 9,
        //     search: value ? value : ''
        // }

        // let { stitchListData } = this.state
        // let datas = string === 'delete' || string === 'search' || string === 'duplicate' ? [] : stitchListData;

        // // let datas = stitchListData;
        // dispatch(getStitch(data)).then(res => {
        //     res.data.data.map(dat => (
        //         datas.push(dat)

        //     ))

        //     // function compare(a, b) {
        //     //     const bandA = a._id
        //     //     const bandB = b._id

        //     //     let comparison = 0;
        //     //     if (bandB > bandA) {
        //     //         comparison = 1;
        //     //     } else if (bandB < bandA) {
        //     //         comparison = -1;
        //     //     }
        //     //     return comparison;
        //     // }

        //     // datas.sort(compare);

        //     setTimeout(() => {
        //         this.setState({ stitchListData: datas, loadingApi: false })
        //     }, 200)


        // })



        if (string === 'delete' || string === 'duplicate') {
            this.setState({ loadingApi: true });
        }
        let page = (e - 1) * 9
        let data = {
            start: page,
            length: 9,
            search: value ? value : ''
        }
        let { dispatch } = this.props;
        let { stitchListData } = this.state
        let datas = string === 'delete' || string === 'search' || string === 'duplicate' ? [] : stitchListData;
        dispatch(getStitch(data)).then(res => {
            this.setState({ newPageNo: e, recordsTotals: res && res.data && res.data.recordsTotal })
            this.setState({ pageNo: page + 9 })
            res.data.data.map(dat => (
                datas.push(dat)

            ))

            function compare(a, b) {
                const bandA = a.createdAt
                const bandB = b.createdAt

                let comparison = 0;
                if (bandB > bandA) {
                    comparison = 1;
                } else if (bandB < bandA) {
                    comparison = -1;
                }
                return comparison;
            }

            datas.sort(compare);
            setTimeout(() => {
                this.setState({ stitchListData: datas, loadingApi: false })
            }, 200)

        })


    }


    handleDelete = (e, id) => {
        e.preventDefault();
        let { dispatch } = this.props;
        dispatch(deleteStitch(id)).then(res => {
            if (res && res.status === 200) {
                this.loadFunc(1, 'delete');
            }
        })
    }

    stitchEdit = (e, id) => {
        this.props.history.push(adminRoutes.EDITSTITCH + "/" + id)

    }


    stitchAdd = (e) => {
        e.preventDefault()
        this.props.history.push(adminRoutes.ADDSTITCH)
    }

    render() {
        let { loading, data } = this.props;
        let { stitchListData, patternId, loadingApi } = this.state;
        let total = data && data.data && data.data.data && data.data.data.length > 0 && data.data.recordsTotal

        return (
            <Layout>
                <div className="content-box">
                    <div className="bredcrumb-div">
                        <Breadcrumb separator="-">

                            <Breadcrumb.Item><Link to={adminRoutes.STITCHLIST}>Stitches</Link></Breadcrumb.Item>

                        </Breadcrumb>
                    </div>
                    <div className="page-title d-flex">
                        <div className="PageTitleLeft">
                            <h2>Stitch List</h2>
                            <h3> {this.state.pageNo < this.state.recordsTotals ?
                                this.state.pageNo : this.state.recordsTotals}
                                /{this.state.recordsTotals}</h3>
                        </div>
                        <Button onClick={this.stitchAdd} type="primary" className="primary-btn"> ADD STITCH </Button>
                    </div>
                    <div className="page-content StitchListContent">
                        <div className="pattern_wrapper">
                            {
                                loadingApi === true ? <div><Spin /></div> :
                                    <InfiniteScroll
                                        pageStart={1}
                                        initialLoad={false}
                                        hasMore={stitchListData.length < total ? true : false}
                                        // loader={<div >Loading ...</div>}
                                        useWindow={true}
                                        loadMore={this.loadFunc}

                                    >
                                        {
                                            stitchListData && stitchListData.length > 0 && stitchListData.map((item, i) => (
                                                <div key={i}>

                                                    <div className="pattern-list-box size-list-box">

                                                        <div className="pattern-list-content ">
                                                            <h2>{item.name}</h2>
                                                        </div>

                                                        <div className="pattern-list-contentIMG">
                                                            <div className="StitchesIMG">
                                                                {item.image && item.image.length > 0 && <img src={`${SERVER_URL}stitches/${item.image}`} alt=""
                                                                    onLoad={this.handleImageLoaded}
                                                                    onError={this.handleImageErrored}
                                                                />
                                                                }
                                                            </div>
                                                            <div className="size-info-box">
                                                                <h4>Abbreviation :</h4>
                                                                <p>{item.abbreviation}</p>
                                                            </div>
                                                            <div className="size-info-box">
                                                                <h4>Height :</h4>
                                                                <p>{item.height}</p>
                                                            </div>
                                                            <div className="size-info-box">
                                                                <h4>Width :</h4>
                                                                <p>{item.width}</p>
                                                            </div>


                                                        </div>
                                                        <div className="pattern-action">
                                                            <div className="edit-on-click">
                                                                <Button className="pattern-action-button" onClick={(e) => this.stitchEdit(e, item._id)}></Button>
                                                            </div>
                                                            <ul>
                                                                <li>
                                                                    <Button className="pattern-action-button" onClick={(e) => this.stitchEdit(e, item._id)}>
                                                                        <i><img src="images/edit.svg" alt="" /></i> <span>EDIT</span>
                                                                    </Button>
                                                                </li>
                                                                <li>
                                                                    <Popconfirm
                                                                        title="Are you sure you want to permanently delete this?"
                                                                        onConfirm={(e) => this.handleDelete(e, item._id)}
                                                                        //onConfirm
                                                                        onCancel={this.cancel}
                                                                        okText="Yes"
                                                                        cancelText="No"
                                                                    >
                                                                        <Button className="pattern-action-button">
                                                                            <i><img src="images/delete.svg" alt="" /></i> <span>DELETE</span>
                                                                        </Button>
                                                                    </Popconfirm>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>

                                                </div>

                                            ))

                                        }
                                    </InfiniteScroll>
                            }
                        </div>


                    </div >
                </div>
            </Layout >
        )
    }

}

const mapStateToProps = ((state) => {
    let { loading, data, error } = state.getStiches

    return {
        loading,
        data,
        error,

    }
})


export default connect(mapStateToProps)(ListStitch);