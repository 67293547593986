import React, { Component } from 'react'
import Layout from './Layout';
import { Button, Breadcrumb, Popconfirm, Spin, Menu, Dropdown, Icon, Select, Checkbox } from 'antd';
import { adminRoutes } from '../helpers/adminRoutes';
import { connect } from 'react-redux';
import { getUser, resetListUser } from '../redux/actions/user';
import { deleteUser } from '../redux/actions/deleteUser';
import { login } from '../redux/actions/login';
import InfiniteScroll from 'react-infinite-scroller';
import { Link } from 'react-router-dom';
import { SERVER_URL } from '../api';
import { responsiveArray } from 'antd/lib/_util/responsiveObserve';

import moment from 'moment';
const { Option } = Select;

class ListUsers extends Component {

    constructor(props) {
        super(props)
        this.state = {
            // recordsTotals: 0,
            UserListData: [],
            drpVal: "web",
            betaValue: null,
            userData: null,
            loadingApi: false,
            pageNo: 0,
            imageStatus: "loading"
        }
    }



    componentWillUnmount() {
        this.setState({
            userListData: []
        })
    }

    componentDidMount() {
        let { dispatch } = this.props;
        // dispatch(resetAddStitchData())
        let userData = JSON.parse(localStorage.getItem('adminData'));
        this.setState({ userData: userData })

        this.loadFunc(1, '', '', "web", this.state.betaValue)
    }

    handleDelete = (e, id) => {
        e.preventDefault();
        let { dispatch } = this.props;
        dispatch(deleteUser(id)).then(res => {
            console.log('del_resp => ', res);

            if (res) {

                this.loadFunc(1, 'delete');
            }
        })
    }

    loadFunc = (e, string, value, drpValue, betaValue) => {
        console.log('string => ', string);

        if (string === 'delete') {
            this.setState({ loadingApi: true });
        }
        let { dispatch, location } = this.props;


        let page = (e - 1) * 9;
        let data = {

            start: page,
            length: 9,
            search: value,
            beta: this.state.betaValue ? this.state.betaValue : betaValue,
            drpVal: drpValue ? drpValue : this.state.drpVal
        }
        let { UserListData } = this.state

        let datas = string === 'delete' || string === 'search' || string === 'beta' || string === 'duplicate' ? [] : [...UserListData];
        dispatch(getUser(data)).then(res => {
            this.setState({ newPageNo: e, recordsTotals: res && res.data && res.data.recordsTotal })
            this.setState({ pageNo: page + 9 })
            if (res && res.data && res.data.data && res.data.data.length > 0) {
                res.data.data.map(dat => (
                    datas.push(dat)

                ))
                setTimeout(() => {
                    this.setState({ UserListData: datas, drpVal: data.drpVal, loadingApi: false })
                }, 200)
            }

            // function compare(a, b) {
            //     const bandA = a._id
            //     const bandB = b._id

            //     let comparison = 0;
            //     if (bandB > bandA) {
            //         comparison = 1;
            //     } else if (bandB < bandA) {
            //         comparison = -1;
            //     }
            //     return comparison;
            // }

            // datas.sort(compare);


        })



    }

    handleWeb = (e) => {
        // e.preventDefault();

        this.setState({ drpVal: e })
        let { dispatch } = this.props;
        dispatch(resetListUser())
        this.setState({ UserListData: [], loadingApi: true }, () => {
            this.loadFunc(1, '', '', e, this.state.betaValue);
        })
    }

    handleMobile = (e) => {
        // e.preventDefault();
        this.setState({ drpVal: "mobile" })
        let { dispatch } = this.props;
        dispatch(resetListUser())
        this.setState({ UserListData: [], loadingApi: true })
        this.loadFunc(e);

    }
    UserEdit = (e, id) => {
        this.props.history.push(adminRoutes.EDITUSER + "/" + id)

    }
    onBeta(e) {
        this.setState({ betaValue: e.target.checked })
        if (e.target.checked === true) {
            this.setState({ betaValue: "yes" })
        }
        else {
            this.setState({ betaValue: "no" })

        }
        this.setState({ UserListData: [], loadingApi: true }, () => {
            this.loadFunc(1, '', '', this.state.drpVal, this.state.betaValue);
        })
    }

    UserAdd = (e) => {
        e.preventDefault()
        this.props.history.push(adminRoutes.ADDUSER)
    }

    render() {
        let { loading, data } = this.props;
        let { UserListData, patternId, userData, loadingApi } = this.state;
        console.log('data && data.data && data.data.data.length > 0 && data.data => ', data && data.data && data.data.data.length > 0 && data.data);

        let total = data && data.data && data.data.data.length > 0 && data.data.recordsTotal
        // const menu = (
        //     <Menu defaultSelectedKeys={["web"]} selectable>
        //         <Menu.Item key="web" onClick={(e) => this.handleWeb(e)}>
        //             Web

        //         </Menu.Item>
        //         <Menu.Item key="mobile" onClick={(e) => this.handleMobile(e)}>
        //             Mobile
        //          </Menu.Item>
        //     </Menu>
        // );
        return (

            <Layout>

                <div className="content-box">
                    <div className="bredcrumb-div">
                        <Breadcrumb separator="-">

                            <Breadcrumb.Item><Link to={adminRoutes.ListUsers}>Users</Link></Breadcrumb.Item>

                        </Breadcrumb>
                    </div>
                    <div className="CustomeSelect UserWeb">
                        <Select defaultValue="web" style={{ width: 120 }} onChange={this.handleWeb}>
                            <Option value="mobile">Mobile</Option>
                            <Option value="web">Web</Option>
                        </Select>

                    </div>
                    {this.state.drpVal === 'mobile' && <div className="BetaLabel"><label>Beta</label>
                        <Checkbox onChange={(e) => this.onBeta(e)}></Checkbox></div>}

                    <div className="page-title d-flex">
                        <div className="PageTitleLeft">

                            <h2>Users List</h2>
                            <h3> {this.state.pageNo < this.state.recordsTotals ?
                                this.state.pageNo : this.state.recordsTotals}
                                /{this.state.recordsTotals}</h3>
                        </div>
                        <Button onClick={this.UserAdd} type="primary" className="primary-btn"> ADD USERS </Button>

                    </div>
                    <div className="page-content StitchListContent">
                        <div className="pattern_wrapper">
                            {
                                loadingApi === true ? <div><Spin /></div> :
                                    <InfiniteScroll
                                        pageStart={1}
                                        initialLoad={false}
                                        hasMore={UserListData.length < total ? true : false}
                                        // loader={<div >Loading ...</div>}
                                        useWindow={true}
                                        loadMore={this.loadFunc}

                                    >
                                        {
                                            UserListData && UserListData.length > 0 && UserListData.map((item, i) => (
                                                <div key={i}>

                                                    <div className="pattern-list-box size-list-box">

                                                        <div className="pattern-list-content ">
                                                            <h2>{item.name}</h2>
                                                        </div>

                                                        <div className="pattern-list-contentIMG">

                                                            <div className="size-info-box">
                                                                <h4>Email :</h4>
                                                                <p>{item.email}</p>
                                                            </div>

                                                        </div>
                                                        {item.role === 'user' && <div className="pattern-list-contentIMG">

                                                            <div className="size-info-box">
                                                                <h4>Beta :</h4>
                                                                <p>{item && item.beta === true ? 'Yes' : 'No'}</p>
                                                            </div>

                                                        </div>
                                                        }
                                                        <div className="pattern-list-contentIMG">

                                                            <div className="size-info-box">
                                                                <h4>Role :</h4>

                                                                <p>{(item.role).charAt(0).toUpperCase() + (item.role).slice(1)}</p>
                                                            </div>

                                                        </div>

                                                        <div className="pattern-list-contentIMG">

                                                            <div className="size-info-box">
                                                                <h4>Created :</h4>
                                                                {/* <p>{item.createdAt}</p> */}
                                                                <p>  {new moment(item.createdAt).format('MM-DD-YYYY')}</p>

                                                            </div>

                                                        </div>
                                                        <div className="pattern-action">
                                                            <div className="edit-on-click">
                                                                <Button className="pattern-action-button" onClick={(e) => this.UserEdit(e, item._id)}></Button>
                                                            </div>
                                                            <ul>
                                                                <li>
                                                                    <Button className="pattern-action-button" onClick={(e) => this.UserEdit(e, item._id)}>
                                                                        <i><img src="images/edit.svg" alt="" /></i> <span>EDIT</span>
                                                                    </Button>
                                                                </li>
                                                                <li>
                                                                    <Popconfirm
                                                                        title="Are you sure you want to permanently delete this?"
                                                                        onConfirm={(e) => this.handleDelete(e, item._id)}
                                                                        onCancel={this.cancel}
                                                                        okText="Yes"
                                                                        cancelText="No"
                                                                    >
                                                                        <Button className="pattern-action-button">
                                                                            <i><img src="images/delete.svg" alt="" /></i> <span>DELETE</span>
                                                                        </Button>
                                                                    </Popconfirm>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>

                                                </div>

                                            ))

                                        }
                                    </InfiniteScroll>
                            }
                        </div>


                    </div >
                </div>
            </Layout >
        )
    }

}

const mapStateToProps = ((state) => {
    let { loading, data, error } = state.getUser

    return {
        loading,
        data,
        error,
        loginLoading: state.login.loading,
        loginData: state.login.data,
        loginError: state.login.error
    }
})


export default connect(mapStateToProps)(ListUsers);