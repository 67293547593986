import { SERVER_URL } from "../../api";
import axios from 'axios';
import { getHeaderWithToken } from "../../helpers/func";

export const GET_COLORS_REQUEST = 'GET_COLORS_REQUEST';
export const GET_COLORS_SUCCESS = 'GET_COLORS_SUCCESS';
export const GET_COLORS_ERROR = 'GET_COLORS_ERROR';
export const RESET_GET_COLORS = 'RESET_GET_COLORS';


export const getColors = (id) => {
    let headers = getHeaderWithToken();

    return dispatch => {
        return new Promise(function (resolve, reject) {
            dispatch({
                type: GET_COLORS_REQUEST
            })
            axios({
                method: 'GET',
                url: `${SERVER_URL}v1/pattern/colors/${id}`,
                headers: headers,

            }).then(function (response) {
                dispatch({
                    type: GET_COLORS_SUCCESS,
                    payload: response
                })
                resolve(response);
            })
                .catch(function (error) {
                    dispatch({
                        type: GET_COLORS_ERROR,
                        payload: error
                    })
                    reject(error)
                })
        })

    }
}

export const resetViewPattern = () => {
    return dispatch => {
        dispatch({
            type: RESET_GET_COLORS
        })
    }
}



