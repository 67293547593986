import React, { Component } from 'react'
import Sidebar from './Sidebar'
import Header from './Header'

class Layout extends Component {
    openSider = (e) => {
        e.preventDefault();
        let x = document.getElementsByClassName("openSider");
        if (x.length > 0) {
            document.body.classList.remove("openSider");
        } else {
            document.body.classList.add("openSider");
        }
    }

    render() {
        return (
            <div className=" content-wrap">
                <div className="sidebar-toggle">
                    <button onClick={this.openSider}>
                        <svg width="31px" height="18px">
                            <path fillRule="evenodd" d="M-0.000,18.000 L-0.000,16.000 L31.000,16.000 L31.000,18.000 L-0.000,18.000 ZM-0.000,8.000 L20.000,8.000 L20.000,10.000 L-0.000,10.000 L-0.000,8.000 ZM-0.000,-0.000 L9.000,-0.000 L9.000,2.000 L-0.000,2.000 L-0.000,-0.000 Z" />
                        </svg>
                    </button>
                </div>
                <div className="smallview-toggle">
                    <button onClick={this.openSider}>
                        <svg width="31px" height="18px">
                            <path fillRule="evenodd" d="M-0.000,18.000 L-0.000,16.000 L31.000,16.000 L31.000,18.000 L-0.000,18.000 ZM-0.000,8.000 L20.000,8.000 L20.000,10.000 L-0.000,10.000 L-0.000,8.000 ZM-0.000,-0.000 L9.000,-0.000 L9.000,2.000 L-0.000,2.000 L-0.000,-0.000 Z" />
                        </svg>
                    </button>
                </div>
                <Sidebar />
                <Header />
                {this.props.children}

            </div>

        )
    }
}
export default Layout;