import { VIEW_STITCH_REQUEST, VIEW_STITCH_SUCCESS, VIEW_STITCH_ERROR, RESET_VIEW_STITCH } from "../actions/viewStitch"

let initialState = {
    loading: false,
    data: null,
    error: null
}

const viewStitch = (state = initialState, action) => {
    switch (action.type) {
        case VIEW_STITCH_REQUEST:
            return {
                ...state,
                loading: true
            }

        case VIEW_STITCH_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            }
        case VIEW_STITCH_ERROR:
            return {
                ...state,
                loading: false,
                data: action.payload
            }
        case RESET_VIEW_STITCH:
            return {
                ...state,
                loading: false,
                data: null,
                error: null
            }

        default:
            return state;
    }
}

export default viewStitch