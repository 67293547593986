export const CREATE_GRID_REQUEST = 'CREATE_GRID_REQUEST';
export const CREATE_GRID = 'CREATE_GRID';
export const createGridArray = (row, col) => {
    return dispatch => {
        dispatch({
            type: CREATE_GRID_REQUEST
        })
        setTimeout(() => {
            dispatch({
                type: CREATE_GRID,
                payload: { row, col }
            })
        }, 100)

    }
}