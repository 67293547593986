import React, { Component } from 'react'
import { Form, Input, Button } from 'antd';
import { connect } from 'react-redux';
import { forget_password } from '../redux/actions/forget_password';
import { ADMIN_BASE_URL } from '../helpers/adminRoutes';

class ForgetPasswordForm extends Component {
    state = {
        confirmDirty: false,
    };

    handleSubmit = e => {
        e.preventDefault();
        let { history } = this.props;
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.props.dispatch(forget_password(values))
                history.push(ADMIN_BASE_URL)
            }
        });
    };

    handleConfirmBlur = e => {
        const { value } = e.target;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    };
    render() {

        const { getFieldDecorator } = this.props.form;
        let { loading } = this.props;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 }
            },
            wrapperCol: {
                xs: { span: 24 }
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                }
            },
        };
        return (
            <div className="login-page">
                <div className="login-box">
                    <h2>Forget Password</h2>
                    <Form {...formItemLayout} onSubmit={this.handleSubmit} className="login-form">
                        <h3 className="form-title">Email</h3>
                        <Form.Item>
                            {getFieldDecorator('email', {
                                rules: [
                                    {
                                        type: 'email',
                                        message: 'The input is not valid E-mail!',
                                    },
                                    {
                                        required: true,
                                        message: 'Please input your E-mail!',
                                    },
                                ],
                            })(<Input placeholder="Email" />)}
                        </Form.Item>
                        <Form.Item {...tailFormItemLayout} className="login-submit-btn">
                            <Button disabled={loading} type="primary" htmlType="submit" className="panel-btn login-form-button">
                                Reset Password
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        );
    }
}


const mapStateToProps = ((state) => {
    const { loading, data, error } = state.forget_password;
    return {
        loading,
        data,
        error
    }
})
const ForgetPasswordComponent = Form.create({ name: 'forgot_form' })(ForgetPasswordForm);
export default connect(mapStateToProps)(ForgetPasswordComponent)