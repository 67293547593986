import { RESET_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_ERROR } from "../actions/reset_password"

let initialState = {
    loading: false,
    data: null,
    error: null
}

const forget_password = (state = initialState, action) => {
    switch (action.type) {
        case RESET_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true
            }

        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            }
        case RESET_PASSWORD_ERROR:
            return {
                ...state,
                loading: false,
                data: action.payload
            }

        default:
            return state;
    }
}

export default forget_password