export const ADMIN_BASE_URL = "/";
export const adminRoutes = {
    LOGIN: `${ADMIN_BASE_URL}login`,
    FORGOT_PASSWORD: `${ADMIN_BASE_URL}forget_password`,
    RESET_PASSWORD: `${ADMIN_BASE_URL}reset-password`,
    DASHBOARD: `${ADMIN_BASE_URL}user`,
    PATTERNLIST: `${ADMIN_BASE_URL}pattern_list`,
    ADDPATTERN: `${ADMIN_BASE_URL}add_pattern`,
    ADDSTITCH: `${ADMIN_BASE_URL}add_stitch`,
    STITCHLIST: `${ADMIN_BASE_URL}stitch_list`,
    USERSLIST: `${ADMIN_BASE_URL}users_list`,
    ADDUSER: `${ADMIN_BASE_URL}add_user`,
    ADDSIZE: `${ADMIN_BASE_URL}add_size`,
    ADDSECTION: `${ADMIN_BASE_URL}add_section`,
    SIZELIST: `${ADMIN_BASE_URL}size_list`,
    SECTIONLIST: `${ADMIN_BASE_URL}section_list`,
    VIEWPATTERN: `${ADMIN_BASE_URL}view_pattern`,
    VIEWSIZE: `${ADMIN_BASE_URL}view_size`,
    VIEWSECTION: `${ADMIN_BASE_URL}view_section`,
    GRID: `${ADMIN_BASE_URL}chart`,
    EDITPATTERN: `${ADMIN_BASE_URL}edit_pattern`,
    EDITUSER: `${ADMIN_BASE_URL}edit_user`,
    EDITSTITCH: `${ADMIN_BASE_URL}edit_stitch`,
    EDITSIZE: `${ADMIN_BASE_URL}edit_size`,
    EDITSECTION: `${ADMIN_BASE_URL}edit_section`,
};
