import { SERVER_URL } from "../../api";
import axios from 'axios'
import { getHeaderWithToken } from "../../helpers/func";
export const DELETE_Chart_REQUEST = 'DELETE_Chart_REQUEST';
export const DELETE_Chart_SUCCESS = 'DELETE_Chart_SUCCESS';
export const DELETE_Chart_ERROR = 'DELETE_Chart_ERROR';
export const RESET_DELETE_Chart = 'RESET_DELETE_Chart';


export const deleteChart = (id) => {

    let headers = getHeaderWithToken();
    return dispatch => {
        return new Promise(function (resolve, reject) {
            dispatch({
                type: DELETE_Chart_REQUEST
            })
            axios({
                method: 'DELETE',
                url: `${SERVER_URL}v1/pattern/deleteChart/${id}`,
                headers: headers,
            }).then(function (response) {
                dispatch({
                    type: DELETE_Chart_SUCCESS,
                    payload: response
                })
                resolve(response);
            })
                .catch(function (error) {
                    dispatch({
                        type: DELETE_Chart_ERROR,
                        payload: error
                    })
                    reject(error)
                })
        })

    }
}



