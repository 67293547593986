import React, { Component } from 'react'
import Cell from './Cell'

export default class Row extends Component {

    shouldComponentUpdate(nextProps) {

        if (nextProps.rowChanged || nextProps.cellsindex || nextProps.rowsindex || nextProps.loadStitch || nextProps.RedoUndoData !== this.props.RedoUndoData || nextProps.image !== this.props.image || nextProps.color !== this.props.color || nextProps.important !== this.props.important || nextProps.note !== this.props.note) {

            return true
        }
        return false
    }

    render() {
        return (
            <ul>
                {this.props.cells.map((cell, j) => (
                    <Cell
                        key={`row-${this.props.rowIndex}-cell-${j}`}
                        rowIndex={this.props.rowIndex}
                        cellIndex={j}
                        data={cell}
                        stitchLst={this.props.stitchLst}
                        loadStitch={this.props.loadStitch}
                        rowsindex={this.props.rowsindex}
                        cellsindex={this.props.cellsindex}
                        color={this.props.color}
                        onChange={this.props.onCellChange}
                        isChecked={this.props.isChecked}
                    ></Cell>
                ))}
            </ul>
        )
    }
}
