import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import configureStore from './redux/store';
import axios from 'axios';

const store = configureStore()


// axios.interceptors.response.use(
//     response => {
//         console.log("Res DM =>", response);
//         return response;
//     },
//     err => {
//         console.log("Error DM =>", JSON.stringify(err.response));
//         return new Promise((resolve, reject) => {
//             if (
//                 err.response.status === 401 &&
//                 err.config &&
//                 !err.config.__isRetryRequest
//             ) {
//                 localStorage.clear();
//                 console.log("Locak screen ->", err.response);
//             }


//             if (err.response.status === 403) {
//                 console.log('403 Not accses')
//                 // if you ever get an unauthorized response, logout the user
//                 localStorage.clear() // add later
//                 this.setSession(null);
//             }


//             throw err;
//             // return err;

//         });
//     }
// );



ReactDOM.render(<Provider store={store}> <App /></Provider >, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();