import React, { Component } from 'react'
import { Checkbox, Popover, Spin } from 'antd';
import { SERVER_URL } from '../api';
// import Loader from 'react-loader-spinner'

export default class Cell extends Component {

    shouldComponentUpdate(nextProps) {


        if (this.props.data !== nextProps.data) {
            return true
        }

        if (this.props.stitchLst !== nextProps.stitchLst) {
            return true
        }
        if (this.props.data.image !== nextProps.data.image) {
            return true
        }

        if (this.props.data.important !== nextProps.important) {
            return true
        }
        if (this.props.data.loadStitch !== nextProps.loadStitch) {
            return true
        }
        if (this.props.data.rowsindex !== nextProps.rowsindex) {
            return true
        }
        if (this.props.data.cellsindex !== nextProps.cellsindex) {
            return true
        }
        if (this.props.data.border !== nextProps.border) {
            return true
        }
        if (this.props.data.mark !== nextProps.mark) {
            return true
        }
        if (this.props.data.note) {
            return true
        }

        if (this.props.data.color !== nextProps.color) {
            return true
        }
        return false
    }



    render() {

        return (
            <li className={this.props.data.image ? "AddedStitches" : ""} >
                <Checkbox checked={this.props.isChecked} onChange={(e) => this.props.onChange(e, this.props.rowIndex, this.props.cellIndex)}>

                    {this.props.cellIndex === this.props.cellsindex &&
                        this.props.rowIndex === this.props.rowsindex &&
                        this.props.loadStitch === true ?
                        <Spin /> :

                        <Popover placement="right" trigger="hover" onMouseEnter={this.props.data.important === true ? false : true} content={<div><h1>{this.props.data.note}</h1></div>} >
                            {
                                typeof this.props.data === 'object'
                                &&

                                < div
                                    style={{
                                        backgroundColor: this.props.color ?
                                            this.props.color.map(c => c._id).includes(this.props.data.colorIndex)
                                                ? this.props.color.find(o => o._id === this.props.data.colorIndex).color : ""

                                            : ""
                                    }}
                                    className={`${this.props.data.important === true ? "triangle" : ""} " " ${this.props.data.important === true && "important-border"} `}
                                >
                                    {/* <div className={` ${this.props.data.important === true ? "important-border" : ""} ${this.props.data.mark ? this.props.data.mark : ""} ${this.props.data.border} `}> */}
                                    <div className={this.props.data.border ? this.props.data.border === "TopBottomRepeat" ? "TopBottomRepeat" : this.props.data.border : ""}>
                                        <div className={this.props.data.mark ? this.props.data.mark === "TopBottomMarker" ? "TopBottomMarker" : this.props.data.mark : ""}>
                                            {/* <div className={`  ${this.props.data.mark ? this.props.data.mark : ""} ${this.props.data.border} `}> */}

                                            <div class="StitchNumberBox">{this.props.data.numberVal} </div>
                                            <img src=
                                                // {this.props.data.numberVal} 
                                                {this.props.data.image ?
                                                    this.props.data.type == "elemental" ?
                                                        this.props.stitchLst && this.props.stitchLst.elemental_stiches.map(c => c._id)
                                                            .includes(this.props.data._id) === true
                                                            ?
                                                            this.props.stitchLst
                                                                ? `${SERVER_URL}stitches/${this.props.stitchLst.elemental_stiches.find(o => o._id === this.props.data._id).image}`
                                                                :
                                                                `${SERVER_URL}stitches/errorstitch.svg`
                                                            :
                                                            `${SERVER_URL}stitches/errorstitch.svg`

                                                        : this.props.data.type == "compound" ?
                                                            this.props.stitchLst && this.props.stitchLst.compound_stiches.map(c => c._id)
                                                                .includes(this.props.data._id) === true
                                                                ?
                                                                this.props.stitchLst
                                                                    ? `${SERVER_URL}stitches/${this.props.stitchLst.compound_stiches.find(o => o._id === this.props.data._id).image}`
                                                                    :
                                                                    `${SERVER_URL}stitches/errorstitch.svg`
                                                                :
                                                                `${SERVER_URL}stitches/knit.png`

                                                            : ""
                                                    : `${SERVER_URL}stitches/knit.png`



                                                }

                                                style={{ height: 41, width: this.props.data.type == "compound" && this.props.data.width > 2 ? (this.props.data.width) * 42 + 4 : (this.props.data.width) * 41, backgroundColor: this.props.color ? this.props.color[this.props.data.colorIndex] : "#fff" }}
                                                alt="" />


                                        </div>
                                    </div>
                                </div>
                            }
                        </Popover>

                    }
                </Checkbox>

            </ li >
        )
    }

}
