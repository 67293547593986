import { VIEW_USER_REQUEST, VIEW_USER_SUCCESS, VIEW_USER_ERROR, RESET_VIEW_USER } from "../actions/viewUser"

let initialState = {
    loading: false,
    data: null,
    error: null
}

const viewUser = (state = initialState, action) => {
    switch (action.type) {
        case VIEW_USER_REQUEST:
            return {
                ...state,
                loading: true
            }

        case VIEW_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            }
        case VIEW_USER_ERROR:
            return {
                ...state,
                loading: false,
                data: action.payload
            }
        case RESET_VIEW_USER:
            return {
                ...state,
                loading: false,
                data: null,
                error: null
            }

        default:
            return state;
    }
}

export default viewUser