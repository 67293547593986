import { SERVER_URL } from "../../api";
import axios from 'axios'
import { getHeaderWithToken } from "../../helpers/func";
export const ADD_CHART_REQUEST = 'ADD_CHART_REQUEST';
export const ADD_CHART_SUCCESS = 'ADD_CHART_SUCCESS';
export const ADD_CHART_ERROR = 'ADD_CHART_ERROR';
export const RESET_ADD_CHART = 'RESET_ADD_CHART';


export const addChart = (data, tableValue, instruction, instructionGroup, patternId, sizeId, sectionId, construction) => {
    data.tableValue = tableValue;
    data.patternId = patternId;
    data.sizeId = sizeId;
    data.sectionId = sectionId;
    data.construction = construction;
    data.instruction = instruction;
    data.instructionGroup = instructionGroup;

    let headers = getHeaderWithToken();

    return dispatch => {
        return new Promise(function (resolve, reject) {

            dispatch({
                type: ADD_CHART_REQUEST
            })
            axios({
                method: 'POST',
                url: `${SERVER_URL}v1/pattern/chart`,
                headers: headers,
                data: data
            }).then(function (response) {
                dispatch({
                    type: ADD_CHART_SUCCESS,
                    payload: response
                })
                resolve(response);

            })
                .catch(function (error) {
                    dispatch({
                        type: ADD_CHART_ERROR,
                        payload: error
                    })
                    reject(error)
                })
        })
    }
}

export const resetAddChartData = () => {
    return dispatch => {
        dispatch({
            type: RESET_ADD_CHART
        })
    }
}

