import { ADD_SECTION_REQUEST, ADD_SECTION_SUCCESS, ADD_SECTION_ERROR, RESET_ADD_SECTION } from "../actions/addSection"

let initialState = {
    loading: false,
    data: null,
    error: null
}

const addSection = (state = initialState, action) => {
    switch (action.type) {
        case ADD_SECTION_REQUEST:
            return {
                ...state,
                loading: true
            }

        case ADD_SECTION_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            }
        case ADD_SECTION_ERROR:
            return {
                ...state,
                loading: false,
                data: action.payload
            }
        case RESET_ADD_SECTION:
            return {
                ...state,
                loading: false,
                data: null,
                error: null
            }
        default:
            return state;
    }
}

export default addSection