import { SECTION_LIST_REQUEST, SECTION_LIST_SUCCESS, SECTION_LIST_ERROR } from "../actions/sectionList"

let initialState = {
    loading: false,
    data: null,
    sectionData: [],
    error: null
}

const sectionList = (state = initialState, action) => {
    switch (action.type) {
        case SECTION_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }

        case SECTION_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            }
        case SECTION_LIST_ERROR:
            return {
                ...state,
                loading: false,
                data: action.payload
            }

        default:
            return state;
    }
}

export default sectionList