import { SEARCH_REQUEST, SEARCH_SUCCESS, SEARCH_ERROR, RESET_SEARCH } from "../actions/search"

let initialState = {
    loading: false,
    data: null,
    error: null
}

const search = (state = initialState, action) => {
    switch (action.type) {
        case SEARCH_REQUEST:
            return {
                ...state,
                loading: true
            }

        case SEARCH_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            }
        case SEARCH_ERROR:
            return {
                ...state,
                loading: false,
                data: action.payload
            }
        case RESET_SEARCH:
            return {
                ...state,
                loading: false,
                data: null,
                error: null
            }

        default:
            return state;
    }
}

export default search