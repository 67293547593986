import { CREATE_GRID_REQUEST, CREATE_GRID } from "../actions/createGridArray"

let initialState = {
    gridArrayLoading: false,
    gridArrayData: [],
    error: null
}

function initTableValues(cellCount) {
    const cells = Array.from(Array(+cellCount).keys())
    return [...(cells.map(c => ({
        _id: '',
        color: '',
        border: '',
        important: false
    })))]
}

const createGridArray = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_GRID_REQUEST:
            return {
                ...state,
                gridArrayLoading: true
            }

        case CREATE_GRID:
            let tempArr = []
            for (let i = 0; i < action.payload.row; i++) {
                tempArr.push([...(initTableValues(action.payload.col))])
            }
            return {
                ...state,
                gridArrayLoading: false,
                gridArrayData: tempArr
            }
        default:
            return state;
    }
}

export default createGridArray