import React, { Component } from 'react'
import { SwatchesPicker } from 'react-color'

export default class Colors extends Component {
    constructor(props) {
        super(props)
        this.state = {
            color: this.props.color,
            displayColorPicker: this.props.displayColorPicker
        }
    }


    handleColorChangeComplete = (color, index) => {

        let Datas = color.hex;
        let colorData = this.props.color;
        colorData.push(Datas);

        this.setState({ color: colorData },
            () => this.props.eColors({ color: colorData })
        )

    };

    handleClick = (e) => {
        e.preventDefault();
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };


    render() {
        return (
            <div>
                <h2>Colors
                        <button onClick={this.handleClick}>
                        <svg viewBox="0 0 448 448" xmlns="http://www.w3.org/2000/svg"><path d="m272 184c-4.417969 0-8-3.582031-8-8v-176h-80v176c0 4.417969-3.582031 8-8 8h-176v80h176c4.417969 0 8 3.582031 8 8v176h80v-176c0-4.417969 3.582031-8 8-8h176v-80zm0 0" />
                        </svg>
                        {this.state.displayColorPicker ? <div className="popover">
                            <div className="cover" />
                            <SwatchesPicker onChange={this.handleColorChangeComplete} />
                        </div> : null}
                    </button>
                </h2>
            </div>
        )
    }
}

// const mapStateToProps = ((state) => {

// })
// export default (Colors);