import { VIEW_CHART_REQUEST, VIEW_CHART_SUCCESS, VIEW_CHART_ERROR, RESET_VIEW_CHART } from "../actions/getChart"

let initialState = {
    loading: false,
    data: null,
    error: null
}

const getChart = (state = initialState, action) => {
    switch (action.type) {
        case VIEW_CHART_REQUEST:
            return {
                ...state,
                loading: true
            }

        case VIEW_CHART_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.data
            }
        case VIEW_CHART_ERROR:
            return {
                ...state,
                loading: false,
                data: action.payload
            }
        case RESET_VIEW_CHART:
            return {
                ...state,
                loading: false,
                data: null,
                error: null
            }
        default:
            return state;
    }
}

export default getChart