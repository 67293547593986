import React, { Component } from 'react'
import { Descriptions } from 'antd';
import { connect } from 'react-redux';
import { Breadcrumb } from 'antd';
import Layout from './Layout';
import { viewPattern, resetViewPattern } from '../redux/actions/viewPattern';
import { SERVER_URL } from '../api';
import { Link } from 'react-router-dom';



class ViewPattern extends Component {
    componentDidMount() {
        let { dispatch, location } = this.props;
        let path = location.pathname.split('/')
        let patternId = path[2];
        dispatch(viewPattern(patternId)).then(res => {

        })

    }

    Capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    componentWillUnmount() {
        let { dispatch } = this.props;
        dispatch(resetViewPattern())
    }

    render() {
        let { loading, data } = this.props;

        let arr = [];
        data && data.data && data.data.data.image.map((dat, index) => {
            arr.push({ ImagePath: `${SERVER_URL}uploads/${dat}`, uid: index, originFileObj: `${SERVER_URL}uploads/${dat}` })
        })

        return (
            <Layout>

                <div className="content-box">
                    <div className="bredcrumb-div">
                        <Breadcrumb separator="-">
                            {!loading && data && data.data &&
                                <Breadcrumb.Item><Link to="">{data.data.data.patternName}</Link></Breadcrumb.Item>}
                            <Breadcrumb.Item><Link to="">View</Link></Breadcrumb.Item>

                        </Breadcrumb>
                    </div>
                    <div className="page-title d-flex">
                        <h2>Pattern Info</h2>
                    </div>

                    <div className="pattern_img_cstm">
                        {arr && arr.length > 0 && arr.map(img => (
                            <img src={img.ImagePath} alt="" />
                        ))}

                    </div>

                    <div className="white-box">
                        {!loading && data && data.data &&

                            <Descriptions bordered column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
                                <Descriptions.Item label="Pattern Name">{data.data.data.patternName} </Descriptions.Item>
                                <Descriptions.Item label="Author Name">{data.data.data.authorName} </Descriptions.Item>
                                <Descriptions.Item label="Description">{data.data.data.description}</Descriptions.Item>
                                {/* <Descriptions.Item label="Difficulty Type">{data.data.data.difficultyType}</Descriptions.Item> */}
                                <Descriptions.Item label="Level">{this.Capitalize(data.data.data.difficultyType)}</Descriptions.Item>
                                <Descriptions.Item label="Price">${data.data.data.amount}</Descriptions.Item>
                            </Descriptions>}
                    </div>
                </div>

            </Layout>

        )
    }
}

const mapStateToProps = ((state) => {
    let { loading, data, error } = state.viewPattern
    return {
        loading,
        data,
        error
    }
})

export default connect(mapStateToProps)(ViewPattern);
