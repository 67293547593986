import { DELETE_STITCH_IMAGE_REQUEST, DELETE_STITCH_IMAGE_SUCCESS, DELETE_STITCH_IMAGE_ERROR } from "../actions/deleteStitchImage"

let initialState = {
    loading: false,
    data: null,
    error: null
}

const deleteStitchImage = (state = initialState, action) => {
    switch (action.type) {
        case DELETE_STITCH_IMAGE_REQUEST:
            return {
                ...state,
                loading: true
            }

        case DELETE_STITCH_IMAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            }
        case DELETE_STITCH_IMAGE_ERROR:
            return {
                ...state,
                loading: false,
                data: action.payload
            }

        default:
            return state;
    }
}

export default deleteStitchImage