import React, { Component } from 'react'
import Layout from './Layout';
import { Button, Breadcrumb, Popconfirm, Spin } from 'antd';
import { adminRoutes } from '../helpers/adminRoutes';
import { connect } from 'react-redux';
import { viewSection } from '../redux/actions/viewSection';
import { deleteSection } from '../redux/actions/deleteSection';
import { sectionList } from '../redux/actions/sectionList';
import InfiniteScroll from 'react-infinite-scroller';
import { addSection, resetAddSectionData, } from '../redux/actions/addSection';
import { Link } from 'react-router-dom';
import { SERVER_URL } from '../api';

class ListSection extends Component {

    constructor(props) {
        super(props)
        this.state = {
            patternId: '',
            sectionId: '',
            loadingApi: false,
            sectionListData: [],
        }
    }


    sectionEdit = (e, sectionId) => {
        let { data } = this.props;
        let { patternId } = this.state;
        let sizeId = data && data.data && data.data.id;

        this.props.history.push(adminRoutes.EDITSECTION + "/" + patternId + "/" + sizeId + "/" + sectionId)
    }
    componentDidMount() {
        let { location, dispatch } = this.props;
        let path = location.pathname.split('/')
        let patternId = path[2];
        let sectionId = path[3];
        dispatch(resetAddSectionData())

        this.setState({ patternId: patternId, sectionId: sectionId })
        this.loadFunc(1)
    }


    loadFunc = (e, string, value) => {
        if (string === 'delete' || string === 'duplicate') {
            this.setState({ loadingApi: true });
        }
        let { dispatch, location } = this.props;
        dispatch(resetAddSectionData())

        let path = location.pathname.split('/')
        let patternId = path[2];
        let sectionId = path[3];
        this.setState({ patternId: patternId, sectionId: sectionId })
        let page = (e - 1) * 9
        let data = {
            start: page,
            length: 9,
            search: value
        }
        let { sectionListData } = this.state
        let datas = string === 'delete' || string === 'search' || string === 'duplicate' ? [] : sectionListData;

        dispatch(sectionList({ id: sectionId, ...data })).then(res => {
            this.setState({ newPageNo: e, recordsTotals: res && res.data && res.data.recordsTotal })
            this.setState({ pageNo: page + 9 })
            res.data.data.map(dat => (
                datas.push(dat)
            ))

            this.setState({ sectionListData: datas, loadingApi: false })
            function compare(a, b) {
                const bandA = b.position
                const bandB = a.position

                let comparison = 0;
                if (bandB > bandA) {
                    comparison = 1;
                } else if (bandB < bandA) {
                    comparison = -1;
                }
                return comparison;
            }

            datas.sort(compare);
            dispatch(resetAddSectionData())

            setTimeout(() => {
                this.setState({ sectionListData: datas, loadingApi: false })
            }, 200)

        })

    }

    handleView = (e, id) => {
        let { dispatch, history } = this.props;
        e.preventDefault();
        dispatch(viewSection(id))

        history.push(adminRoutes.VIEWSECTION + '/' + id)
    }

    handleDelete = (e, id) => {
        e.preventDefault();
        let { dispatch } = this.props;
        dispatch(deleteSection(id)).then(res => {
            if (res && res.status === 200) {
                this.loadFunc(1, 'delete');
                window.location.reload()

            }
        })
    }

    handleChart = (e, id) => {
        let { location } = this.props;
        let path = location.pathname.split('/')
        let patternId = path[2];
        let sizeId = path[3];
        this.props.history.push(adminRoutes.GRID + '/' + patternId + '/' + sizeId + '/' + id);

    }

    handleduplicate = (e, data) => {
        e.preventDefault();
        let { dispatch } = this.props;
        dispatch(addSection({ type: "duplicate", ...data, "sizeId": data.sizeId })).then(res => {

            if (res && res.status === 200) {

                this.loadFunc(1, 'duplicate', '')
                dispatch(resetAddSectionData())

            }
        })

    }

    sectionAdd = (e, id) => {
        let { patternId, sectionId } = this.state;
        this.props.history.push(adminRoutes.ADDSECTION + '/' + patternId + '/' + sectionId)
    }

    render() {
        let { loading, data } = this.props;
        let { sectionListData, patternId, loadingApi } = this.state;

        let total = data && data.data && data.data.data.length > 0 && data.data.recordsTotal

        return (
            <Layout>
                <div className="content-box">
                    <div className="bredcrumb-div">
                        <Breadcrumb separator="-">

                            <Breadcrumb.Item><Link to={adminRoutes.PATTERNLIST}>{data && data.data && data.data.pattern.patternId.patternName}</Link></Breadcrumb.Item>
                            <Breadcrumb.Item><Link to={adminRoutes.SIZELIST + '/' + patternId}>{data && data.data && data.data.pattern.sizeName}</Link></Breadcrumb.Item>
                            <Breadcrumb.Item> Sections</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className="page-title d-flex">
                        <div className="PageTitleLeft">

                            <h2>Section List</h2>
                            <h3> {this.state.pageNo < this.state.recordsTotals ?
                                this.state.pageNo : this.state.recordsTotals}
                                /{this.state.recordsTotals}</h3>
                        </div>
                        <Button onClick={this.sectionAdd} type="primary" htmlType="submit" className="primary-btn"> ADD SECTION </Button>
                    </div>
                    <div className="page-content">
                        <div className="pattern-list">
                            <div className="pattern_wrapper">
                                {
                                    loadingApi === true ? <div><Spin /></div> :
                                        <InfiniteScroll
                                            pageStart={1}
                                            initialLoad={false}
                                            hasMore={sectionListData.length < total ? true : false}
                                            // loader={<div >Loading ...</div>}
                                            useWindow={true}
                                            loadMore={this.loadFunc}

                                        >
                                            {
                                                sectionListData && sectionListData.length > 0 ? sectionListData.map((item, i) => (
                                                    <div key={i}>
                                                        <div className={item.status === false ? "FalseDIV" : ""}>

                                                            <div className="pattern-list-box size-list-box">

                                                                <div className="pattern-list-content ">
                                                                    <h2>{item.sectionName}</h2>
                                                                </div>
                                                                <div className="pattern-list-contentIMG">

                                                                    {item.image && item.image.length > 0 && <img src={this.state.imageStatus === "loading" ? "" : `${SERVER_URL}uploads/${item.image[0]}`} alt=""
                                                                        onLoad={this.handleImageLoaded}
                                                                        onError={this.handleImageErrored}
                                                                    />
                                                                    }

                                                                </div>
                                                                <div className="size-info-box">
                                                                    <h4>Intro Text :</h4>
                                                                    <p>{item.introText}</p>
                                                                </div>
                                                                <div className="size-info-box">
                                                                    <h4>Outro Text :</h4>
                                                                    <p>{item.outroText}</p>
                                                                </div>
                                                                <div className="pattern-action">
                                                                    <div className="edit-on-click">
                                                                        <Button className="pattern-action-button" onClick={(e) => this.sectionEdit(e, item._id)}></Button>
                                                                    </div>
                                                                    <ul>
                                                                        {/* <li>
                                                                <Button className="pattern-action-button" onClick={(e) => this.handleView(e, item._id)}>
                                                                    <i><img src="images/eye.svg" alt="" /></i> <span>VIEW</span>
                                                                </Button></li> */}
                                                                        <li>
                                                                            <Button className="pattern-action-button" onClick={(e) => this.sectionEdit(e, item._id)}>

                                                                                <i><img src="images/edit.svg" alt="" /></i> <span>EDIT</span>
                                                                            </Button>
                                                                        </li>
                                                                        <li> <Button className="pattern-action-button" onClick={(e) => this.handleduplicate(e, item)} >
                                                                            <i><img src="images/duplicate.svg" alt="" /></i> <span>DUPLICATE</span>
                                                                        </Button>  </li>
                                                                        <li>
                                                                            <Popconfirm
                                                                                title="Are you sure you want to permanently delete this?"
                                                                                onConfirm={(e) => this.handleDelete(e, item._id)}
                                                                                onCancel={this.cancel}
                                                                                okText="Yes"
                                                                                cancelText="No"
                                                                            >
                                                                                <Button className="pattern-action-button">
                                                                                    <i><img src="images/delete.svg" alt="" /></i> <span>DELETE</span>
                                                                                </Button>
                                                                            </Popconfirm>
                                                                        </li>


                                                                        <li className="standout-btn"> <Button className="pattern-action-button" onClick={(e) => this.handleChart(e, item._id)} >
                                                                            <i><img src="images/size.svg" alt="" /></i> <span>VIEW CHART</span>
                                                                        </Button>  </li>
                                                                    </ul>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                ))
                                                    :
                                                    <div className="white-box emply-data-message">
                                                        {!loading && <p>Hi there! <span>Sadly there are no sections yet.</span> Feel free to click on the button in the top right corner to add one</p>
                                                        } </div>
                                            }
                                        </InfiniteScroll>
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </Layout >
        )
    }

}

const mapStateToProps = ((state) => {
    let { loading, data, sectionData, error } = state.sectionList
    return {
        loading,
        data,
        sectionData,
        error
    }
})

export default connect(mapStateToProps)(ListSection);
