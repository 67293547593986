import { SERVER_URL } from "../../api";
import axios from 'axios'
import { getHeaderWithToken } from "../../helpers/func";
export const SIZE_LIST_REQUEST = 'SIZE_LIST_REQUEST';
export const SIZE_LIST_SUCCESS = 'SIZE_LIST_SUCCESS';
export const SIZE_LIST_ERROR = 'SIZE_LIST_ERROR';
export const RESET_SIZE_LIST = 'RESET_SIZE_LIST';


export const sizeList = (data) => {
    let start = data && data.start;
    let length = data && data.length;

    let headers = getHeaderWithToken();
    return dispatch => {
        return new Promise(function (resolve, reject) {
            dispatch({
                type: SIZE_LIST_REQUEST
            })
            axios({
                method: 'GET',
                url: `${SERVER_URL}v1/patterns/${data.id}/sizess`,
                headers: headers,
                params: { start: start, length: length, search: data.search ? data.search : '' }
            }).then(function (response) {
                dispatch({
                    type: SIZE_LIST_SUCCESS,
                    payload: response
                })
                resolve(response);
            })
                .catch(function (error) {
                    dispatch({
                        type: SIZE_LIST_ERROR,
                        payload: error
                    })
                    reject(error)
                })
        })
    }
}




