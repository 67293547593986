import { GET_COLORS_REQUEST, GET_COLORS_SUCCESS, GET_COLORS_ERROR, RESET_GET_COLORS } from "../actions/getColors"

let initialState = {
    loading: false,
    data: null,
    error: null
}

const getColors = (state = initialState, action) => {
    switch (action.type) {
        case GET_COLORS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case GET_COLORS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            }
        case GET_COLORS_ERROR:
            return {
                ...state,
                loading: false,
                data: action.payload
            }
        case RESET_GET_COLORS:
            return {
                ...state,
                loading: false,
                data: null,
                error: null
            }

        default:
            return state;
    }
}

export default getColors