import React, { Component } from 'react'
import { connect } from 'react-redux';
import { SERVER_URL } from '../api';
import { Checkbox, Popover } from 'antd';


class Stitches extends Component {

    handleCheckboxChange = (e, val) => {
        e.preventDefault();
        let { stitchData } = this.props
        const newStitch = stitchData !== val ? val : "";

        this.setState({ stitchValue: newStitch },
            () => this.props.eStitchData(e, { stitchData: newStitch })
        )
    }

    render() {
        const { data, stitchData } = this.props;
        return (
            <div>
                <h2>Compound Stitches</h2>
                <div className="grid-box compound-grid">
                    <ul className="d-flex">
                        {data && data.data &&
                            data.data.compound_stiches && data.data.compound_stiches.map((value, i) => (
                                <li key={`compound-stitch-${i}`}>
                                    <Checkbox checked={stitchData && value._id === stitchData._id ? true : false} defaultChecked={false} onChange={(e) => this.handleCheckboxChange(e, value)}>
                                        <Popover mouseEnterDelay={1.5} trigger="hover" content={<div><h3>{value.name}</h3></div>} >
                                            <img src={`${SERVER_URL}stitches/${value.image}`} alt="" />
                                        </Popover>
                                    </Checkbox>
                                </li>
                            ))}
                    </ul>
                </div>

            </div>


        )
    }
}
const mapStateToProps = ((state) => {
    let { loading, data, error } = state.getStiches
    return {
        loading,
        data,
        error
    }
})

export default connect(mapStateToProps)(Stitches);