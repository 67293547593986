import { ADD_PATTERN_REQUEST, ADD_PATTERN_SUCCESS, ADD_PATTERN_ERROR, RESET_ADD_PATTERN } from "../actions/addPattern"

let initialState = {
    loading: false,
    data: null,
    error: null
}

const addPattern = (state = initialState, action) => {
    switch (action.type) {
        case ADD_PATTERN_REQUEST:
            return {
                ...state,
                loading: true
            }

        case ADD_PATTERN_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            }
        case ADD_PATTERN_ERROR:
            return {
                ...state,
                loading: false,
                data: action.payload
            }
        case RESET_ADD_PATTERN:
            return {
                ...state,
                loading: false,
                data: null,
                error: null
            }

        default:
            return state;
    }
}

export default addPattern