import { combineReducers } from 'redux';
import login from './login';
import forget_password from './forget_password';
import reset_password from './reset_password';
import addPattern from './addPattern';
import addSize from './addSize';
import patternList from './patternList';
import sizeList from './sizeList';
import sectionList from './sectionList';
import addSection from './addSection';
import viewPattern from './viewPattern';
import viewUser from './viewUser';
import viewSize from './viewSize';
import viewSection from './viewSection';
import viewStitch from './viewStitch';
import getNeedles from './needles';
import getSize from './size';
import deleteImage from './deleteImage';
import deleteSchematic from './deleteSchematic';
import deleteColor from './deleteColor';
import deleteStitchImage from './deleteStitchImage';
import search from './search';
import getStiches from './stitches';
import addChart from './addChart';
import addStitch from './addStitch';
import addUser from './addUser';
import getChart from './getChart';
import getStitch from './stitch';
import getColors from './getColors';
import getUser from './user';
import createGridArray from './createGridArray';

export default combineReducers({
    login,
    forget_password,
    getColors,
    reset_password,
    deleteColor,
    deleteSchematic,
    addPattern,
    patternList,
    addUser,
    sizeList,
    deleteStitchImage,
    sectionList,
    addSection,
    addSize,
    getStitch,
    getUser,
    getSize,
    viewPattern,
    viewUser,
    viewSize,
    viewSection,
    viewStitch,
    getNeedles,
    getStiches,
    deleteImage,
    search,
    addChart,
    getChart,
    addStitch,
    createGridArray
});