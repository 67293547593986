import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { adminRoutes, ADMIN_BASE_URL } from '../helpers/adminRoutes';
import { resetLogin } from "../redux/actions/login";
import { AutoComplete } from 'antd';
import { Menu, Dropdown, Icon } from 'antd';
import { search } from '../redux/actions/search';

const Option = AutoComplete.Option;
const OptGroup = AutoComplete.OptGroup;

function renderChildren(group, disp_title) {
    if (group && group.length === 0) {
        return (
            <Option key="No Record">No {disp_title} found</Option>
        )
    }
    let child = group && group.length > 0 && group.map((data, i) => {
        return (
            <Option value={JSON.stringify(data)} key={i}>
                {data.patternName || data.sizeName || data.sectionName || data.name}
            </Option>
        )
    })
    return child;
}

class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            login: false,
            userData: null,
            dataOptions: []
        };
    }

    handleLogout = () => {
        localStorage.removeItem("adminData");
        this.props.dispatch(resetLogin());
        this.props.history.push(ADMIN_BASE_URL)
    };

    closeSider = (e) => {
        e.preventDefault();
        document.body.classList.remove('openSider')
    }
    componentDidMount() {
        let userData = JSON.parse(localStorage.getItem('adminData'));
        this.setState({ userData: userData })
    }

    // componentDidUpdate() {
    //     this.searchData();
    // }

    handleClick = e => {
    };

    searchData = (data) => {

        let { dispatch } = this.props
        dispatch(search({ search: data })).then(res => {
            console.log('res.data.resp => ', res.data.resp);

            this.setState({ dataOptions: res.data.resp })
        })
    }

    handleSearchSelect = (val, selectValue) => {
        var selectedData = JSON.parse(selectValue.props.value);
        console.log('selectedData => ', selectedData);

        if (selectedData.sectionName) {
            // this.props.history.push(adminRoutes.VIEWSECTION + "/" + selectedData._id)
            this.props.history.push(adminRoutes.EDITSECTION + "/" + selectedData.patternId + "/" + selectedData.sizeId + "/" + selectedData._id)
        }
        if (selectedData.patternName) {
            //  this.props.history.push(adminRoutes.VIEWPATTERN + "/" + selectedData._id)
            this.props.history.push(adminRoutes.EDITPATTERN + "/" + selectedData._id)

        }
        if (selectedData.sizeName) {
            // this.props.history.push(adminRoutes.VIEWSIZE + "/" + selectedData._id)
            this.props.history.push(adminRoutes.EDITSIZE + "/" + selectedData.patternId + "/" + selectedData._id)
        }
        if (selectedData.name && selectedData.role) {
            // this.props.history.push(adminRoutes.VIEWSIZE + "/" + selectedData._id)
            this.props.history.push(adminRoutes.EDITUSER + "/" + selectedData._id)
        }
        if (selectedData.name && selectedData.height && selectedData.width) {
            // this.props.history.push(adminRoutes.VIEWSIZE + "/" + selectedData._id)
            this.props.history.push(adminRoutes.EDITSTITCH + "/" + selectedData._id)
        }

    }

    render() {
        let { userData, dataOptions } = this.state;
        const children = dataOptions.map(data =>
            <OptGroup
                key={data.title}
            >
                {renderChildren(data.children, data.disp_title)}
            </OptGroup>
        );


        const menu = (
            <Menu>
                <Menu.Item key="0">
                    <Link to="" onClick={this.handleLogout} className="nav-link">
                        Logout
                </Link>
                </Menu.Item>
            </Menu>
        );
        return (
            <div className="header-top">

                <div className="d-flex">
                    <div className="header-search" >


                        <AutoComplete style={{ width: 200 }}
                            onSelect={this.handleSearchSelect}
                            onSearch={this.searchData} placeholder="Search">
                            {children}
                        </AutoComplete>
                    </div>

                    <div className="header-dropdown">
                        <Dropdown overlay={menu} trigger={['click']}>

                            <div className="ant-dropdown-link">
                                {userData && userData.data && userData.data.name}

                                <Icon type="down" />

                            </div>

                        </Dropdown>
                    </div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = ((state) => {
    let { loading, data, error } = state.login
    return {
        loading,
        data,
        error,
        searchLoading: state.search.loading,
        searchData: state.search.data,
        searchError: state.search.error
    }
})
export default withRouter(connect(mapStateToProps)(Header));