import React, { Component } from 'react'
import Layout from './Layout';
import { withRouter } from 'react-router-dom';
import { Form, Icon, Input, Button, message, Switch, Breadcrumb, Select } from 'antd';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { adminRoutes } from '../helpers/adminRoutes';
import { addSize, resetAddSizeDatas } from '../redux/actions/addSize';
import { viewSize, resetViewSize } from '../redux/actions/viewSize';
import { viewPatternBreadcrumb } from '../redux/actions/sizeBreadcrumb';
import { Beforeunload } from 'react-beforeunload';

import { sizeList } from '../redux/actions/sizeList';

import { getNeedles } from '../redux/actions/needles';
import { Link } from 'react-router-dom';
import TextArea from 'antd/lib/input/TextArea';


const { Option } = Select;

class AddSizeData extends Component {
    constructor(props) {
        super(props)
        this.state = {
            copy: false,
            validted: true,
            totalRecords: 0,
            gaugeValue: '',
            edit: false,
            status: false,
            id: null,
            patternName: null,
            defaulState: false,
            patternId: null,
            sizeId: null,
            needle1: 'Us 1 (2.25 mm)',
            idLabel: 1,
            idYarn: 1,
            idNeedle: 1
        }

    }


    removeError() {
        this.setState({ validted: true })

    }

    remove = k => {
        const { form } = this.props;
        const keys = form.getFieldValue('keys');
        if (keys.length === 1) {
            return;
        }

        form.setFieldsValue({
            keys: keys.filter(key => key !== k),
        });
    };
    removeYarn = k => {
        const { form } = this.props;
        const keys = form.getFieldValue('yarnkeys');
        if (keys.length === 1) {
            return;
        }

        form.setFieldsValue({
            yarnkeys: keys.filter(key => key !== k),
        });
    };

    removecopyYarn = k => {
        const { form } = this.props;
        const keys = form.getFieldValue('yarnkeys');
        if (keys.length === 1) {
            return;
        }

        form.setFieldsValue({
            yarnkeys: keys.filter(key => key !== k),
        });
    };

    componentWillUnmount() {
        this.setState({ edit: false, id: null });
        let { dispatch } = this.props;
        dispatch(resetViewSize())
        let val = this.props.form.isFieldsTouched();
        if (val === true) {
        } else {
        }
    }
    removeNeedle = k => {

        const { form } = this.props;
        const keys = form.getFieldValue('needlekeys');
        if (keys.length === 1) {
            return;
        }

        form.setFieldsValue({
            needlekeys: keys.filter(key => key !== k),
        });
    };

    add = (e) => {
        const { form } = this.props;
        const keys = form.getFieldValue('keys');
        let { idLabel } = this.state
        let id = idLabel;
        const nextKeys = keys.concat(id++);
        this.setState({ idLabel: id })
        form.setFieldsValue({
            keys: nextKeys,
        });
    };

    addyarn = (e, copy) => {
        const { form } = this.props;
        let { idYarn } = this.state
        let id = idYarn;
        const yarnkeys = form.getFieldValue('yarnkeys');
        const nextyarnKeys = yarnkeys.concat(id++);
        this.setState({ idYarn: id })
        form.setFieldsValue({
            yarnkeys: nextyarnKeys,
        });
        if (copy !== undefined) {

            this.setState({ copy: true })
        }
        else {

            this.setState({ copy: false })
        }
    };

    addneedle = (e) => {
        const { form } = this.props;
        let { idNeedle, edit } = this.state
        console.log('edit => ', edit);

        let id = idNeedle;
        const needlekeys = form.getFieldValue('needlekeys');
        const nextneedleKeys = needlekeys.concat(id++);
        this.setState({ idNeedle: id })
        form.setFieldsValue({
            needlekeys: nextneedleKeys,
        });
    };


    handleSubmit = (e, id, type) => {
        e.preventDefault()
        let { dispatch, history } = this.props;
        let { edit, patternId, sizeId } = this.state;
        this.setState({ defaulState: true })

        this.props.form.validateFields((err, values) => {
            console.log('values => ', values);

            if (err) {
                this.setState({ validted: false })
            }

            if (edit === false) {
                if (err && values.sizeName == null) {
                    if (type && type === "save")
                        history.push(adminRoutes.SIZELIST + '/' + patternId);

                }
                if (!err && values.sizeName != null) {

                    if (type && type === "save") {
                        dispatch(addSize({ ...values, "patternId": patternId })).then(res => {
                            history.push(adminRoutes.SIZELIST + '/' + patternId);
                        })
                    }
                    else {
                        dispatch(addSize({ ...values, "patternId": patternId })).then(res => {
                            history.push(adminRoutes.ADDSECTION + '/' + res.data.data.patternId + '/' + res.data.data._id);
                        })
                    }

                }

            }

            else {

                if (!err && values.sizeName != null) {
                    if (type && type === "editNext") {
                        dispatch(addSize({ ...values, "patternId": patternId, "id": sizeId }, edit)).then(res => {
                            if (res && res.status === 200) {
                                history.push(adminRoutes.SECTIONLIST + '/' + patternId + '/' + sizeId);
                                dispatch(resetAddSizeDatas())
                            }


                        })
                    }


                    else {
                        if (type && type === "edit") {
                            dispatch(addSize({ ...values, "patternId": patternId, "id": sizeId }, edit)).then(res => {
                                if (res && res.status === 200) {
                                    history.push(adminRoutes.SIZELIST + '/' + patternId);
                                    dispatch(resetAddSizeDatas())
                                }

                            })
                        }
                    }
                }
            }

        });
    };

    componentDidUpdate(prevProps) {

        let { dispatch, location, error, history, editData, } = this.props;
        let path = location.pathname.split('/')
        let patternId = path[2];
        let sizeId = path[3];

        if (location && prevProps.location !== location) {
            dispatch(getNeedles()).then(response => {

            });
            dispatch(viewPatternBreadcrumb(patternId)).then(response => {
                this.setState({ "patternName": response.data[0] && response.data[0].patternName, "status": response.data[0] && response.data[0].status })
            });
            if (sizeId) {
                this.setState({ edit: true, sizeId: sizeId })
                dispatch(viewSize(sizeId)).then(res => {
                    if (res && res.status === 200) {
                        if (res.data && res.data.data && res.data.data.measurement && res.data.data.measurement.length > 0)
                            this.setState({
                                idLabel: res.data.data.measurement.length,
                                measurements: res && res.data && res.data.data && res.data.data.measurement
                            })

                        if (res.data && res.data && res.data.data.yarn.length > 0)
                            this.setState({
                                idYarn: res.data.data.yarn.length,
                                DbYarns: res.data.data.yarn
                            })

                        if (res.data && res.data && res.data.data.suggesstedNeedle.length > 0)
                            this.setState({
                                idNeedle: res.data.data.suggesstedNeedle.length,
                                dbNeedle: res.data.data.suggesstedNeedle
                            })

                    }
                })
            } else {
                this.add();
                this.addyarn();
                this.addneedle();
            }
            this.setState({ patternId: patternId })

            if (error && error.status === 400) {
                message.error(error.data.message)
                history.push(adminRoutes.AddSize)
            }

            if (editData && editData.data && editData.data.data && editData.data.data.measurement.length > 0) {
                this.setState({ idlabel: editData.data.data.measurement.length })
            }
        }

    }



    componentDidMount() {
        window.scrollTo(0, 0);

        let { dispatch, location, error, history, editData, } = this.props;
        let path = location.pathname.split('/')
        let patternId = path[2];
        let sizeId = path[3];
        dispatch(getNeedles()).then(response => {

        });
        let data = {
            start: 0,
            length: 1000000,

        }
        dispatch(sizeList({ id: patternId, ...data })).then(response => {
            console.log('response => ', response.data);

            if (response && response.data && response.data && response.data.recordsTotal) {
                this.setState({ totalRecords: response.data.recordsTotal })
            }
        })
        dispatch(viewPatternBreadcrumb(patternId)).then(response => {
            this.setState({ "patternName": response.data[0] && response.data[0].patternName, "status": response.data[0] && response.data[0].status })
        });

        if (sizeId) {
            this.setState({ edit: true, sizeId: sizeId })
            dispatch(viewSize(sizeId)).then(res => {
                if (res && res.status === 200) {
                    if (res.data && res.data.data && res.data.data.measurement && res.data.data.measurement.length > 0)
                        this.setState({
                            idLabel: res.data.data.measurement.length,
                            measurements: res && res.data && res.data.data && res.data.data.measurement
                        })

                    if (res.data && res.data && res.data.data.yarn.length > 0)
                        this.setState({
                            idYarn: res.data.data.yarn.length,
                            DbYarns: res.data.data.yarn
                        })
                    if (res.data && res.data && res.data.data.suggesstedNeedle.length > 0)
                        this.setState({
                            idNeedle: res.data.data.suggesstedNeedle.length,
                            dbNeedle: res.data.data.suggesstedNeedle
                        })
                }
            })
        } else {
            this.add();
            this.addyarn();
            this.addneedle();
        }
        this.setState({ patternId: patternId })

        if (error && error.status === 400) {
            message.error(error.data.message)
            history.push(adminRoutes.AddSize)
        }

        if (editData && editData.data && editData.data.data && editData.data.data.measurement.length > 0) {
            this.setState({ idlabel: editData.data.data.measurement.length })
        }

    }

    handleChange = (value) => {
        this.setState({ needles1: value })
    }

    removeFromDb = (i) => {
        var array = this.state.measurements;
        array.splice(i, 1);
        this.setState({ measurements: array, idLabel: array.length })
    }
    removeYarnFromDb = (i) => {
        var array = this.state.DbYarns;
        array.splice(i, 1);
        this.setState({ DbYarns: array, idYarn: array.length })
    }
    removeNeedleFromDb = (i) => {
        var array = this.state.dbNeedle;
        array.splice(i, 1);
        this.setState({ dbNeedle: array, idNeedle: array.length })
    }

    addanotherYarnOnEnter = (e) => {
        // e.preventDefault();
        if (e.which === 13) {
            this.addyarn();
        }
    }
    addanotherMeasurementOnEnter = (e) => {
        // e.preventDefault();
        if (e.which === 13) {
            this.add();
        }
    }
    optionValues(number) {
        let arr = [];
        let { edit } = this.state;
        if (number && number > 0) {
            if (edit === true) {

                for (let i = 1; i <= number; i++) {
                    arr.push(<Option value={i}>{i}</Option>)
                }
            }
            else {
                for (let i = 1; i <= number + 1; i++) {
                    arr.push(<Option value={i}>{i}</Option>)
                }
            }
            return arr;
        }
    }
    render() {
        const { getFieldDecorator, getFieldValue, getFieldsValue, } = this.props.form;
        let { location, editData, needleData, prevAddData } = this.props;
        let { totalRecords } = this.state;
        let path = location.pathname.split('/')
        let id = path[2];
        let { edit, defaulState, patternId, measurements, DbYarns, dbNeedle, patternName, copy } = this.state;

        getFieldDecorator('keys', {
            initialValue: [
                // "yarn_name":getFieldsValue.yarn[0]
            ]
        });
        const keys = getFieldValue('keys');
        let formValues = getFieldsValue()
        getFieldDecorator('yarnkeys', {
            initialValue: [

            ]
        });
        const yarnKeys = getFieldValue('yarnkeys');
        getFieldDecorator('needlekeys', { initialValue: [] });
        const needlekeys = getFieldValue('needlekeys');

        const formItems = keys.map((k, index) => (
            <li className="double-input d-flex" key={k}>
                {keys.length > 1 ? (
                    <Icon
                        className="dynamic-delete-button"
                        type="minus-circle-o"
                        onClick={() => this.remove(k)}
                    />

                ) : null}
                <Form.Item>

                    {getFieldDecorator(`measurement[${k}].label_name`, {

                    })(
                        <Input
                            // prefix={<Icon />}
                            type="text"
                            placeholder="Label name, i.e. Bust"
                        />,
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator(`measurement[${k}].label_measurement`, {

                    })(
                        <Input
                            // prefix={<Icon />}
                            type="text"
                            placeholder={`Measurement, i.e 36"`}
                            onKeyDown={(e) => this.addanotherMeasurementOnEnter(e)}
                        />,
                    )}

                </Form.Item>

            </li>
        ))



        const yarnItems = yarnKeys.map((k, index) => (
            <div key={index} className="d-flex">
                <ul className="d-flex inner-ul yarn-filled">
                    <li><Form.Item
                        // label={index === 0 ? 'Yarn' : ''}
                        required={false}
                    >
                        {getFieldDecorator(`yarn[${k}].yarn_name`, {
                            validateTrigger: ['onChange', 'onBlur'],
                            initialValue: `${copy === true && formValues.yarn && formValues.yarn.length > 0 && formValues.yarn[k - 1] && formValues.yarn[k - 1].yarn_name ? formValues.yarn[k - 1].yarn_name : ""}`
                        })(<Input placeholder="Yarn Name" />)}
                    </Form.Item></li>
                    <li><Form.Item
                        required={false}
                    >
                        {getFieldDecorator(`yarn[${k}].yarn_weight`, {
                            validateTrigger: ['onChange', 'onBlur'],
                            initialValue: `${copy === true && formValues.yarn && formValues.yarn.length > 0 && formValues.yarn[k - 1] && formValues.yarn[k - 1].yarn_weight ? formValues.yarn[k - 1].yarn_weight : ""}`


                        })(<Input placeholder="Yarn Weight" />)}
                    </Form.Item></li>
                    <li>
                        <Form.Item
                            required={false}
                        >
                            {getFieldDecorator(`yarn[${k}].fiber_content`, {
                                validateTrigger: ['onChange', 'onBlur'],
                                initialValue: `${copy === true && formValues.yarn && formValues.yarn.length > 0 && formValues.yarn[k - 1] && formValues.yarn[k - 1].fiber_content ? formValues.yarn[k - 1].fiber_content : ""}`

                            })(<Input placeholder="Fiber Content" />)}
                        </Form.Item>
                    </li>
                    <li>
                        <Form.Item
                            required={false}
                        >
                            {getFieldDecorator(`yarn[${k}].length_density`, {
                                validateTrigger: ['onChange', 'onBlur'],
                                initialValue: `${copy === true && formValues.yarn && formValues.yarn.length > 0 && formValues.yarn[k - 1] && formValues.yarn[k - 1].length_density ? formValues.yarn[k - 1].length_density : ""}`

                            })(<Input placeholder="Length per weight" />)}

                        </Form.Item>
                    </li>
                    <li>
                        <Form.Item
                            required={false}

                        >
                            {getFieldDecorator(`yarn[${k}].yardage_used`, {
                                validateTrigger: ['onChange', 'onBlur'],
                                initialValue: `${copy === true && formValues.yarn && formValues.yarn.length > 0 && formValues.yarn[k - 1] && formValues.yarn[k - 1].yardage_used ? formValues.yarn[k - 1].yardage_used : ""}`

                            })(<Input placeholder="Yardage Used" />)}

                        </Form.Item>
                    </li>
                    <li>
                        <Form.Item
                            required={false}

                        >
                            {getFieldDecorator(`yarn[${k}].colorway`, {
                                validateTrigger: ['onChange', 'onBlur'],
                                initialValue: `${copy === true && formValues.yarn && formValues.yarn.length > 0 && formValues.yarn[k - 1] && formValues.yarn[k - 1].colorway ? formValues.yarn[k - 1].colorway : ""}`

                            })(<Input onKeyDown={(e) => this.addanotherYarnOnEnter(e)} placeholder="Colorway #1" />)}

                        </Form.Item>
                    </li>
                </ul>
                {copy === true}
                {yarnKeys.length > 1 ? (
                    copy === true ? <Icon
                        className="dynamic-delete-button"
                        type="minus-circle-o"
                        onClick={() => this.removecopyYarn(k)}
                    />
                        : <Icon
                            className="dynamic-delete-button"
                            type="minus-circle-o"
                            onClick={() => this.removeYarn(k)}
                        />
                ) : null}
            </div>
        ))



        const neddleItems = needlekeys.map((k, index) => (
            <div className="suggested-needles-box" key={index}>
                <label>Suggested Needles</label>
                <div className="d-flex">
                    <ul className="three-li neddles_li d-flex">
                        <li>
                            <Form.Item>
                                <div className="CustomeSelect">
                                    {getFieldDecorator(`suggesstedNeedle[${k}].size`, {

                                    })(
                                        <Select onChange={this.handleChange} placeholder="Size" initialValue="size">

                                            {needleData && needleData.data &&

                                                needleData.data.size.map((value, index) => (
                                                    <Option key={index} value={value.name}>{value.name}</Option>
                                                ))
                                            }

                                        </Select>
                                    )}

                                </div>
                            </Form.Item>
                        </li>
                        <li>
                            <Form.Item>
                                <div className="CustomeSelect">
                                    {getFieldDecorator(`suggesstedNeedle[${k}].type`, {

                                    })(
                                        <Select onChange={this.handleChange} placeholder="Type" initialValue="type">


                                            {needleData && needleData.data &&

                                                needleData.data.type.map((value, index) => (
                                                    <Option key={index} value={value.name}>{value.name}</Option>
                                                ))
                                            }

                                        </Select>
                                    )}

                                </div>
                            </Form.Item>
                        </li>
                        <li>
                            <Form.Item>
                                <div className="CustomeSelect">
                                    {getFieldDecorator(`suggesstedNeedle[${k}].length`, {

                                    })(
                                        <Select onChange={this.handleChange} placeholder="Length" initialValue="length">


                                            {needleData && needleData.data &&

                                                needleData.data.lenght.map((value, index) => (
                                                    <Option key={index} value={value.name}>{value.name} </Option>
                                                ))
                                            }

                                        </Select>
                                    )}
                                </div>
                            </Form.Item>
                        </li>
                    </ul>
                    {needlekeys.length > 1 ? (
                        <Icon
                            className="dynamic-delete-button"
                            type="minus-circle-o"
                            onClick={() => this.removeNeedle(k)}
                        />
                    ) : null}
                </div>
            </div>
        ))


        return (

            <div>
                {this.props.form.isFieldsTouched() === true && defaulState === false && <Beforeunload
                    onBeforeunload={() => "You have unsaved changes, are you sure you want to leave?"} />}
                <Prompt
                    when={this.props.form.isFieldsTouched() === true && defaulState === false}
                    message='You have unsaved changes, are you sure you want to leave?'
                />
                <Layout>
                    {/* <div className="header-top">

                        <div className="d-flex">
                            <div className="header-search">
                                <Search
                                    placeholder="Search"
                                    onSearch={value => this.loadFunc(1, 'search', value)}
                                    style={{ width: 200 }}
                                />
                            </div>
                        </div>
                    </div> */}
                    <div className="bredcrumb-div">
                        <Breadcrumb separator="-">
                            <Breadcrumb.Item><Link to={adminRoutes.PATTERNLIST}> {editData && editData.pattern && editData.pattern.patternId.patternName ? editData && editData.pattern && editData.pattern.patternId.patternName : patternName}</Link ></Breadcrumb.Item>
                            <Breadcrumb.Item><Link to={adminRoutes.SIZELIST + "/" + patternId}> {editData && editData.pattern && editData.pattern.sizeName ? editData && editData.pattern && editData.pattern.sizeName : editData && editData.pattern && editData.pattern.sizeName ? "Size" : "Size"}</Link></Breadcrumb.Item>
                            {/* <Breadcrumb.Item><Link to={adminRoutes.PATTERNLIST }>{edit === true ? editData && editData.pattern && editData.pattern.patternId.patternName : patternName}</Link ></Breadcrumb.Item> */}
                            {/* <Breadcrumb.Item><Link to={adminRoutes.SIZELIST + "/" + patternId}>{edit === true ? editData && editData.pattern && editData.pattern.sizeName : "Size"}</Link></Breadcrumb.Item> */}
                        </Breadcrumb>
                    </div>
                    <div className="page-title d-flex">
                        <h2>{edit === true ? "Edit Size" : "Add Size"}</h2>
                    </div>
                    <div className="white-box">
                        <Form layout="horizontal" onSubmit={(e) => this.handleSubmit(e, id)} style={{ width: "50 %" }}>
                            <ul className="filled-ul">
                                <li className="half-width">
                                    <Form.Item >
                                        <label>Size Name</label>
                                        {getFieldDecorator('sizeName', {
                                            rules: [{ required: true, message: 'Please input your size name!' }],
                                            initialValue: editData && editData.data && editData.data.sizeName ? editData.data.sizeName :
                                                prevAddData && prevAddData.data && prevAddData.data.data.sizeName ?
                                                    prevAddData && prevAddData.data && prevAddData.data.data.sizeName : ""
                                        })(
                                            <Input
                                                // prefix={<Icon />}
                                                type="text"
                                                onFocus={() => this.removeError()}
                                                placeholder="Size Name"
                                            />,
                                        )}
                                    </Form.Item>
                                </li>

                                <li>
                                    <label>Finished Measurements</label>

                                    <div className="another-label">
                                        <ul className="d-flex inner-ul multicopy-li">
                                            {/* {edit === true ? editData && editData.data && editData.data.measurement.map((value, index) => ( */}
                                            {edit === true ? measurements && measurements.length > 0 && measurements.map((value, index) => (
                                                <li className="double-input d-flex" key={index}>
                                                    <Form.Item >
                                                        {getFieldDecorator(`measurement[${index}].label_name`, {

                                                            initialValue: value && value.label_name
                                                        })(
                                                            <Input
                                                                // prefix={<Icon />}
                                                                type="text"
                                                                placeholder="Label name, i.e. Bust"
                                                            />,
                                                        )}
                                                    </Form.Item>
                                                    <Form.Item >
                                                        {getFieldDecorator(`measurement[${index}].label_measurement`, {

                                                            initialValue: value && value.label_measurement
                                                        })(
                                                            <Input
                                                                // prefix={<Icon />}
                                                                type="text"
                                                                placeholder="Measurement, i.e 36"
                                                            />,
                                                        )}
                                                    </Form.Item>
                                                    <i key={index}>
                                                        <Icon
                                                            className="dynamic-delete-button"
                                                            type="minus-circle-o"
                                                            onClick={() => this.removeFromDb(index)}
                                                        />
                                                    </i>
                                                </li>
                                            )) :
                                                prevAddData && prevAddData.data && prevAddData.data.data.measurement ?
                                                    prevAddData && prevAddData.data && prevAddData.data.data.measurement.map((value, index) => (
                                                        <li className="double-input d-flex" key={index}>
                                                            <Form.Item >
                                                                {getFieldDecorator(`measurement[${index}].label_name`, {

                                                                    initialValue: value && value.label_name
                                                                })(
                                                                    <Input
                                                                        // prefix={<Icon />}
                                                                        type="text"
                                                                        placeholder="Label name, i.e. Bust"
                                                                    />,
                                                                )}
                                                            </Form.Item>
                                                            <Form.Item >
                                                                {getFieldDecorator(`measurement[${index}].label_measurement`, {

                                                                    initialValue: value && value.label_measurement
                                                                })(
                                                                    <Input
                                                                        // prefix={<Icon />}
                                                                        type="text"
                                                                        placeholder="Measurement, i.e 36"
                                                                    />,
                                                                )}
                                                            </Form.Item>
                                                        </li>
                                                    )) : ""
                                            }

                                            {formItems}

                                            <li>
                                                <div className="add-another">
                                                    <Form.Item>
                                                        <Button type="dashed" onClick={this.add}>
                                                            <Icon type="plus" />
                                                            <span>Add another label</span>
                                                        </Button>
                                                    </Form.Item>
                                                    {/* {keys && keys.length >= 2 && < Form.Item >
                                                        <Button type="dashed" onClick={this.remove}>
                                                            <Icon type="minus" />
                                                            <span>Remove label</span>
                                                        </Button>
                                                    </Form.Item>} */}
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <div className="yarn-filled">
                                        <label>Yarn</label>

                                        {edit === true ? DbYarns && DbYarns.length > 0 && DbYarns.map((value, index) => (
                                            <div className="yarn-filledBox">
                                                <ul className="d-flex inner-ul" key={index}>
                                                    <li><Form.Item
                                                        required={false}
                                                    >
                                                        {getFieldDecorator(`yarn[${index}].yarn_name`, {
                                                            validateTrigger: ['onChange', 'onBlur'],

                                                            initialValue: value && value.yarn_name ? value.yarn_name : ""
                                                        })(<Input placeholder="Yarn Name" />)}

                                                    </Form.Item></li>
                                                    <li><Form.Item

                                                        required={false}

                                                    >
                                                        {getFieldDecorator(`yarn[${index}].yarn_weight`, {
                                                            validateTrigger: ['onChange', 'onBlur'],


                                                            initialValue: value && value.yarn_weight ? value.yarn_weight : ""
                                                        })(<Input placeholder="Yarn Weight" />)}

                                                    </Form.Item></li>
                                                    <li>
                                                        <Form.Item
                                                            required={false}

                                                        >
                                                            {getFieldDecorator(`yarn[${index}].fiber_content`, {
                                                                validateTrigger: ['onChange', 'onBlur'],
                                                                rules: [
                                                                    {
                                                                        whitespace: true,
                                                                        message: "Please input fiber content ",
                                                                    },
                                                                ],
                                                                initialValue: value && value.fiber_content ? value.fiber_content : ""
                                                            })(<Input placeholder="Fiber Content" />)}

                                                        </Form.Item>
                                                    </li>
                                                    <li>
                                                        <Form.Item

                                                            required={false}

                                                        >
                                                            {getFieldDecorator(`yarn[${index}].length_density`, {
                                                                validateTrigger: ['onChange', 'onBlur'],


                                                                initialValue: value && value.length_density ? value.length_density : ""
                                                            })(<Input placeholder="Length per weight" />)}

                                                        </Form.Item>
                                                    </li>
                                                    <li>
                                                        <Form.Item
                                                            required={false}

                                                        >
                                                            {getFieldDecorator(`yarn[${index}].yardage_used`, {
                                                                validateTrigger: ['onChange', 'onBlur'],


                                                                initialValue: value && value.yardage_used ? value.yardage_used : ""
                                                            })(<Input placeholder="Yardage Used" />)}

                                                        </Form.Item>
                                                    </li>
                                                    <li>
                                                        <Form.Item
                                                            required={false}

                                                        >
                                                            {getFieldDecorator(`yarn[${index}].colorway`, {
                                                                validateTrigger: ['onChange', 'onBlur'],

                                                                initialValue: value && value.colorway ? value.colorway : ""
                                                            })(<Input onKeyDown={this.addanotherYarnOnEnter} placeholder="Colorway #1" />)}

                                                        </Form.Item>
                                                    </li>
                                                </ul>

                                                <i key={index}>
                                                    <Icon
                                                        className="dynamic-delete-button"
                                                        type="minus-circle-o"
                                                        onClick={() => this.removeYarnFromDb(index)}
                                                    />
                                                </i>
                                            </div>
                                        )) :
                                            prevAddData && prevAddData.data && prevAddData.data.data.yarn ?
                                                prevAddData && prevAddData.data && prevAddData.data.data.yarn.map((value, index) => (
                                                    <ul className="d-flex inner-ul">
                                                        <li><Form.Item
                                                            required={false}
                                                        >
                                                            {getFieldDecorator(`yarn[${index}].yarn_name`, {
                                                                validateTrigger: ['onChange', 'onBlur'],

                                                                initialValue: value && value.yarn_name ? value.yarn_name : ""
                                                            })(<Input placeholder="Yarn Name" />)}

                                                        </Form.Item></li>
                                                        <li><Form.Item

                                                            required={false}

                                                        >
                                                            {getFieldDecorator(`yarn[${index}].yarn_weight`, {
                                                                validateTrigger: ['onChange', 'onBlur'],


                                                                initialValue: value && value.yarn_weight ? value.yarn_weight : ""
                                                            })(<Input placeholder="Yarn Weight" />)}

                                                        </Form.Item></li>
                                                        <li>
                                                            <Form.Item
                                                                required={false}

                                                            >
                                                                {getFieldDecorator(`yarn[${index}].fiber_content`, {
                                                                    validateTrigger: ['onChange', 'onBlur'],
                                                                    rules: [
                                                                        {
                                                                            whitespace: true,
                                                                            message: "Please input fiber content ",
                                                                        },
                                                                    ],
                                                                    initialValue: value && value.fiber_content ? value.fiber_content : ""
                                                                })(<Input placeholder="Fiber Content" />)}

                                                            </Form.Item>
                                                        </li>
                                                        <li>
                                                            <Form.Item

                                                                required={false}

                                                            >
                                                                {getFieldDecorator(`yarn[${index}].length_density`, {
                                                                    validateTrigger: ['onChange', 'onBlur'],


                                                                    initialValue: value && value.length_density ? value.length_density : ""
                                                                })(<Input placeholder="Length per weight" />)}

                                                            </Form.Item>
                                                        </li>
                                                        <li>
                                                            <Form.Item
                                                                required={false}

                                                            >
                                                                {getFieldDecorator(`yarn[${index}].yardage_used`, {
                                                                    validateTrigger: ['onChange', 'onBlur'],


                                                                    initialValue: value && value.yardage_used ? value.yardage_used : ""
                                                                })(<Input placeholder="Yardage Used" />)}

                                                            </Form.Item>
                                                        </li>
                                                        <li>
                                                            <Form.Item
                                                                required={false}

                                                            >
                                                                {getFieldDecorator(`yarn[${index}].colorway`, {
                                                                    validateTrigger: ['onChange', 'onBlur'],

                                                                    initialValue: value && value.colorway ? value.colorway : ""
                                                                })(<Input onKeyDown={this.addanotherYarnOnEnter} placeholder="Colorway #1" />)}

                                                            </Form.Item>
                                                        </li>
                                                    </ul>
                                                )) : ""
                                        }
                                        {yarnItems}
                                        <div className="add-another">
                                            <Form.Item>
                                                <Button type="dashed" onClick={this.addyarn}>
                                                    <Icon type="plus" />
                                                    <span>Add another yarn</span>
                                                </Button>
                                            </Form.Item>
                                        </div>
                                        <div className="add-another">
                                            <Form.Item>
                                                <Button type="dashed" onClick={(e) => this.addyarn(e, "copy")}>
                                                    <Icon type="plus" />
                                                    <span>Copy previous yarn</span>
                                                </Button>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </li>
                                <li >
                                    <div className="suggested-needles-box">
                                        {/* <label>Suggested Needles</label> */}
                                        {edit === true ?
                                            dbNeedle && dbNeedle.length > 0 && dbNeedle.map((value, index) =>
                                                (
                                                    <div className="suggested-Wrap">
                                                        <ul className="three-li neddles_li  d-flex" key={index}>
                                                            <li>
                                                                <Form.Item>
                                                                    <div className="CustomeSelect">
                                                                        {getFieldDecorator(`suggesstedNeedle[${index}].size`, {

                                                                            initialValue: value && value.size
                                                                        })(
                                                                            <Select onChange={this.handleChange} >


                                                                                {needleData && needleData.data &&

                                                                                    needleData.data.size.map((value, index) => (
                                                                                        <Option key={index} value={value.name}>{value.name}</Option>
                                                                                    ))
                                                                                }

                                                                            </Select>
                                                                        )}

                                                                    </div>
                                                                </Form.Item>
                                                            </li>
                                                            <li>
                                                                <Form.Item>
                                                                    <div className="CustomeSelect">
                                                                        {getFieldDecorator(`suggesstedNeedle[${index}].type`, {


                                                                            initialValue: value && value.type
                                                                        })(
                                                                            <Select onChange={this.handleChange} >


                                                                                {needleData && needleData.data &&

                                                                                    needleData.data.type.map((value, index) => (
                                                                                        <Option key={index} value={value.name}>{value.name}</Option>
                                                                                    ))
                                                                                }

                                                                            </Select>
                                                                        )}

                                                                    </div>
                                                                </Form.Item>
                                                            </li>
                                                            <li>
                                                                <Form.Item>
                                                                    <div className="CustomeSelect">
                                                                        {getFieldDecorator(`suggesstedNeedle[${index}].length`, {
                                                                            initialValue: value && value.length
                                                                        })(
                                                                            <Select onChange={this.handleChange} >


                                                                                {needleData && needleData.data &&

                                                                                    needleData.data.lenght.map((value, index) => (
                                                                                        <Option key={index} value={value.name}>{value.name}</Option>
                                                                                    ))
                                                                                }

                                                                            </Select>
                                                                        )}
                                                                    </div>
                                                                </Form.Item>
                                                            </li>
                                                        </ul>
                                                        <i key={index}>
                                                            <Icon
                                                                className="dynamic-delete-button"
                                                                type="minus-circle-o"
                                                                onClick={() => this.removeNeedleFromDb(index)}
                                                            />
                                                        </i>
                                                    </div>
                                                )) :
                                            prevAddData && prevAddData.data && prevAddData.data.data.suggesstedNeedle ?
                                                prevAddData && prevAddData.data && prevAddData.data.data.suggesstedNeedle.map((value, index) => (
                                                    <ul className="three-li neddles_li  d-flex">
                                                        <li>
                                                            <Form.Item>
                                                                <div className="CustomeSelect">
                                                                    {getFieldDecorator(`suggesstedNeedle[${index}].size`, {

                                                                        initialValue: value && value.size
                                                                    })(
                                                                        <Select onChange={this.handleChange} >


                                                                            {needleData && needleData.data &&

                                                                                needleData.data.size.map((value, index) => (
                                                                                    <Option value={value.name}>{value.name}</Option>
                                                                                ))
                                                                            }

                                                                        </Select>
                                                                    )}

                                                                </div>
                                                            </Form.Item>
                                                        </li>
                                                        <li>
                                                            <Form.Item>
                                                                <div className="CustomeSelect">
                                                                    {getFieldDecorator(`suggesstedNeedle[${index}].type`, {

                                                                        initialValue: value && value.type
                                                                    })(
                                                                        <Select onChange={this.handleChange} >


                                                                            {needleData && needleData.data &&

                                                                                needleData.data.type.map((value, index) => (
                                                                                    <Option value={value.name}>{value.name}</Option>
                                                                                ))
                                                                            }

                                                                        </Select>
                                                                    )}

                                                                </div>
                                                            </Form.Item>
                                                        </li>
                                                        <li>
                                                            <Form.Item>
                                                                <div className="CustomeSelect">
                                                                    {getFieldDecorator(`suggesstedNeedle[${index}].length`, {
                                                                        initialValue: value && value.length
                                                                    })(
                                                                        <Select onChange={this.handleChange} >


                                                                            {needleData && needleData.data &&

                                                                                needleData.data.lenght.map((value, index) => (
                                                                                    <Option value={value.name}>{value.name}</Option>
                                                                                ))
                                                                            }

                                                                        </Select>
                                                                    )}
                                                                </div>
                                                            </Form.Item>
                                                        </li>
                                                    </ul>
                                                )) : ""
                                        }
                                    </div>
                                </li>
                                <li>
                                    {neddleItems}
                                    <div className="add-another add-another-needle">
                                        <Form.Item>
                                            {/* <Button type="dashed" onClick={this.add}> */}
                                            <Button type="dashed" onClick={this.addneedle}>
                                                <Icon type="plus" />
                                                <span> Add another Suggested Needle</span>
                                            </Button>
                                        </Form.Item>
                                    </div>
                                </li>

                                <li className="half-width">
                                    <Form.Item >
                                        <label>Gauge</label>
                                        {getFieldDecorator('gauge', {

                                            initialValue: edit === true ? editData && editData.data && editData.data.gauge :
                                                prevAddData && prevAddData.data && prevAddData.data.data.gauge ?
                                                    prevAddData && prevAddData.data && prevAddData.data.data.gauge : ""
                                        })(
                                            <TextArea
                                                className="text-area-input"
                                                prefix={<Icon />}
                                                type="text"

                                                // value={this.state.gaugeValue}
                                                placeholder="Gauge, i.e. 20 stitches and 28 rows = 4"
                                            ></TextArea>,
                                        )}
                                    </Form.Item>
                                </li>
                                <li className="half-width">
                                    <Form.Item >
                                        <label>Bits &amp; Bobs</label>
                                        {getFieldDecorator('bitsBobs', {

                                            initialValue: edit === true ? editData && editData.data && editData.data.bitsBobs :
                                                prevAddData && prevAddData.data && prevAddData.data.data.bitsBobs ?
                                                    prevAddData && prevAddData.data && prevAddData.data.data.bitsBobs : ""
                                        })(
                                            <TextArea
                                                className="text-area-input"
                                                prefix={<Icon />}
                                                type="text"
                                                placeholder="Bits &amp; Bobs"
                                            ></TextArea>,
                                        )}
                                    </Form.Item>
                                </li>
                                <li className="half-width CustomeSelect">
                                    <Form.Item>
                                        <label>Position</label>

                                        {getFieldDecorator('position', {
                                            rules: [{ required: true, message: 'Please input position!' }],

                                            initialValue: edit === true ? editData && editData.data && editData.data.position :
                                                prevAddData && prevAddData.data && prevAddData.data.position ?
                                                    prevAddData && prevAddData.data && prevAddData.data.position : totalRecords && totalRecords === 0 ? 1 : totalRecords + 1
                                        })(
                                            <Select>
                                                {totalRecords && totalRecords > 0 ?

                                                    this.optionValues(totalRecords) :
                                                    <Option key={1}>1</Option>
                                                }
                                            </Select>
                                        )}
                                    </Form.Item>
                                </li>

                                <li className="switch-components">
                                    <Form.Item label="Publish">
                                        {getFieldDecorator('status', {
                                            valuePropName: 'checked',
                                            initialValue: edit === true ? editData && editData.data && editData.data.status : this.state.status ? this.state.status :
                                                prevAddData && prevAddData.data && prevAddData.data.data.status ?
                                                    prevAddData && prevAddData.data && prevAddData.data.data.status : false
                                        })(<Switch />)}
                                    </Form.Item>
                                </li>

                                <li className="btm-btn chat-view-btm">
                                    <Form.Item>
                                        {this.state.validted === false && <label className="SelectColor">Please complete required fields</label>}
                                        {edit === false &&
                                            <div className="d-flex justify-content-center">
                                                <div className="ChatExitButton">

                                                    < Button type="button" onClick={(e) => this.handleSubmit(e, id, "save")}   >
                                                        Save and Exit
                                                    </Button>
                                                </div>
                                                <div>
                                                    <Button type="primary" onClick={(e) => this.handleSubmit(e, id, "next")}  >
                                                        Next
                                                    </Button>
                                                </div>
                                            </div>

                                        }
                                        {edit === true &&
                                            <Button type="button" onClick={(e) => this.handleSubmit(e, id, "edit")} >
                                                Update
                        </Button>
                                        }
                                        {edit === true &&
                                            <Button type="primary" onClick={(e) => this.handleSubmit(e, id, "editNext")} >
                                                Next
                        </Button>
                                        }
                                    </Form.Item>
                                </li>
                            </ul>
                        </Form>
                    </div>
                </Layout>
            </div >
        )
    }
}



const mapStateToProps = ((state) => {
    let { loading, data, error } = state.addSize
    return {
        loading,
        data,
        error,
        editLoading: state.viewSize.loading,
        editData: state.viewSize.data,
        prevAddData: state.addSize.data,
        editError: state.viewSize.error,
        needleLoading: state.getNeedles.loading,
        needleData: state.getNeedles.data,
        needleError: state.getNeedles.error,
        // breadcrumbLoading: state.viewPatternBreadcrumb.data,
        // breadcrumbData: state.viewPatternBreadcrumb.data,
        // breadcrumbError: state.viewPatternBreadcrumb.error,
    }
})

const AddSize = Form.create({ name: 'addSize' })(AddSizeData);
export default withRouter(connect(mapStateToProps)(AddSize))