import { SERVER_URL } from "../../api";
import axios from 'axios'
import { getHeaderWithToken } from "../../helpers/func";
export const DELETE_STITCH_IMAGE_REQUEST = 'DELETE_STITCH_IMAGE_REQUEST';
export const DELETE_STITCH_IMAGE_SUCCESS = 'DELETE_STITCH_IMAGE_SUCCESS';
export const DELETE_STITCH_IMAGE_ERROR = 'DELETE_STITCH_IMAGE_ERROR';
export const RESET_DELETE_STITCH_IMAGE = 'RESET_DELETE_STITCH_IMAGE';


export const deleteStitchImage = (id, data) => {
    let headers = getHeaderWithToken();
    return dispatch => {
        return new Promise(function (resolve, reject) {
            dispatch({
                type: DELETE_STITCH_IMAGE_REQUEST
            })
            axios({
                method: 'DELETE',
                url: `${SERVER_URL}v1/pattern/stitch_image`,
                headers: headers,
                data: { id, data }
            }).then(function (response) {
                dispatch({
                    type: DELETE_STITCH_IMAGE_SUCCESS,
                    payload: response
                })
                resolve(response);
            })
                .catch(function (error) {
                    dispatch({
                        type: DELETE_STITCH_IMAGE_ERROR,
                        payload: error
                    })
                    reject(error)
                })
        })

    }
}



