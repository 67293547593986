import { SERVER_URL } from "../../api";
import axios from 'axios';
import { getHeaderWithToken } from "../../helpers/func";

export const VIEW_CHART_REQUEST = 'VIEW_CHART_REQUEST';
export const VIEW_CHART_SUCCESS = 'VIEW_CHART_SUCCESS';
export const VIEW_CHART_ERROR = 'VIEW_CHART_ERROR';
export const RESET_VIEW_CHART = 'RESET_VIEW_CHART';


export const getChart = (pattern, size, section) => {
    let headers = getHeaderWithToken();

    return dispatch => {
        return new Promise(function (resolve, reject) {
            dispatch({
                type: VIEW_CHART_REQUEST
            })
            axios({
                method: 'GET',
                url: `${SERVER_URL}v1/patterns/charts/${pattern}/${size}/${section}`,
                headers: headers,

            }).then(function (response) {
                dispatch({
                    type: VIEW_CHART_SUCCESS,
                    payload: response
                })
                resolve(response);
            })
                .catch(function (error) {
                    dispatch({
                        type: VIEW_CHART_ERROR,
                        payload: error
                    })
                    reject(error)
                })
        })

    }
}

export const resetViewChart = () => {
    return dispatch => {
        dispatch({
            type: RESET_VIEW_CHART
        })
    }
}



