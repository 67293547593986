import { GET_STITCHES_REQUEST, GET_STITCHES_SUCCESS, GET_STITCHES_ERROR } from "../actions/stitch"

let initialState = {
    loading: false,
    data: null,
    error: null
}

const getStitch = (state = initialState, action) => {
    switch (action.type) {
        case GET_STITCHES_REQUEST:
            return {
                ...state,
                loading: true
            }

        case GET_STITCHES_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            }
        case GET_STITCHES_ERROR:
            return {
                ...state,
                loading: false,
                data: action.payload
            }

        default:
            return state;
    }
}

export default getStitch