import { SERVER_URL } from "../../api";
import axios from 'axios';
import FormData from "form-data";

import { getHeaderWithToken } from "../../helpers/func";
export const ADD_SECTION_REQUEST = 'ADD_SECTION_REQUEST';
export const ADD_SECTION_SUCCESS = 'ADD_SECTION_SUCCESS';
export const ADD_SECTION_ERROR = 'ADD_SECTION_ERROR';
export const RESET_ADD_SECTION = 'RESET_ADD_SECTION';


export const addSection = (data, edit) => {
    let headers = getHeaderWithToken();
    const formdata = new FormData();

    formdata.append('sectionName', data.sectionName);
    formdata.append('position', data.position);
    if (data.introText) {

        formdata.append('introText', data.introText);
    }
    if (data.outroText) {

        formdata.append('outroText', data.outroText);
    }

    formdata.append('sizeId', data.sizeId);
    formdata.append('patternId', data.patternId);
    formdata.append('status', data.status);

    if (edit) {
        formdata.append('id', data.id);
    }
    console.log('data.fileList', data.fileList);

    if (data.fileList) {

        for (let i = 0; i < data.fileList.length; i++) {
            formdata.append("image", data.fileList[i].originFileObj);
        }
    }

    if (data && data.type) {
        formdata.append("id", data._id);

        formdata.append('type', data.type);
        for (let i = 0; i < data.image.length; i++) {
            formdata.append("image", data.image[i]);
        }

    }
    return dispatch => {
        return new Promise(function (resolve, reject) {

            dispatch({
                type: ADD_SECTION_REQUEST
            })
            axios({
                method: edit ? 'PUT' : 'POST',
                url: `${SERVER_URL}v1/pattern/section`,
                headers: headers,
                data: formdata
            }).then(function (response) {
                dispatch({
                    type: ADD_SECTION_SUCCESS,
                    payload: response
                })
                resolve(response);
            })
                .catch(function (error) {
                    dispatch({
                        type: ADD_SECTION_ERROR,
                        payload: error
                    })
                    reject(error)
                })
        })

    }
}
export const resetAddSectionData = () => {
    return dispatch => {
        dispatch({
            type: RESET_ADD_SECTION
        })
    }
}


