import { SERVER_URL } from "../../api";
import axios from 'axios';
import { getHeaderWithToken } from "../../helpers/func";

export const VIEW_USER_REQUEST = 'VIEW_USER_REQUEST';
export const VIEW_USER_SUCCESS = 'VIEW_USER_SUCCESS';
export const VIEW_USER_ERROR = 'VIEW_USER_ERROR';
export const RESET_VIEW_USER = 'RESET_VIEW_USER';


export const viewUser = (id) => {
    let headers = getHeaderWithToken();

    return dispatch => {
        return new Promise(function (resolve, reject) {
            dispatch({
                type: VIEW_USER_REQUEST
            })
            axios({
                method: 'GET',
                url: `${SERVER_URL}v1/pattern/users/${id}`,
                headers: headers,

            }).then(function (response) {
                dispatch({
                    type: VIEW_USER_SUCCESS,
                    payload: response
                })
                resolve(response);
            })
                .catch(function (error) {
                    dispatch({
                        type: VIEW_USER_ERROR,
                        payload: error
                    })
                    reject(error)
                })
        })

    }
}

export const resetViewUser = () => {
    return dispatch => {
        dispatch({
            type: RESET_VIEW_USER
        })
    }
}



