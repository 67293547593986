import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { Form, Input, Button, message, } from 'antd';
import { reset_password } from '../redux/actions/reset_password';



class Resetpassword extends Component {
    state = {
        confirmDirty: false,
    };

    handleSubmit = e => {
        e.preventDefault();
        let { dispatch, match } = this.props;
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                let data = {
                    password: values.password,
                    token: match.params.token
                }
                dispatch(reset_password(data))
            }
        });
    };

    handleConfirmBlur = e => {
        const { value } = e.target;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    };

    compareToFirstPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && value !== form.getFieldValue('password')) {
            callback('Two passwords that you enter is inconsistent!');
        } else {
            callback();
        }
    };

    validateToNextPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm'], { force: true });
        }
        callback();
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        let { loading } = this.props;

        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 8,
                },
            },
        };

        return (

            <div className="login-page">
                <div className="login-box">
                    <h2>Reset Password</h2>
                    <Form layout="block" onSubmit={this.handleSubmit} style={{ width: "50 %" }}>

                        <Form.Item label="Password" hasFeedback>
                            {getFieldDecorator('password', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please input your password!',
                                    },
                                    {
                                        validator: this.validateToNextPassword,
                                    },
                                ],
                            })(<Input.Password />)}
                        </Form.Item>
                        <Form.Item label="Confirm Password" hasFeedback>
                            {getFieldDecorator('confirm', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please confirm your password!',
                                    },
                                    {
                                        validator: this.compareToFirstPassword,
                                    },
                                ],
                            })(<Input.Password onBlur={this.handleConfirmBlur} />)}
                        </Form.Item>
                        <Form.Item {...tailFormItemLayout}>
                            <Button disabled={loading} type="primary" htmlType="submit" className="login-form-button">
                                Reset Password
                    </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        );
    }


    componentDidUpdate(prevProps, prevState) {
        let { loading, data, error } = this.props;
        if (!loading && prevProps.loading !== loading) {
            if (data && data.status === 200) {
                message.success("Password Changed Successfully")
            }
            if (error && error.status === 400) {
                message.error(error.data.message)
            }
        }
    }

}

const mapStateToProps = ((state) => {
    const { loading, data, error } = state.reset_password;
    return {
        loading,
        data,
        error
    }
})

const ResetPassword = Form.create({ name: 'reset-form' })(Resetpassword);
export default withRouter(connect(mapStateToProps)(ResetPassword));