import React, { Component } from 'react'
// import { connect } from 'react-redux';
import { Checkbox } from 'antd';

export default class Important extends Component {
    constructor(props) {
        super(props)
        this.state = {
            checkboxValue: props.checkboxValue,
            importantValue: props.importantValue
        }
    }


    handleImportant = (e) => {
        // e.preventDefault();
        let { checkboxValue } = this.state;
        let tempval = { ...checkboxValue }
        tempval.important = e.target.checked;
        this.setState({ importantValue: e.target.checked, checkboxValue: tempval },
            () => this.props.eImportant({ importantValue: e.target.checked, checkboxValue: tempval })
        )
    };

    componentDidUpdate(prevProps, prevState) {
        let { importantValue } = this.props;
        if (!importantValue && prevProps.importantValue !== importantValue) {
            this.setState({ importantValue: importantValue })
        }
    }

    render() {
        let { checkboxValue } = this.state
        return (
            <div>
                <h2>Important</h2>
                <Checkbox checked={this.state.importantValue ? this.state.importantValue : checkboxValue && checkboxValue.important ? true : false} defaultChecked={false}
                    onChange={(e) => this.handleImportant(e)}><i></i></Checkbox>
            </div>
        )
    }
}

// const mapStateToProps = ((state) => {

// })
// export default connect(mapStateToProps)(Important);