import { SERVER_URL } from "../../api";
import axios from 'axios';
import { getHeaderWithToken } from "../../helpers/func";

export const VIEW_PATTERN_BREADCRUMB_REQUEST = 'VIEW_PATTERN_BREADCRUMB_REQUEST';
export const VIEW_PATTERN_BREADCRUMB_SUCCESS = 'VIEW_PATTERN_BREADCRUMB_SUCCESS';
export const VIEW_PATTERN_BREADCRUMB_ERROR = 'VIEW_PATTERN_BREADCRUMB_ERROR';
export const RESET_VIEW_PATTERN_BREADCRUMB = 'RESET_VIEW_PATTERN_BREADCRUMB';


export const viewPatternBreadcrumb = (id) => {
    let headers = getHeaderWithToken();

    return dispatch => {
        return new Promise(function (resolve, reject) {
            dispatch({
                type: VIEW_PATTERN_BREADCRUMB_REQUEST
            })
            axios({
                method: 'GET',
                url: `${SERVER_URL}v1/pattern/sizeBreadcrumb/${id}`,
                headers: headers,

            }).then(function (response) {
                dispatch({
                    type: VIEW_PATTERN_BREADCRUMB_SUCCESS,
                    payload: response
                })
                resolve(response);
            })
                .catch(function (error) {
                    dispatch({
                        type: VIEW_PATTERN_BREADCRUMB_ERROR,
                        payload: error
                    })
                    reject(error)
                })
        })

    }
}

export const resetViewPattern = () => {
    return dispatch => {
        dispatch({
            type: RESET_VIEW_PATTERN_BREADCRUMB
        })
    }
}



