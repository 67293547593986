import { DELETE_COLOR_REQUEST, DELETE_COLOR_SUCCESS, DELETE_COLOR_ERROR } from "../actions/deleteColor"

let initialState = {
    loading: false,
    data: null,
    error: null
}

const deleteColor = (state = initialState, action) => {
    switch (action.type) {
        case DELETE_COLOR_REQUEST:
            return {
                ...state,
                loading: true
            }

        case DELETE_COLOR_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            }
        case DELETE_COLOR_ERROR:
            return {
                ...state,
                loading: false,
                data: action.payload
            }

        default:
            return state;
    }
}

export default deleteColor