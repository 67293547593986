import React, { Component } from 'react'
import Layout from './Layout';
import { Form, Icon, Input, Button, Upload, message, Select } from 'antd';
import { connect } from 'react-redux';
import { adminRoutes } from '../helpers/adminRoutes';
import { addStitch } from '../redux/actions/addStitch';
import { Breadcrumb } from 'antd';
import { SERVER_URL } from '../api';
import { Link } from 'react-router-dom';
import { Beforeunload } from 'react-beforeunload';
import { viewStitch, resetviewStitch } from '../redux/actions/viewStitch';
import { deleteStitchImage } from '../redux/actions/deleteStitchImage';
import imageCompression from 'browser-image-compression';

const { Option } = Select;


function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}


const { Dragger } = Upload;

const props = {
    name: 'file',
    multiple: false,
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
        }
        if (status === 'done') {

            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
};

class AddStitchData extends Component {

    constructor(props) {
        super(props)
        this.state = {
            fileList: [],
            edit: false,
            id: null,

        }
    }

    componentWillUnmount() {
        this.setState({ edit: false, id: null });
        let { dispatch } = this.props;

        dispatch(resetviewStitch())

        let val = this.props.form.isFieldsTouched();
        if (val === true) {
        } else {
        }
    }



    handleSubmit = (e) => {

        e.preventDefault();
        let { dispatch, location } = this.props;
        let { fileList, edit, difficultyValue, image } = this.state;

        let path = location.pathname.split('/')

        let id = path[2];
        this.setState({ defaulState: true })

        this.props.form.validateFields((err, values) => {
            if (!err) {
                if (edit === false) {

                    dispatch(addStitch({ ...values, fileList })).then(res => {

                        if (res && res.status === 200) {

                            this.props.history.push(adminRoutes.STITCHLIST);
                            window.location.reload();


                        }
                    }).catch(err => {
                        message.destroy();
                        message.error("Please use a unique stitch name")

                    })
                }
                else {

                    dispatch(addStitch({ ...values, fileList, id }, edit)).then(res => {

                        this.props.history.push(adminRoutes.STITCHLIST);
                        window.location.reload();

                    }).catch(err => {
                        message.destroy();
                        message.error("Please use a unique stitch name")
                    })
                }
            }


        })

    }



    componentDidUpdate(prevProps) {
        let { dispatch, location } = this.props;

        let path = location.pathname.split('/')

        let id = path[2];
        if (location && prevProps.location !== location) {

            if (id) {
                this.setState({ edit: true, id: id })

                dispatch(viewStitch(id)).then(res => {
                    if (res && res.status === 200) {
                        this.setState({ edit: true })
                    }
                })

            }
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        let { dispatch, location, error, history } = this.props;

        let path = location.pathname.split('/')

        let id = path[2];
        if (id) {
            this.setState({ edit: true, id: id })

            dispatch(viewStitch(id)).then(res => {
                if (res && res.status === 200) {
                    // this.setState({ image: res.data.data.image })
                }
            })

        }


        if (error && error.status === 400) {
            message.error(error.data.message)
            history.push(adminRoutes.AddPattern)
        }


    }

    handleDelete = (e, id, imageName) => {
        const img = imageName;
        e.preventDefault();
        let { dispatch } = this.props;
        dispatch(deleteStitchImage(id._id, img)).then(res => {
            if (res && res.status === 200) {
                dispatch(viewStitch(id._id));
            }
        })
    }

    normFile = e => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    handleChange = ({ file, fileList }) => {
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        }

        let temp = [];

        fileList.map(data => {
            const isJpgOrPng = data.type === 'image/svg+xml';
            if (!isJpgOrPng) {
                return message.error('Sorry you can only upload SVG file !');
            }
            const sizeOfFile = data.size / 1024 / 1024

            const isLt2M = sizeOfFile < 5;
            if (isLt2M === false) {
                return message.error('Sorry you cannot upload a photo larger then 5 MB!');

            }

            else {
                if (fileList.length <= 1) {
                    // const compressedFile = await imageCompression(imageFile, options);
                    // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
                    // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

                    // await uploadToServer(compressedFile); // write your own logic



                    return (
                        temp.push(data)

                    )
                }
                else {
                    temp = [];
                    return (

                        temp.push(data)

                    )

                }
            }

        })


        this.setState({ fileList: temp });

        // }

    }

    render() {
        const { getFieldDecorator, getFieldsError } = this.props.form;
        let { editData, prevAddData } = this.props;
        let { fileList, edit } = this.state;


        const uploadButton = (
            <div>
                <Icon type="plus" />
                <div className="ant-upload-text">Upload</div>
            </div>
        );

        return (
            <div className="content-box">


                <Layout>

                    <div className="bredcrumb-div">
                        <Breadcrumb separator="-">
                            <Breadcrumb.Item><Link to={adminRoutes.STITCHLIST}>Stitches</Link></Breadcrumb.Item>
                            <Breadcrumb.Item><Link to={adminRoutes.ADDSTITCH}>Add Stitch</Link></Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className="page-title d-flex">
                        <h2>Add Stitch</h2>
                    </div>
                    <div className="white-box">
                        <Form layout="horizontal" onSubmit={this.handleSubmit} style={{ width: "50 %" }}>
                            <ul className="filled-ul d-flex flex-wrap">
                                <li className="">
                                    <Form.Item >
                                        <label>Stitch Name <em>(i.e knit , 4/1 Right Purl Cable, 1/1 Left Slipped Cable)</em></label>
                                        {getFieldDecorator('name', {
                                            rules: [{ required: true, message: 'Please input your stitch name!' }],
                                            initialValue:
                                                edit === true ? editData && editData.data && editData.data.data &&
                                                    editData.data.data.name :
                                                    prevAddData && prevAddData.data && prevAddData.data.data.name ?
                                                        prevAddData && prevAddData.data && prevAddData.data.data.name : ""
                                        })(
                                            <Input
                                                // prefix={<Icon />}
                                                type="text"
                                                placeholder="Stitch Name"
                                            />,
                                        )}
                                    </Form.Item>
                                </li>
                                <li className="">
                                    <div className="d-flex HeightWidthWrap">
                                        <div className="HeightWidth">
                                            <Form.Item >
                                                <label>Height <em>(in number of Stitches)</em> :</label>
                                                {getFieldDecorator('height', {
                                                    rules: [{ required: true, message: 'Please input your height!' }, {
                                                    }],


                                                    initialValue: "1"
                                                })
                                                    (
                                                        <Input
                                                            // prefix={<Icon />}
                                                            type="text"
                                                            readOnly
                                                            placeholder="Height"
                                                        />

                                                    )}
                                            </Form.Item>
                                        </div>
                                        <div className="HeightWidth">
                                            <Form.Item >
                                                <label>Width <em>(in number of Stitches)</em> :</label>
                                                {getFieldDecorator('width', {
                                                    rules: [{ required: true, message: 'Please input your width!' }],
                                                    initialValue:
                                                        edit === true ? editData && editData.data && editData.data.data &&
                                                            editData.data.data.width :
                                                            prevAddData && prevAddData.data && prevAddData.data.data.width ?
                                                                prevAddData && prevAddData.data && prevAddData.data.data.width : ""
                                                })(
                                                    edit === true ? <Input
                                                        // prefix={<Icon />}
                                                        type="text"
                                                        readOnly
                                                        placeholder="Width"
                                                    />
                                                        :
                                                        <Input
                                                            // prefix={<Icon />}
                                                            type="text"
                                                            placeholder="Width"
                                                        />
                                                )}
                                            </Form.Item>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <Form.Item >
                                        <label>Abbreviation <em>(how the stitch will be shown in written instructin, i.e, k2tog, slip 1, 4/1 RPC, LSC )</em></label>
                                        {getFieldDecorator('abbreviation', {
                                            rules: [{ required: true, message: 'Please input your abbreviation!' }],
                                            initialValue:
                                                edit === true ? editData && editData.data && editData.data.data &&
                                                    editData.data.data.abbreviation :
                                                    prevAddData && prevAddData.data && prevAddData.data.data.abbreviation ?
                                                        prevAddData && prevAddData.data && prevAddData.data.data.abbreviation : ""
                                        })(
                                            <Input
                                                // prefix={<Icon />}
                                                type="text"
                                                placeholder="Abbreviation"
                                            />,
                                        )}
                                    </Form.Item>
                                </li>
                                <li>
                                    <Form.Item >
                                        <label>Short Instruction <em>(how to do the stitch in shorthand)</em></label>
                                        {getFieldDecorator('short_instruction', {
                                            // rules: [{ required: true, message: 'Please input your short instruction!' }],
                                            initialValue:
                                                edit === true ? editData && editData.data && editData.data.data &&
                                                    editData.data.data.short_instruction :
                                                    prevAddData && prevAddData.data && prevAddData.data.data.short_instruction ?
                                                        prevAddData && prevAddData.data && prevAddData.data.data.short_instruction : ""
                                        })(
                                            <Input
                                                // prefix={<Icon />}
                                                type="text"
                                                placeholder="Short Instruction"
                                            />,
                                        )}
                                    </Form.Item>
                                </li>
                                <li>
                                    <Form.Item >
                                        <label>Long Instruction <em>(how to do the stitch in friendliest language)</em></label>
                                        {getFieldDecorator('long_instruction', {
                                            // rules: [{ required: true, message: 'Please input your short instruction!' }],
                                            initialValue:
                                                edit === true ? editData && editData.data && editData.data.data &&
                                                    editData.data.data.long_instruction :
                                                    prevAddData && prevAddData.data && prevAddData.data.data.long_instruction ?
                                                        prevAddData && prevAddData.data && prevAddData.data.data.long_instruction : ""
                                        })(
                                            <Input
                                                // prefix={<Icon />}
                                                type="text"
                                                placeholder="Long Instruction"
                                            />,
                                        )}
                                    </Form.Item>
                                </li>
                                <li>
                                    <Form.Item >
                                        <label>Video Tutorial Embed Code</label>
                                        {getFieldDecorator('link', {
                                            // rules: [{ required: true, message: 'Please input your link!' }],
                                            initialValue:
                                                edit === true ? editData && editData.data && editData.data.data &&
                                                    editData.data.data.link :
                                                    prevAddData && prevAddData.data && prevAddData.data.data.link ?
                                                        prevAddData && prevAddData.data && prevAddData.data.data.link : ""
                                        })(
                                            <Input
                                                // prefix={<Icon />}
                                                type="text"
                                                placeholder="Enter embed code"
                                            />,
                                        )}
                                    </Form.Item>
                                </li>
                                <li className="half-width CustomeSelect">
                                    <Form.Item>
                                        <label>Type</label>
                                        {getFieldDecorator('type', {
                                            rules: [{ required: true, message: 'Please input type!' }],

                                            initialValue: edit === true ? editData && editData.data && editData.data.data &&
                                                editData.data.data.type && editData.data.data.type :
                                                prevAddData && prevAddData.data && prevAddData.data.data.type ?
                                                    prevAddData && prevAddData.data && prevAddData.data.data.type : ""
                                        })(
                                            <Select>
                                                <Option key="elemental">Elemental</Option>
                                                <Option key="compound">Compound</Option>
                                            </Select>
                                        )}
                                    </Form.Item>
                                </li>


                                <li className="picture-card">
                                    <Form.Item extra="">
                                        <label>Upload stitch image</label>

                                        {(edit !== true || (edit == true && editData && editData.data && editData.data.data.image == undefined)) && getFieldDecorator('image',
                                            {
                                                rules: [{ required: true, message: 'Please input image!' }],
                                            })

                                            (
                                                < Dragger listType="picture-card"
                                                    fileList={fileList}
                                                    beforeUpload={() => false}
                                                    //beforeUpload={this.beforeUpload}

                                                    onPreview={this.handlePreview}
                                                    onChange={this.handleChange}
                                                    defaultFileList={editData && editData.data && editData.data.data && editData.data.data.image ? `${SERVER_URL}uploads/${editData.data.data.image[0]}` :
                                                        prevAddData && prevAddData.data && prevAddData.data.data && prevAddData.data.data.image[0] ?
                                                            `${SERVER_URL} uploads /${prevAddData.data.data.image[0]}` : []
                                                    }
                                                    multiple={false} >

                                                    <p>Drag a file here </p>
                                                    <p>
                                                        Upload image from you computer
                                                         </p>
                                                </Dragger>,
                                            )
                                        }

                                        <div className="upload-images d-flex">

                                            {editData && editData.data && editData.data.data.image != undefined &&
                                                // editData && editData.data && editData.data.data.image.map((dat, index) => {
                                                // return (
                                                <div >
                                                    <img src={`${SERVER_URL}stitches/${editData && editData.data && editData.data.data.image}`} alt="" />
                                                    <Button className="imag-close" onClick={(e) => this.handleDelete(e, editData.data.data, editData.data.data.image)}>
                                                        <Icon type="close" />

                                                    </Button>
                                                </div>
                                                // )
                                                // })
                                            }
                                        </div>
                                    </Form.Item>
                                </li>

                                <li className="btm-btn chat-view-btm">
                                    <Form.Item>

                                        <div className="d-flex justify-content-center">
                                            <div className="ChatExitButton">
                                                < Button type="button" onClick={(e) => this.handleSubmit(e)} disabled={hasErrors(getFieldsError())} >
                                                    {edit === true ? "Update" : "Save and Exit"}
                                                </Button>
                                            </div>

                                        </div>



                                    </Form.Item>
                                </li>
                            </ul>

                        </Form>
                    </div>
                </Layout>
            </div >
        )
    }
}

const mapStateToProps = ((state) => {
    let { loading, data, error } = state.addStitch
    return {
        loading,
        data,
        error,
        editLoading: state.viewStitch.loading,
        editData: state.viewStitch.data,
        prevAddData: state.addPattern.data,
        editError: state.viewStitch.error,

    }
})

const AddStitch = Form.create({ name: 'addStitch' })(AddStitchData);
export default connect(mapStateToProps)(AddStitch);