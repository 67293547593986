import React, { Component } from 'react'
import Layout from './Layout';
import { Form, Icon, Input, Button, Upload, Switch, message, Menu, Dropdown, Select } from 'antd';
import { connect } from 'react-redux';
import { adminRoutes } from '../helpers/adminRoutes';
import { addSection, resetAddSectionData } from '../redux/actions/addSection';
import { Breadcrumb, List, Card } from 'antd';
import { Prompt } from 'react-router-dom';
import { viewSection, resetViewSection } from '../redux/actions/viewSection';
import { sectionList } from '../redux/actions/sectionList';
import { sectionBreadcrumb } from '../redux/actions/sectionBreadcrumb';
import { SERVER_URL } from '../api';
import { Link } from 'react-router-dom';
import { deleteImage } from '../redux/actions/deleteImage';
import { Beforeunload } from 'react-beforeunload';
import TextArea from 'antd/lib/input/TextArea';
import Item from 'antd/lib/list/Item';
import InfiniteScroll from 'react-infinite-scroller';

const { Option } = Select;



function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class AddSectionData extends Component {

    constructor(props) {
        super(props)
        this.state = {
            fileList: [],
            status: false,
            totalRecords: 0,
            patternName: null,
            sizeName: null,
            defaulState: false,
            edit: false,
            patternId: '',
            sizeId: '',
            sectionId: ''
        }
    }
    componentDidUpdate(prevProps) {
        let { location, dispatch } = this.props;
        let path = location.pathname.split('/')
        let patternId = path[2];
        let sizeId = path[3];
        let sectionId = path[4];

        if (location && prevProps.location !== location) {
            dispatch(sectionBreadcrumb(sizeId)).then(response => {

                this.setState({
                    "status": response && response.data[0] && response.data[0].status,
                    "patternName": response && response.data[0] && response.data[0].pattern && response.data[0].pattern.patternName,
                    "sizeName": response && response.data[0] && response.data[0].sizeName
                })
            });
            let data = {
                start: 0,
                length: 1000000,

            }
            dispatch(sectionList({ id: sizeId, ...data })).then(response => {

            })

            dispatch(viewSection(sectionId)).then(response => {
                console.log('response 12=> ', response);

            })
            this.setState({ patternId: patternId, sizeId: sizeId, sectionId: sectionId })
            if (sectionId) {
                this.setState({ edit: true })

            }

        }
    }
    componentDidMount() {
        let { location, dispatch } = this.props;
        let path = location.pathname.split('/')
        let patternId = path[2];
        let sizeId = path[3];
        let sectionId = path[4];


        dispatch(sectionBreadcrumb(sizeId)).then(response => {
            this.setState({
                "status": response && response.data[0] && response.data[0].status,
                "patternName": response && response.data && response.data[0] && response.data[0].pattern && response.data[0].pattern.patternName,
                "sizeName": response && response.data[0] && response.data[0].sizeName
            })
        });
        let data = {
            start: 0,
            length: 1000000,

        }
        dispatch(sectionList({ id: sizeId, ...data })).then(response => {
            if (response && response.data && response.data && response.data.recordsTotal) {
                this.setState({ totalRecords: response.data.recordsTotal })
            }
        })
        dispatch(viewSection(sectionId)).then(response => {
            this.setState({ emails: response && response.data && response.data.sectionUsers })

        })
        this.setState({ patternId: patternId, sizeId: sizeId, sectionId: sectionId })
        if (sectionId) {
            this.setState({ edit: true })
        }
    }


    handleDelete = (e, id, imageName) => {
        const img = imageName;
        e.preventDefault();
        let { dispatch } = this.props;
        dispatch(deleteImage(id._id, img)).then(res => {
            if (res && res.status === 200) {
                dispatch(viewSection(id._id));
            }
        })
    }

    componentWillUnmount() {
        this.setState({ edit: false, id: null });
        let { dispatch } = this.props;
        dispatch(resetViewSection())
        let val = this.props.form.isFieldsTouched();
        if (val === true) {
        } else {

        }
    }
    normFile = e => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    // handleChange = ({ file, fileList }) => {

    //     this.setState({ fileList });
    // }

    handleChange = ({ file, fileList }) => {
        let temp = []
        fileList.map(data => {
            const isJpgOrPng = data.type === 'image/jpeg' || data.type === 'image/png' || data.type === 'image/jpg';
            if (!isJpgOrPng) {
                message.error('You can only upload JPG/PNG/JPEG file!');
                return message.error('Sorry you can  upload JPG/PNG/JPEG file !');
            }
            const sizeOfFile = data.size / 1024 / 1024

            const isLt2M = sizeOfFile < 5;
            if (isLt2M === false) {
                return message.error('Sorry you cannot upload a photo larger then 5 MB!');

            }

            else {
                return (
                    temp.push(data)
                )
            }

        })
        this.setState({ fileList: temp });
    }


    // handleInfiniteOnLoad = () => {
    //     let { emails } = this.state;
    //     this.setState({
    //         loading: true,
    //     });
    //     if (emails.length > 14) {
    //         message.warning('Infinite List loaded all');
    //         this.setState({
    //             hasMore: false,
    //             loading: false,
    //         });
    //         return;
    //     }
    //     // this.fetchData(res => {

    //     //     emails = emails.concat(res.results);
    //     //     this.setState({
    //     //         emails,
    //     //         loading: false,
    //     //     });
    //     // });
    // };


    handleSubmit = (e, id, type) => {

        e.preventDefault()

        let { dispatch } = this.props;

        let { sizeId, fileList, sectionId, edit, patternId } = this.state;
        this.setState({ defaulState: true })

        this.props.form.validateFields((err, values) => {

            if (!err) {
                if (edit === false) {
                    dispatch(addSection({ ...values, fileList, "sizeId": sizeId, "patternId": patternId, "sectionId": sectionId })).then(res => {
                        if (res && res.status === 200) {

                            if (type && type === "save") {
                                this.props.history.push(adminRoutes.SECTIONLIST + '/' + patternId + '/' + sizeId);
                                dispatch(resetAddSectionData());
                            }
                            else

                                this.props.history.push(adminRoutes.GRID + '/' + patternId + '/' + sizeId + '/' + res.data.data._id);
                        }
                    })
                }
                else {
                    if (!err && values.sectionName != null) {
                        if (type && type === "editNext") {

                            dispatch(addSection({ ...values, fileList, "sizeId": sizeId, "id": sectionId }, edit)).then(res => {
                                if (res && res.status === 200) {

                                    this.props.history.push(adminRoutes.GRID + '/' + patternId + '/' + sizeId + '/' + res.data.data._id);
                                }

                            })
                        }
                        else {
                            dispatch(addSection({ ...values, fileList, "sizeId": sizeId, "id": sectionId }, edit)).then(res => {
                                if (res && res.status === 200) {
                                    this.props.history.push(adminRoutes.SECTIONLIST + '/' + patternId + '/' + sizeId);
                                    this.setState({ edit: false })
                                    dispatch(resetAddSectionData())
                                }
                            })
                        }
                    }
                }

            }
        })
    };




    handleValidationforAlphabtes = (a, b, c) => {
        if (b.match(/^[a-zA-Z ]*$/)) {
            c();
        } else {
            c("Please enter only alphabets")
        }
    }
    handleValidationforNumbers = (a, b, c) => {
        if (b.match(/^[0-9]*$/)) {
            c();
        } else {
            c("Please enter only numbers")
        }
    }

    optionValues(number) {
        let arr = [];
        let { edit } = this.state;
        if (number && number > 0) {
            if (edit === true) {

                for (let i = 1; i <= number; i++) {
                    arr.push(<Option value={i}>{i}</Option>)
                }
            }
            else {
                for (let i = 1; i <= number + 1; i++) {
                    arr.push(<Option value={i}>{i}</Option>)
                }
            }
            return arr;
        }
    }



    render() {
        const { getFieldDecorator, getFieldsError } = this.props.form;
        let { location, editData, prevAddData } = this.props;
        let { fileList, defaulState, patternId, emails, patternName, sizeName, sizeId, totalRecords, edit } = this.state;
        let path = location.pathname.split('/')
        let id = path[2];

        const uploadButton = (
            <div>
                <Icon type="plus" />
                <div className="ant-upload-text">Upload</div>
            </div>
        );

        return (
            <div className="content-box">

                {this.props.form.isFieldsTouched() === true && defaulState === false && <Beforeunload
                    onBeforeunload={() => "You have unsaved changes, are you sure you want to leave?"} />}

                <Prompt
                    when={this.props.form.isFieldsTouched() === true && defaulState === false}
                    message='You have unsaved changes, are you sure you want to leave?'
                />
                <Layout>

                    <div className="bredcrumb-div">
                        <Breadcrumb separator="-">
                            <Breadcrumb.Item><Link to={adminRoutes.PATTERNLIST}>{editData && editData.data && editData.data.pattern && editData.data.pattern.pattern ? editData && editData.data && editData.data.pattern && editData.data.pattern.pattern && editData.data.pattern.pattern.patternName : patternName}</Link></Breadcrumb.Item>
                            <Breadcrumb.Item><Link to={adminRoutes.SIZELIST + "/" + patternId}>{editData && editData.data && editData.data.pattern && editData.data.pattern.sizeName ? editData && editData.data && editData.data.pattern && editData.data.pattern.sizeName : sizeName}</Link></Breadcrumb.Item>
                            <Breadcrumb.Item><Link to={adminRoutes.SECTIONLIST + "/" + patternId + "/" + sizeId}>{editData && editData.data && editData.data.data && editData.data.data.sectionName ? editData && editData.data && editData.data.data && editData.data.data.sectionName : "Sections"}</Link></Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className="page-title d-flex">
                        <h2>{edit === true ? "Edit Section" : "Add Section"}</h2>
                    </div>
                    <div className="white-box">
                        <Form layout="horizontal" onSubmit={this.handleSubmit} style={{ width: "50 %" }}>
                            <ul className="filled-ul">
                                <li className="half-width">
                                    <Form.Item >
                                        <label>Section Title</label>
                                        {getFieldDecorator('sectionName', {
                                            rules: [{ required: true, message: 'Please input your section name!' }],
                                            initialValue:
                                                edit === true ? editData && editData.data && editData.data.data &&
                                                    editData.data.data.sectionName :
                                                    prevAddData && prevAddData.data && prevAddData.data.data.sectionName ?
                                                        prevAddData && prevAddData.data && prevAddData.data.data.sectionName : ""
                                        })(
                                            <Input
                                                // prefix={<Icon />}
                                                type="text"
                                                placeholder="Section Name"
                                            />,
                                        )}
                                    </Form.Item>
                                </li>

                                <li>
                                    <ul className="d-flex inner-ul ">
                                        <li>
                                            <Form.Item >
                                                <label>Intro Text</label>
                                                {getFieldDecorator('introText', {

                                                    initialValue:
                                                        edit === true ? editData && editData.data && editData.data.data &&
                                                            editData.data.data.introText && editData.data.data.introText :
                                                            prevAddData && prevAddData.data && prevAddData.data.data.introText ?
                                                                prevAddData && prevAddData.data && prevAddData.data.data.introText : ""
                                                })(
                                                    <TextArea
                                                        className="text-area-input"
                                                        type="text"
                                                        placeholder="Comments..."

                                                    ></TextArea>,
                                                )}
                                            </Form.Item>
                                        </li>
                                        <li>
                                            <Form.Item >
                                                <label>Outro Text</label>
                                                {getFieldDecorator('outroText', {

                                                    initialValue: edit === true ? editData && editData.data && editData.data.data &&
                                                        editData.data.data.outroText && editData.data.data.outroText :
                                                        prevAddData && prevAddData.data && prevAddData.data.data.outroText ?
                                                            prevAddData && prevAddData.data && prevAddData.data.data.outroText : ""
                                                })(
                                                    <TextArea
                                                        className="text-area-input"
                                                        type="text"
                                                        placeholder="Comments..."
                                                    ></TextArea>,
                                                )}
                                            </Form.Item>
                                        </li>
                                    </ul>
                                </li>
                                <li className="half-width CustomeSelect">
                                    <Form.Item>
                                        <label>Position</label>
                                        {getFieldDecorator('position', {
                                            rules: [{ required: true, message: 'Please input position!' }],

                                            initialValue: edit === true ? editData && editData.data && editData.data.data &&
                                                editData.data.data.position && editData.data.data.position :
                                                prevAddData && prevAddData.data && prevAddData.data.data.position ?
                                                    prevAddData && prevAddData.data && prevAddData.data.data.position : totalRecords && totalRecords === 0 ? 1 : totalRecords + 1
                                        })(
                                            <Select>
                                                {totalRecords && totalRecords > 0 ?

                                                    this.optionValues(totalRecords) :
                                                    <Option key={1}>1</Option>
                                                }
                                            </Select>
                                        )}
                                    </Form.Item>
                                </li>
                                <li className="picture-card">
                                    <Form.Item extra="">
                                        <label>Add Photos</label>
                                        {getFieldDecorator('image'
                                        )(
                                            <Upload
                                                listType="picture-card"
                                                fileList={fileList}
                                                beforeUpload={() => false}
                                                onPreview={this.handlePreview}
                                                onChange={this.handleChange}
                                                defaultFileList={editData && editData.data && editData.data.data ? `${SERVER_URL}uploads/${editData.data.data.image[0]}` :
                                                    prevAddData && prevAddData.data && prevAddData.data.data && prevAddData.data.data.image[0] ?
                                                        `${SERVER_URL} uploads /${prevAddData.data.data.image[0]}` : []
                                                }
                                                multiple={true}
                                            >
                                                {uploadButton}
                                            </Upload>

                                        )}

                                        <div className="upload-images d-flex">
                                            {
                                                editData && editData.data && editData.data.data.image.map((dat, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <img src={`${SERVER_URL}uploads/${dat}`} alt="" />
                                                            <Button className="imag-close" onClick={(e) => this.handleDelete(e, editData.data.data, dat)}>
                                                                <Icon type="close" />
                                                            </Button>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </Form.Item>
                                </li>
                                <li className="">
                                    <Form.Item label="Publish">
                                        {getFieldDecorator('status', {
                                            valuePropName: 'checked',
                                            initialValue: edit === true ? editData && editData.data && editData.data.data.status : this.state.status ? this.state.status :
                                                prevAddData && prevAddData.data && prevAddData.data.data.status ?
                                                    prevAddData && prevAddData.data && prevAddData.data.data.status : false
                                        })(<Switch />)}
                                    </Form.Item>
                                </li>
                                {emails && emails !== undefined &&
                                    <li>
                                        <tr>
                                            <label>In-Progress Users ({emails.length})
                                        </label>
                                            <div className="demo-infinite-container">
                                                <InfiniteScroll
                                                    initialLoad={false}
                                                    pageStart={0}
                                                    loadMore={this.handleInfiniteOnLoad}
                                                    hasMore={!this.state.loading && this.state.hasMore}
                                                    useWindow={false}
                                                >
                                                    <List
                                                        dataSource={this.state.emails}
                                                        renderItem={item => (
                                                            <List.Item key={item}>
                                                                <List.Item.Meta
                                                                    description={item}
                                                                />
                                                            </List.Item>
                                                        )}
                                                    >
                                                        {/* {this.state.loading && this.state.hasMore && (
                                                            <div className="demo-loading-container">
                                                                <Spin />
                                                            </div>
                                                        )} */}
                                                    </List>
                                                </InfiniteScroll>
                                            </div>
                                        </tr>
                                    </li>
                                }
                                <li className="btm-btn chat-view-btm">
                                    <Form.Item>
                                        {edit === false &&
                                            <div className="d-flex justify-content-center">
                                                <div className="ChatExitButton">
                                                    < Button type="button" onClick={(e) => this.handleSubmit(e, id, "save")} disabled={hasErrors(getFieldsError())} >
                                                        Save and Exit
                                                    </Button>
                                                </div>
                                                <div>
                                                    <Button type="primary" onClick={(e) => this.handleSubmit(e, id, "next")} disabled={hasErrors(getFieldsError())} >
                                                        Next</Button>
                                                </div>
                                            </div>
                                        }
                                        {edit === true &&
                                            <Button type="button" onClick={(e) => this.handleSubmit(e)} disabled={hasErrors(getFieldsError())} >
                                                Update
                        </Button>
                                        }
                                        {edit === true &&
                                            <Button type="primary" onClick={(e) => this.handleSubmit(e, id, "editNext")} disabled={hasErrors(getFieldsError())} >
                                                Next
                        </Button>
                                        }
                                    </Form.Item>
                                </li>


                            </ul>

                        </Form>
                    </div>
                </Layout>
            </div >
        )
    }
}



const mapStateToProps = ((state) => {
    let { loading, data, error } = state.addSection
    return {
        loading,
        data,
        error,
        editLoading: state.viewSection.loading,
        editData: state.viewSection.data,
        prevAddData: state.addSection.data,
        editError: state.viewSection.error,
        sectionData: state.sectionList.data

    }
})

const AddSection = Form.create({ name: 'addSection' })(AddSectionData);
export default connect(mapStateToProps)(AddSection);